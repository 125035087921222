import { AbstractEntity, DataServer, DgTableColumn } from "@datagrupo/dg-crud";
import { environment } from "src/environments/environment";

@DataServer({
  path: environment.apiUrl,
  context: 'akad/proposal'
})
export class CotacoesRd extends AbstractEntity{

  constructor(
    id?: number | string,
    totalEquipmentAmount?: number | string,
    status?: string,
    proposalNo?: string,
    effectiveDate?: string,
    expiryDate?: string,
    name?: string,
    document?: number | string,
    mobile?: number | string,
    email?: string,
    certificate?: string,
    dataInclusao?: string,
    produtor?: string,
    apelido?: string,
    usuarioInclusao?: string
  ){
    super()
    this.id = id;
    this.totalEquipmentAmount = totalEquipmentAmount;
    this.status = status;
    this.proposalNo = proposalNo;
    this.effectiveDate = effectiveDate;
    this.expiryDate = expiryDate;
    this.name = name;
    this.document = document;
    this.mobile = mobile;
    this.email = email;
    this.certificate = certificate;
    this.dataInclusao = dataInclusao;
    this.usuarioInclusao = usuarioInclusao
    this.produtor = produtor;
    this.apelido = apelido;
  }

  @DgTableColumn({columnName: 'Cliente', resource: (cel: string, row: any) => {
    return row.personName || '--';
    }
  })
  public name: string|undefined;
  @DgTableColumn({columnName:'Documento', resource: (val: string, row: any) => {
    if(!row.personDocument) return '--'
    val = String(row.personDocument).replace(/\D/g, "")
    if(val.length == 11) return val.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/,"$1.$2.$3-$4")

    return val.replace(/(\d{2})(\d{3})(\d{3})(\d{3})(\d{2})/,"$1.$2.$3/$4-$5")
  }})
  public document: number | string | undefined;
  @DgTableColumn({columnName:'Telefone', resource: (val: string, row: any) => {
    if(!row.personMobile) return '--'
    val = String(row.personMobile).replace(/\D/g, "")
    if(val.length == 11) return val.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/,"($1) $2 $3-$4")

    return val.replace(/(\d{2})(\d{4})(\d{4})/,"($1) $2-$3")

  }})
  public mobile: number | string | undefined;
  @DgTableColumn({columnName:'Email', resource: (cel: string, row: any) => {
    return row.personEmail || '--'
  }})
  public email:  string | undefined;
  @DgTableColumn({columnName:'Valor Total (R$)', resource: (val: string) => {
    if(!val) return '--'
    return  Number(val).toLocaleString("BRL", {maximumFractionDigits: 2, minimumFractionDigits: 2});
  }})
  public totalEquipmentAmount: number | string | undefined;
  @DgTableColumn({columnName: 'Nº Proposta'})
  public proposalNo: string | undefined;
  @DgTableColumn({columnName: 'Inicio de Vigência', mask: 'date'})
  public effectiveDate: string | undefined;
  @DgTableColumn({columnName: 'Fim de Vigência', mask: 'date'})
  public expiryDate: string | undefined;
  @DgTableColumn({columnName: 'Status'})
  public status: string | undefined;
  @DgTableColumn({columnName: 'Data da Criação', mask: 'date'})
  public dataInclusao: string | undefined
  @DgTableColumn({columnName: 'Produtor'})
  public produtor: string | undefined
  @DgTableColumn({columnName: 'Usuário'})
  public usuarioInclusao: string | undefined

  public apelido: string | undefined;
  public certificate: string | undefined;
  public person: any;
}
