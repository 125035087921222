import {ProductsEntity} from "./products.entity";
import {CoveragesEntity} from "./coverages.entity";
import {PolicyRiskListEntity} from "./policy-risk-list.entity";

export class RiskAnalysisEntity {
  constructor(data: {
    policyElementId?: string | undefined,
    policyRiskList?: PolicyRiskListEntity[],
    hasClaimAmountLastYear?: boolean,
    scope?: string | undefined,
    reportedClaim?: number | undefined,
    installationZone?: string | undefined,
    coverages?: CoveragesEntity,
    products?: ProductsEntity[],
    localType?: string,
    federativeUnits?: string,
    policyId?: string,
  }) {
    this.coverages = data?.coverages;
    this.scope = data?.scope || "RL";
    this.reportedClaim = 0;
    this.installationZone = data?.installationZone ? data.installationZone : "Z1";
    this.products = Array.isArray(data?.products) ? data.products : [];
    this.policyRiskList = Array.isArray(data?.policyRiskList) ? data.policyRiskList : [];
    this.policyElementId = data.policyElementId || undefined
    this.localType = data?.localType ? data.localType : 'L1';
    this.federativeUnits = 'SE';
    this.riskType = "RiscoARisco";
    this.policyId = data?.policyId;

    this.hasClaimAmountLastYear = !!data?.hasClaimAmountLastYear
  }

  id: number | string | undefined
  policyElementId?: string | undefined;
  policyRiskList: PolicyRiskListEntity[];
  hasClaimAmountLastYear: boolean;
  scope: string | undefined;
  reportedClaim: number | undefined;
  installationZone: string | undefined;
  coverages: CoveragesEntity | undefined;
  products: ProductsEntity[];
  localType: string;
  federativeUnits: string;
  riskType: string | number;
  policyId: string | number | undefined;
  model: string | undefined;
}
