import { UserModule } from './pages/user/user.module';
import { LayoutsModule } from './layouts/layouts.module';
import { PublicModule } from './pages/public/public.module';

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthModule } from './pages/auth/auth.module';
import { SessionService } from './core/service/session/session.service';
import { AddTokenInterceptor } from './core/interceptors/add-token/add-token.interceptor';
import { DgModalModule } from '@datagrupo/dg-ng-util';
import { DatePipe } from '@angular/common';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { CallbackMessageInterceptor } from './core/interceptors/callback-message/callback-message.interceptor';

registerLocaleData(localePt);

const maskConfig: Partial<IConfig> = {
  validation: false,
};


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    PublicModule,
    LayoutsModule,
    UserModule,
    AuthModule,
    NgxMaskModule.forRoot(maskConfig),
    DgModalModule
  ],
  providers: [
    SessionService,
    { provide: HTTP_INTERCEPTORS, useClass: AddTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CallbackMessageInterceptor, multi: true },
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
