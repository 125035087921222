import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConsultaCepService {
  url_API_CEP = 'https://viacep.com.br/ws/'

  constructor(
    private http: HttpClient
  ) { }

  getConsultaCepRisco(cepRisco: string){
    return this.http.get(`${this.url_API_CEP}${cepRisco}/json`)
  }

  getConsultaCepSegurado(cepSegurado: string){
    return this.http.get(`${this.url_API_CEP}${cepSegurado}/json`)
  }

  getConsultaCepCobranca(cepCobranca: string){
    return this.http.get(`${this.url_API_CEP}${cepCobranca}/json`)
  }

}
