import { AddressReEntity } from "./addressRe.entity";
import { BillingAddressReEntity } from "./billingAddressRe.entity";

export class InsuredEntity {
  constructor(data: {
    type?: string,
    insured?: string,
    document?: number | string,
    email?: string,
    mobile?: string,
    dateOfBirth?: string,
    address?: AddressReEntity
    billingAddress?: BillingAddressReEntity
  }){
    this.type = data.type;
    this.insured = data.insured;
    this.document = data.document;
    this.email = data.email;
    this.mobile = data.mobile;
    this.dateOfBirth = data?.dateOfBirth;
    this.address = data?.address;
    this.billingAddress = data?.billingAddress;
  }

  type?: string | undefined
  insured?: string | undefined
  document?: number | string | undefined
  email?: string | undefined
  mobile?: string | undefined
  dateOfBirth?: string | undefined
  address?: AddressReEntity | undefined
  billingAddress?: AddressReEntity | undefined

}
