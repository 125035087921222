<div class="wrapper d-flex align-items-stretch">

  <dg-sidebar-menu #sidebar>

    <dg-sidebar-menu-logo img="assets/images/ombrello solar_pb_2.png"></dg-sidebar-menu-logo>

    <dg-sidebar-menu-navigation-item
      *ngFor="let item of menuList"
      [startHr]="!!item.hrStart"
      [endHr]="!!item.hrEnd"
      [listNavigation]="item.menuList"
    >
    </dg-sidebar-menu-navigation-item>
  </dg-sidebar-menu>

  <!-- Page Content  -->
  <div id="content">

    <div id='layout-appRoot'>

      <private-header (changeSidebar)="sidebar.changeState()"></private-header>

      <div class="router-outlet-root">
        <router-outlet>
        </router-outlet>

      </div>
    </div>
  </div>
</div>

<!-- <app-navbar></app-navbar>
<main>
  <router-outlet></router-outlet>
</main> -->
