<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="card card-relatorio">
          <div class="card-header ff-gotham-bold header-relatorio">
            <h4 class="card-title">Registro de Usuários</h4>
          </div>
        <div class="card-body">
          <form [formGroup]="form">
            <div class="row d-flex justify-content-center pb-2">
              <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12">
                <label class="form-label ff-gotham-light">Nome Completo<small
                  style="color:red;">*</small></label>
                <input type="text" formControlName="username" class=" form-control input-principal ff-gotham-light">
                <div class="msg-error ff-gotham-medium" *ngIf="form.get('username')?.errors?.['required']
          && form.get('username')?.touched">
                  *Campo obrigatório
                </div>
              </div>
            </div>
            <div class="row d-flex justify-content-center pb-2">
              <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12">
                <label class="form-label ff-gotham-light">Email<small style="color:red;">*</small></label>
                <input type="text" formControlName="email" class=" form-control input-principal ff-gotham-light">
                <div class="msg-error ff-gotham-medium" *ngIf="form.get('email')?.errors?.['required']
          && form.get('email')?.touched">
                  *Campo obrigatório
                </div>
              </div>
            </div>
            <div class="row d-flex justify-content-center pb-2">
              <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12">
                <label  class="form-label ff-gotham-light">Telefone<small
                  style="color:red;">*</small></label>
                <input type="text" name="telefone" formControlName="telefone"
                       class=" form-control input-principal ff-gotham-light" mask="(00) 0000-0000 ||(00) 00000-0000">
                <div class="msg-error ff-gotham-medium" *ngIf="form.get('telefone')?.errors?.['required']
          && form.get('telefone')?.touched">
                  *Campo obrigatório
                </div>
              </div>
              <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12">
                <label class="form-label ff-gotham-light">CPF<small style="color:red;">*</small></label>
                <input type="text" name="cpf" formControlName="cpf"
                       class=" form-control input-principal ff-gotham-light" mask="000.000.000-00">
                <div class="msg-error ff-gotham-medium" *ngIf="form.get('cpf')?.errors?.['required']
          && form.get('cpf')?.touched">
                  *Campo obrigatório
                </div>
              </div>
            </div>
            <div class="row d-flex justify-content-center pb-2">
              <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12">
                <label class="form-label ff-gotham-light">Produtor</label>
                <input-autocomplete
                  #autocomplete
                  positionY="bottom"
                  classes="form-control input-principal ff-gotham-light"
                  formControlName="produtor"
                  [list]="listOperadores"
                  label="apelido"
                  key="id"
                >
                  <ng-template #button_end>
                    <mat-icon (click)="autocomplete.clear()">close</mat-icon>
                  </ng-template>
                </input-autocomplete>
              </div>
            </div>
            <div class="row d-flex justify-content-center pb-2">
              <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12" *ngIf="session.checkPerfil('ROLE_ADMINISTRADOR')">
                <label for="perfil" class="form-label ff-gotham-light">Tipo de Perfil<small style="color:red;">*</small></label>
                <br>
                <select
                  formControlName="perfil"
                  id="perfil"
                  class="custom-select input-principal ff-gotham-light p-2"
                  style="width: 100%"
                >
                  <option value="">Selecione um Perfil</option>
                  <option value="ROLE_ADMINISTRADOR">Administrador</option>
                  <option value="ROLE_PRODUTOR">Usuário Comum</option>
                </select>
                <div class="msg-error ff-gotham-medium" *ngIf="form.get('perfil')?.errors?.['required']
          && form.get('perfil')?.touched">
                  *Campo obrigatório
                </div>
              </div>
            </div>
            <div *ngIf="!entity?.id" class="row d-flex justify-content-center pb-2">
              <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12">
                <label class="form-label ff-gotham-light">Senha<small
                  style="color:red;">*</small></label>
                <div class="input-password">
                  <input type="password" name="outro" formControlName="password" autocomplete="new-password"
                         class=" form-control input-principal password ff-gotham-light">
                  <span class="password-eye" (click)="mostrarPassword()">
              <mat-icon *ngIf="mostrarSenha == true">visibility</mat-icon>
              <mat-icon *ngIf="mostrarSenha == false">visibility_off</mat-icon>
            </span>
                </div>
                <div class="msg-error ff-gotham-medium" *ngIf="form.get('password')?.errors?.['required']
          && form.get('password')?.touched">
                  *Campo obrigatório
                </div>
                <div class="msg-error ff-gotham-medium"
                     *ngIf="form.get('password')?.errors?.['minLength']">
                  A senha deve conter no mínimo 6 caracteres
                </div>
              </div>
              <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12">
                <label class="form-label ff-gotham-light">Confirmar Senha<small
                  style="color:red;">*</small></label>
                <div class="input-password">
                  <input type="password" formControlName="confirmPassword"
                         class=" form-control confirmPassword input-principal ff-gotham-light"
                         (change)="passwordMatchValidator($event)">
                  <span class="password-eye" (click)="mostrarConfirmPassword()">
              <mat-icon *ngIf="mostrarConfirmSenha == true">visibility</mat-icon>
              <mat-icon *ngIf="mostrarConfirmSenha == false">visibility_off</mat-icon>
            </span>
                </div>
                <div class="msg-error ff-gotham-medium" *ngIf="form.get('confirmPassword')?.errors?.['required']
          && form.get('confirmPassword')?.touched">
                  *Campo obrigatório
                </div>
                <div class="msg-error ff-gotham-medium" *ngIf="!!error">
                  As senhas não coincidem.
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="card-footer footer-relatorio">
          <button (click)="voltar()" class="btn btn-secundario" style="margin-left: 2rem;">Voltar</button>
          <!-- <button (click)="saveOrUpdate()"  class="btn btn-principal" style="margin-left: 2rem;">Salvar</button> -->
          <button crud-salvar  class="btn btn-principal" style="margin-left: 2rem;">Salvar</button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="loader"*ngIf="showLoader">
  <div class="content-loader">
    <mat-spinner></mat-spinner>
  </div>
</div>
