import { AbstractEntity, DataServer, DgFilter_autoComplete, DgFilter_input, DgTableColumn } from "@datagrupo/dg-crud";
import { environment } from "src/environments/environment";
import { DadosBancariosEntity } from "./dados-bancarios.entity";
import { DynamicColumn, DynamicTableEntity } from "@datagrupo/dg-ng-util";

@DataServer({
  path: environment.apiUrl,
  context: 'produtor'
})
@DynamicTableEntity({
  api: {
    path: environment.apiUrl,
    context: 'produtor'
  }
})

export class ProdutorEntity extends AbstractEntity{

  constructor(
    id?: number | string,
    nome?: string | undefined,
    apelido?: string | undefined,
    email?: string | undefined,
    documento?: string | undefined,
    telefone?: string | undefined,
    produtorPaiId?: number | string,
    bankInfo?: DadosBancariosEntity
  ){
    super()
    this.id = id;
    this.nome = nome;
    this.apelido = apelido;
    this.email = email;
    this.documento = documento;
    this.telefone = telefone;
    this.produtorPaiId = produtorPaiId
    this.bankInfo = bankInfo
  }

  @DynamicColumn({headerName: 'Produtor'})
  // @DgFilter_input('text', {nameParamFind: 'produtorNome'})
  // @DgFilter_autoComplete({}, {})
  public apelido: string | undefined
  @DynamicColumn({headerName: 'Email'})
  public email: string | undefined
  @DynamicColumn({headerName: 'CPF/CNPJ', resource: (val:string, row: ProdutorEntity)=> {
    if(!val) return '--';

    val = String(val).replace(/\D/g, "")
    if(val.length == 11) return val.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/,"$1.$2.$3-$4")

    return val.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,"$1.$2.$3/$4-$5")

  }})
  public documento: string | undefined
  @DynamicColumn({headerName: 'Telefone', resource: (val: string, row: ProdutorEntity) => {
    if(!val) return '--';

    val = String(val).replace(/\D/g, "")
    if(val.length == 11) return val.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, "($1) $2 $3-$4")

    return val.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3")
  }})
  public telefone: string | undefined
  @DynamicColumn({headerName: 'Produtor Supervisor', resource: (cel: string, row: any) => {
    return row.produtorPaiApelido || row.produtorPaiNome || 'sem vínculo'
  }})
  public produtorPaiId: number | string | undefined

  public nome: string | undefined

  public bankInfo: DadosBancariosEntity | undefined
}
