import { AppMaterialModule } from './../../shared/app-material/app-material.module';
import { CadastroComponent } from './cadastro/cadastro.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component';
import { PublicRoutingModule } from './public-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { RouterModule } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';
import { PublicPaymentComponent } from './public-payment/public-payment.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DgModalModule } from '@datagrupo/dg-ng-util';



@NgModule({
  declarations: [
    HomeComponent,
    LoginComponent,
    CadastroComponent,
    PublicPaymentComponent,
  ],
  imports: [
    CommonModule,
    PublicRoutingModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    AppMaterialModule,
    MatProgressSpinnerModule,
    NgxMaskModule,
    DgModalModule
  ],
})
export class PublicModule { }
