<div class="container">
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
      <div class="card col-xl-10 col-lg-10 col-md-10 col-sm-12 profile pb-3">
        <div class="card-header perfil-header">
          <p class="card-title title ff-gotham-bold">Meu Perfil</p>
          <!-- <button class="btn btn-icon"
          (click)="toggleEditPerson()"
          >
          (click)="toggleEditPerson()"
            <mat-icon class="" fatIcon="border_color">border_color</mat-icon>
          </button> -->
        </div>
        <div class="card-body">
          <form [formGroup]="formPerfil">
            <div class="row item-form">
              <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12">
                <label for="username"  class="form-label ff-gotham-light">Nome Completo</label>
                <input type="text" formControlName="username" class="form-control input-secundario ff-gotham-light" name="username" id="username" [ngClass]="{'enabled': editPersonEnabled}"
                >
                <!-- [ngClass]="{'enabled': editPersonEnabled}"  [disabled]="!editPersonEnabled" -->
              </div>
            </div>
            <div class="row item-form">
              <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12">
                <label for="email"  class="form-label ff-gotham-light">Email</label>
                <input type="text" formControlName="email" class="form-control input-secundario ff-gotham-light" id="email" name="email" value="emaildousuario@teste.com"
                [ngClass]="{'enabled': editPersonEnabled}"
                >
                <!-- [ngClass]="{'enabled': editPersonEnabled}" [disabled]="!editPersonEnabled" -->
              </div>
            </div>
            <div class="row item-form">
              <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12">
                  <label for="telefone"  class="form-label ff-gotham-light">Telefone</label>
                  <input type="tel" formControlName="telefone" class="form-control input-secundario ff-gotham-light" id="telefone" name="telefone" mask="(00) 0000-0000 ||(00) 00000-0000"
                  [ngClass]="{'enabled': editPersonEnabled}"
                  >
                  <!-- [ngClass]="{'enabled': editPersonEnabled}" [disabled]="!editPersonEnabled" -->
              </div>
              <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12">
                <label for="cpf"  class="form-label ff-gotham-light">CPF</label>
                <input name="cpf" formControlName="cpf" id="cpf" type="text" class="form-control input-secundario ff-gotham-light" value="000.000.000/0000-00" mask="000.000.000-00 || 00.000.000/0000-00">
                <!-- [ngClass]="{'enabled': editPersonEnabled}" [disabled]="!editPersonEnabled" -->
              </div>
            </div>
            <div class="row item-form">
              <!-- <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12">
                <label for="produtor" class="form-label ff-gotham-light" >Produtor</label>
                <input type="text" formControlName="produtor" name="produtor" id="produtor" class="form-control input-secundario ff-gotham-light"
                > -->
                <!-- [ngClass]="{'enabled': editPersonEnabled}" [disabled]="!editPersonEnabled" -->
              <!-- </div> -->
              <!-- <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12">
                <label for="cnpj" class="form-label ff-gotham-light">CNPJ</label>
                <input type="text" formControlName="cnpj" name="cnpj" id="cnpj" mask="00.000.000/0000-00" class="form-control input-secundario ff-gotham-light"
                > -->
                <!-- [ngClass]="{'enabled': editPersonEnabled}" [disabled]="!editPersonEnabled" -->
              <!-- </div> -->
            </div>
          </form>
        </div>
        <div class="card-footer perfil-footer">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12 mx-4">
              <!-- <div class="row justify-content-center">
                <button class="btn btn-principal ff-gotham-bold btn-form m-2"
                [disabled]="!editPersonEnabled"
                (click)="updateUser()"
                >Salvar Alterações</button>

              </div> -->
            </div>
            <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12 mx-4">
              <div class="row justify-content-center">
                <button class="btn btn-principal ff-gotham-bold btn-form m-2" (click)="modal.open()">Mudar Senha</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>>

  <dg-modal size="sm" #modal>
    <div modal-header>
      <h4 class="ff-gotham-medium">Mudar Senha</h4>
    </div>
    <div modal-body>
      <form [formGroup]="formSenha">
        <!-- *ngIf="!formSenha.controls.senhaAtual.disable" -->
          <div  class="col-xl-10 col-lg-10 col-md-12 col-sm-12 pb-2">
            <label for="senhaAtual" class="form-label ff-gotham-light">Senha Atual<small style="color:red;">*</small></label>
            <div class="input-password">
              <input class="form-control password input-principal" name="senhaAtual" formControlName="senhaAtual" type="password" id="senhaAtual">
              <span class="password-eye" (click)="currentPassword()">
                <mat-icon *ngIf="mostrarCurrentPass == true">visibility</mat-icon>
                <mat-icon *ngIf="mostrarCurrentPass == false">visibility_off</mat-icon>
              </span>
            </div>
            <div class="msg-error ff-gotham-medium" *ngIf="formSenha.get('senhaAtual')?.errors?.['required']
              && formSenha.get('senhaAtual')?.touched">
                *Campo obrigatório
              </div>
          </div>

          <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12 pb-2">
            <label for="senhaNova" class="form-label ff-gotham-light">Nova Senha<small style="color:red;">*</small></label>
            <div class="input-password">
              <input class="form-control newPassword input-principal" name="senhaNova" formControlName="senhaNova" type="password" id="senhaNova">
              <span class="password-eye" (click)="newPassword()">
                <mat-icon *ngIf="mostrarNewPass == true">visibility</mat-icon>
                <mat-icon *ngIf="mostrarNewPass == false">visibility_off</mat-icon>
              </span>
            </div>
            <div class="msg-error ff-gotham-medium" *ngIf="formSenha.get('newPass')?.errors?.['required']
              && formSenha.get('senhaNova')?.touched">
                *Campo obrigatório
              </div>
              <div class="msg-error ff-gotham-medium"
                    *ngIf="formSenha.get('senhaNova')?.errors?.['minLength']">
                      A senha deve conter no mínimo 6 caracteres
                    </div>
          </div>
          <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12 pb-2">
            <label for="confirmarSenhaNova" class="form-label ff-gotham-light">Confirmar senha<small style="color:red;">*</small></label>
            <div class="input-password">
              <input class="form-control confirmPassword input-principal" name="confirmarSenhaNova" formControlName="confirmarSenhaNova" type="password" id="confirmarSenhaNova" (change)="passwordMatchValidator($event)">
              <span class="password-eye" (click)="confirmPassword()">
                <mat-icon *ngIf="mostrarConfirmPass == true">visibility</mat-icon>
                <mat-icon *ngIf="mostrarConfirmPass == false">visibility_off</mat-icon>
              </span>
            </div>
            <div class="msg-error ff-gotham-medium" *ngIf="formSenha.get('confirmarSenhaNova')?.errors?.['required']
              && formSenha.get('confirmarSenhaNova')?.touched">
                *Campo obrigatório
              </div>
              <div class="msg-error ff-gotham-medium" *ngIf="!!error">
                As senhas não coincidem.
              </div>
          </div>
      </form>
    </div>
    <div modal-footer>
      <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 d-flex flex-row justify-content-end">
          <button class="btn btn-secundario" style="margin-right: 2rem; width:50%" (click)="cancelar()">Cancelar</button>
          <button class="btn btn-principal" (click)="patchPassword()">Salvar</button>
        </div>
      </div>
    </div>
  </dg-modal>
</div>
