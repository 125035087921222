<div class="container-fluid">
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 d-flex justify-content-end ">
      <button routerLink="/user/rd/novo" class="btn btn-principal ff-gotham-bold button-NovoPedido">
        <mat-icon class="pt-1">add_circle_outline</mat-icon>
        Novo Pedido de Cotação
      </button>
    </div>
  </div>
</div>
<br>
<div class="container-fluid">
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
      <div class="card card-meusSeguros">
        <div class="card-header ff-gotham-bold header-meusSeguros">
          <h4 class="card-title">Registro Cotações Riscos Diversos</h4>
        </div>
        <div class="card-body" style="overflow-x: auto; margin-right: 2rem;">
          <crud-data-table
            #table
            tableEventId="relatorioRd"
            [entityMap]="entity"
            [resourceDictionary]="dictionary"
            ></crud-data-table>
            <!-- [showPagination]="true" -->
        </div>
        <div class="card-footer d-flex justify-content-center">
          <dg-pagination
          [dataPagination]="entity.configEntityTable.pagination"
          (changePage)="table.changePages($event)"
          ></dg-pagination>
        </div>
      </div>
    </div>
  </div>
</div>


