import { AddressSAEntity } from "./address-solAgora.entity";

export class PersonSAEntity {
  constructor(data: {
    name?: string,
    document?: string,
    mobile?: string,
    email?: string,
    dateOfBirth?: string,
    address?: AddressSAEntity,
    billingAddress?: AddressSAEntity,
    isPep?: boolean,
    type?: 'PF' | 'PJ',
    documentType?: 'CPF' | 'CNPJ'
  }) {
    this.name = data.name;
    this.document = data.document;
    this.mobile = data.mobile;
    this.email = data.email;
    this.address = data?.address;
    this.isPep = data?.isPep;
    this.type = data?.type ? data.type : 'PF'
    this.documentType = data?.documentType ? data.documentType : 'CPF'
    this.dateOfBirth = data?.dateOfBirth;
  }

  name: string | undefined
  document: string | undefined
  mobile: string | undefined
  email: string | undefined
  address: AddressSAEntity | undefined;
  billingAddress: AddressSAEntity | undefined;
  isPep: boolean | undefined;
  type: 'PF' | 'PJ';
  documentType: 'CPF' | 'CNPJ';
  dateOfBirth: string | undefined
}
