import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {UserLayoutComponent} from "./user-layout/user-layout.component";
import {RouterModule} from "@angular/router";
import { PrivateHeaderComponent } from './sub-components/private-header/private-header.component';
import {MatIconModule} from "@angular/material/icon";
import {DgSidebarMenuModule} from "@datagrupo/dg-ng-util";



@NgModule({
  declarations: [UserLayoutComponent, PrivateHeaderComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    DgSidebarMenuModule
  ],
  exports: [UserLayoutComponent]
})
export class PrivateLayoutModule { }
