import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatStepperModule } from '@angular/material/stepper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgModule } from '@angular/core';
import { NgxMaskModule } from 'ngx-mask';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
  exports: [
    MatIconModule,
    MatStepperModule,
    MatFormFieldModule,
    MatTableModule,
    NgxMaskModule,
    MatTooltipModule
  ],

})
export class AppMaterialModule { }
