import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CdkDynamicTable, CdkDynamicTableService, DgModalComponent } from '@datagrupo/dg-ng-util';
import { Observable } from 'rxjs';
import { SessionService } from 'src/app/core/service/session/session.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { InstallmentListSA } from '../entitys/installment-list-solAgora.entity';
import { SolAgoraEntity } from '../entitys/solAgora.entity';

@Component({
  selector: 'app-sol-agora-main',
  templateUrl: './sol-agora-main.component.html',
  styleUrls: ['./sol-agora-main.component.scss']
})
export class SolAgoraMainComponent implements OnInit, OnDestroy {

  @ViewChild('modal') modal!: DgModalComponent;

  public table: CdkDynamicTable.tableClass;
  public showLoader = false;
  public entity = new SolAgoraEntity()
  public formFilters = new FormGroup({
    proposalNo: new FormControl(''),
    status: new FormControl(''),
    effectiveDate: new FormControl(''),
    expiryDate: new FormControl(''),
    personName: new FormControl(''),
    issueDateStart: new FormControl(''),
    issueDateEnd: new FormControl(''),
    produtor: new FormControl(''),
    certificate: new FormControl('')
  })

  constructor(
    private createTable: CdkDynamicTableService,
    private http: HttpClient,
    private router: Router,
    private session: SessionService
  ) {
    // Cria a instância da tabela
    this.table = createTable.createByEntity(new SolAgoraEntity(), {
      // Definir configurações de paginação
      pipePagination: (p:any) => {
        console.log('pipePagination', p.page)
        return {
          page: p?.page?.number || 0,
          totalPages: p?.page?.totalPages || 0,
          totalElements: p?.page?.totalElements || 0
        };
      },
      // Definir configurações de filtros
      filters: {filters: {}, group: "tableCotacao", reactive: false},
      // Definir configurações de ações
      actions: {
        add : {
          view: {
            name: 'Visualizar',
            dbClick: true,
            action: (val:SolAgoraEntity) => {
              this.showLoader = true;
              this.viewProposal(val)
            }
          },
          downloadProposal: {
            name: 'Baixar Proposta',
            action: (val: SolAgoraEntity) => {
              this.showLoader = true;
              this.downloadProposal(val)
          }
        }
      }
    }})
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.table.destroy()
  }

  dictionary(data: any[]) {
    return data;
  }

  // Obter proposta da API
  private getProposal(val: SolAgoraEntity): Observable<any> {
    const proposalId = val.id;
    return this.http.get(`${environment.apiUrl}akad/proposal/${proposalId}`);
  }

  // Baixar proposta PDF da API
  private downloadProposalPdf(val: SolAgoraEntity): Observable<any> {
    const proposalId = val.id;
    return this.http.post(`${environment.apiUrl}akad/solagora/proposal/${proposalId}`, {}, { responseType: 'arraybuffer' });
  }


  private viewProposal(val: SolAgoraEntity) {
    this.getProposal(val).subscribe((response: any) => {
      this.entity = {
        ...response.data,
        vat: response.data.vat,
        netPremium: response.data.netPremium,
        premium: response.data.premium,
        name: response.data.person?.name,
        document: response.data.person?.document,
        email: response.data.person?.email,
        dateOfBirth: response.data.person?.dateOfBirth,
        cep: response.data.person?.address?.postCode,
        street: response.data.person?.address?.street,
        number: response.data.person?.address?.number,
        city: response.data.person?.address?.city,
        state: response.data.person?.address?.state,
        suburb: response.data.person?.address?.suburb,
        complement: response.data.person?.address?.complement,
        billingCep: response.data.person?.billingAddress?.postCode,
        billingStreet: response.data.person?.billingAddress?.street,
        billingNumber: response.data.person?.billingAddress?.number,
        billingCity: response.data.person?.billingAddress?.city,
        billingState: response.data.person?.billingAddress?.state,
        billingSuburb: response.data.person?.billingAddress?.suburb,
        billingComplement: response.data.person?.billingAddress?.complement,
        payer: response.data.payment?.payer?.name,
        payerDocument: response.data.payment?.payer?.document,
        payerEmail: response.data.payment?.payer?.email,
        payment: {
          installments: response.data.payment?.installments?.map((installment: InstallmentListSA) => ({
            installment: installment.installment,
            billetUrl: installment.billetUrl,
          })),
        }
      };
      this.showLoader = false;
      this.modal.open();
    }, (error) => {
      Swal.fire({
        icon: 'error',
        title: 'Erro ao carregar resumo do certificado.',
        text: error.message
      });
      this.showLoader = false;
    });
  }


  private downloadProposal(val: SolAgoraEntity) {
    this.getProposal(val).subscribe((response: any) => {
      this.entity.id = response.data.id;
      this.entity.proposalNo = response.data.proposalNo;
      this.entity.name = response.data.person?.name
      console.log('proposta', this.entity.proposalNo);
      this.downloadProposalPdf(val).subscribe((response: any) => {
        const blob = new Blob([response], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${this.entity.proposalNo} - ${this.entity.name}.pdf`;
        a.target = '_blank';
        a.click();
        this.showLoader = false;
      });
    }, (error) => {
      Swal.fire({
        icon: 'error',
        title: 'Erro ao baixar proposta',
        text: error.message
      });
      this.showLoader = false;
    });
  }

  maskValores(val: any) {
    if (!val) return '0,00';
    return Number(val).toLocaleString('BRL', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
  }

  maskDocument(val: any){
    if(val.length == 11) return val.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/,"$1.$2.$3-$4")
      return val.replace(/(\d{2})(\d{3})(\d{3})(\d{3})(\d{2})/,"$1.$2.$3/$4-$5")
  }

  maskMobile(val: any){
    if(val.length == 11) return val.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/,"($1) $2 $3-$4")
      return val.replace(/(\d{2})(\d{4})(\d{4})/,"($1) $2-$3")
  }

  maskDate(val: any){
    return  new Date(val).toLocaleString('pt-br', {timeZone: 'UTC'}).slice(0, 10);
  }

}
