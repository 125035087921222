<nav id="user-header-principal" class="navbar navbar-expand-md">
  <div class="container-fluid">

    <button style="border: none; background: none" class="mr-auto" (click)="sidebarChange()">
      <mat-icon [hidden]="sidebaOpened()">menu</mat-icon>
      <mat-icon [hidden]="!sidebaOpened()">menu_open</mat-icon>
    </button>

    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#header-user-minha-coleta" aria-controls="header-user-minha-coleta" aria-expanded="false" aria-label="Toggle navigation">
      <mat-icon>menu</mat-icon>
    </button>

    <div class="collapse navbar-collapse h-100" id="header-user-minha-coleta">
      <ul class="navbar-nav ms-auto mb-2 mb-lg-0 h-100">
        <li class="nav-item">
          <mat-icon class="icon-center">account_circle</mat-icon>
          <span  class="ff-gotham-light user">{{session.user.name}}</span>
        </li>
        <li class="nav-item user-item">
          <mat-icon class="icon-center">logout</mat-icon>
          <span class="ff-gotham-light user logout" routerLink="/login" (click)="session.logout()">SAIR</span>
        </li>
        <!-- <li class="nav-item" (click)="openModalSearch()">
          <span class="nav-link" aria-current="page" style="cursor: pointer;">
            <mat-icon>manage_search</mat-icon>
          </span>
        </li> -->
        <!-- <li class="nav-item">
          <span class="nav-link" aria-current="page" style="cursor: pointer;" routerLink="/user/solicitar-coleta-pontual">
            <mat-icon>calendar_month</mat-icon>
            Solicitar Coleta Pontual
            <span class="badge bg-success fs-7">Beta</span>
          </span>
        </li> -->

        <!-- <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle"
            data-bs-toggle="dropdown"
            data-bs-target="#dropdown-sobre-as-coletas"
            href="#"
            role="button"
            aria-expanded="false"
          >
            <mat-icon>recycling</mat-icon>
            Sobre sua coleta
          </a>
          <ul id="dropdown-sobre-as-coletas" class="dropdown-menu dropdown-menu-end">
            <li><a class="dropdown-item" routerLink="/user/historico-coletas">
              <mat-icon>history_toggle_off</mat-icon>
              Histórico das Coletas
            </a></li>
            <li><a class="dropdown-item" routerLink="/user/ranking-coletas">
              <mat-icon>rocket</mat-icon>Ranking
            </a></li>
          </ul>
        </li> -->

        <!-- <li class="nav-item" check-perfil perfil="CONDOMINIO">
          <div class="nav-link meus-pontos">
            <div>
              <p style="text-align: center; margin: 0">Meus Pontos</p>
              <p style="text-align: center" class="f-bold" >{{!!session.pontuacao || session.pontuacao == 0 ? session.pontuacao : '---'}}</p>
            </div>
          </div>
        </li> -->

        <!-- <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle"
            data-bs-toggle="dropdown"
            href="#"
            role="button"
            aria-expanded="false"
          >
            <mat-icon>quiz</mat-icon>
          </a>
          <ul class="dropdown-menu dropdown-menu-end">
            <li>
              <a class="dropdown-item" routerLink="/user/faq">
                <mat-icon>help</mat-icon>
                FAQ
              </a>
            </li>
            <li>
              <span style="cursor:pointer;" class="dropdown-item" (click)="openNewsletter()">
                <mat-icon>receipt_long</mat-icon>
                Link para newsletter
              </span>
            </li>
            <li>
              <a class="dropdown-item" routerLink="/user/tutorial">
                <mat-icon>construction</mat-icon>
                Tutorial
              </a>
            </li>
            <li><hr class="dropdown-divider"></li>
            <li>
              <span style="cursor:pointer;" class="dropdown-item"(click)="openLix()">
                <mat-icon>warning</mat-icon>
                Tire dúvidas com a Lix
              </span>
            </li>
          </ul>
        </li> -->

        <!-- <li class="nav-item">
           <span style="cursor:pointer" (click)="sair()" class="nav-link" aria-current="page">Sair</span>
        </li> -->
      </ul>
    </div>

  </div>
</nav>
