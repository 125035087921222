import { perfil } from './../../../interfaces/perfil';
import { TokenService } from '../token/token.service';
import { Injectable } from '@angular/core';
import { AbstractSessionService } from '@datagrupo/dg-ng-util';

class UserAppEntity{
  id?: number | string;
  name?: string;
  perfil?: string
}

declare type tipoPerfil = 'ROLE_ADMINISTRADOR' | 'ROLE_PRODUTOR'

@Injectable({
  providedIn: 'root'
})
export class SessionService extends AbstractSessionService {

  user: UserAppEntity = {};
  tokenAkad: string | undefined;

  constructor(
    public token: TokenService
  ) {
    super(token);
    this.pathRedirectLogout = document.location.origin + '/login'
  }

  override checkPerfil(perfil: tipoPerfil | (tipoPerfil)[]): boolean {
    return super.checkPerfil(perfil, 'perfil');
  }

  setUser(): void {
    const token: any = this.token.abrirToken();
    this.user.name = token.Username
    this.user.id = token.sub,
    this.user.perfil = token.Role
    console.log()
  }
}
