<div class="container-fluid">
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 d-flex justify-content-end" *ngIf="session.checkPerfil('ROLE_ADMINISTRADOR')">
      <button routerLink="novo" class="btn btn-principal ff-gotham-bold d-inline-flex justify-content-center align-items-center">
        <mat-icon style="margin-right: 1rem;">person_add</mat-icon>
        Cadastrar Usuário
      </button>
    </div>
  </div>

  <div class="row pt-3">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
      <div class="card card-relatorio">
        <div class="card-header ff-gotham-bold header-relatorio">
          <h4 class="card-title">Registro de Usuários</h4>
        </div>
        <div class="card-header">
          <form [formGroup]="formFilters">
            <div class="row">
              <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                <label class="form-lable">Usuario</label>
                <input class="form-control" formControlName="username" dg-dynamic-group="tableUsuarios" paramName="username">
              </div>
              <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                <label class="form-lable">Produtor</label>
                <input class="form-control" formControlName="produtorApelido" dg-dynamic-group="tableUsuarios" paramName="produtorApelido">
              </div>
            </div>
        </form>
        <div class="row mt-3">
          <div class="col-12 d-inline-flex justify-content-end">
            <button dynamic-group-control="tableUsuarios" filter class="btn btn-principal ff-gotham-medium btn-sm m-1">Filtrar</button>
            <button dynamic-group-control="tableUsuarios" clear class="btn btn-secondary btn-sm m-1">Limpar</button>
          </div>
        </div>
        </div>

        <div class="card-body tbl1">
          <dg-table dg-dynamic-table
          [dynamicTable]="table"
          class="tbl2"
          classes="tbl3 crud-table" >
          </dg-table>
        </div>
        <div class="card-footer justify-content-center d-inline-flex">
          <dg-paginator dg-dynamic-paginator [dynamicTable]="table"></dg-paginator>
      </div>
      </div>
    </div>
  </div>
</div>

<dg-modal (afterClose)="afterClose()" size="md" #modal>
  <div dg-header>
    <h4 class="ff-gotham-medium">Dados do Usuário</h4>
  </div>
  <div modal-body>

  </div>
  <div dg-footer>
    <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 d-flex flex-row justify-content-end">
        <button class="btn btn-secundario" style="margin-right: 2rem; width:50%">Cancelar</button>
        <button class="btn btn-principal" (click)="criarUsuario()">Salvar</button>
      </div>
    </div>
  </div>
</dg-modal>

