import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PrivateLayoutModule} from "./private-layout/private-layout.module";
import {PublicLayoutModule} from "./public-layout/public-layout.module";


@NgModule({
  imports: [
    CommonModule,
    PrivateLayoutModule,
    PublicLayoutModule,
  ],
})
export class LayoutsModule { }
