import { Route, Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-contact-user',
  templateUrl: './contact-user.component.html',
  styleUrls: ['./contact-user.component.scss']
})
export class ContactUserComponent implements OnInit {

  public form = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.pattern(/(.|\s)*\S(.|\s)*/)]),
    email: new FormControl('', [
      Validators.required,
      Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)]),
    password: new FormControl('', [
      Validators.required,
      Validators.pattern(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/)]),
    telefone: new FormControl('', [
      Validators.required,
      Validators.pattern(/^\([1-9]{2}\)[0-9]{4,5}-[0-9]{4}$/)]),
    mensagem: new FormControl('', [
      Validators.required,
      Validators.pattern(/(.|\s)*\S(.|\s)*/)
    ])
  })

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
  }

  enviar(){
    this.router.navigate(['/user/success']);
  }
}
