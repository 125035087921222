import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { perfil } from 'src/app/interfaces/perfil';



@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient
  ) { }

  createUser(user: any){
    return this.http.post(environment.apiUrl + 'auth/signup', user)
  }

  buscarUserId(id?:number | string): Observable<{data: perfil}>{
    return this.http.get<{data:perfil}>(environment.apiUrl + `users/${id}` )
  }

  editUser(data?: any): Observable<perfil>{
    return this.http.put(environment.apiUrl + `users/${data.id}`, data)
  }

  saveOrUpdate(id?: number | string, data?: perfil): Observable<perfil>{
    if(data?.id){
      return this.http.put(environment.apiUrl + `users/${id}`, data)
    }else{
      return this.http.post(environment.apiUrl + `users/${id}`, data)
    }
  }

}
