export class PayerEntity {
  constructor(data: {
    name?: string | null | undefined
    document?: string | null | undefined
    email?: string | null | undefined
    streetName?: string | null | undefined
    unitNumber?: string | null | undefined
    complementary?: string | null | undefined
    city?: string | null | undefined
    state?: string | null | undefined
    postalCode?: string | null | undefined
  }){
    this.name = data.name
    this.document = data.document
    this.email = data.email
    this.streetName = data.streetName
    this.unitNumber = data.unitNumber
    this.complementary = data.complementary
    this.city = data.city
    this.state = data.state
    this.postalCode = data.postalCode
  }
  public name : string | null | undefined
  public document : string | null | undefined
  public email : string | null | undefined
  public streetName : string | null | undefined
  public unitNumber : string | null | undefined
  public complementary : string | null | undefined
  public city : string | null | undefined
  public state : string | null | undefined
  public postalCode : string | null | undefined

}
