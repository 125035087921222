<div class="container mt-2">
  <div class="row">
    <div class="col-12">
      <div class="card card-relatorio">
        <div class="card-header ff-gotham-bold header-relatorio">
          <h4 class="card-title">Registro de Produtor</h4>
        </div>
        <div class="card-body">
          <form [formGroup]="form">
            <div class="row d-flex justify-content-center pb-2">
              <div class="col-xl-10 col-lg-10 col-md-12 col-sm-">
                <label for="nome" class="form-label ff-gotham-light">Nome / Razão Social<small style="color:red;">*</small></label>
                <input type="text" name="nome" formControlName="nome" class=" form-control input-principal ff-gotham-light">
                <div class="msg-error ff-gotham-medium" *ngIf="form.get('nome')?.errors?.['required']
            && form.get('nome')?.touched">
                  *Campo obrigatório
                </div>
              </div>
            </div>
            <div class="row d-flex justify-content-center pb-2">
              <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12">
                <label for="apelido" class="form-label ff-gotham-light">Apelido / Nome Fantasia<small style="color:red;">*</small></label>
                <input type="text" name="apelido" formControlName="apelido" class=" form-control input-principal ff-gotham-light">
                <div class="msg-error ff-gotham-medium" *ngIf="form.get('apelido')?.errors?.['required']
            && form.get('apelido')?.touched">
                  *Campo obrigatório
                </div>
              </div>
            </div>
            <div class="row d-flex justify-content-center pb-2">
              <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12">
                <label for="email" class="form-label ff-gotham-light">Email<small style="color:red;">*</small></label>
                <input type="text" name="email" formControlName="email" class=" form-control input-principal ff-gotham-light">
                <div class="msg-error ff-gotham-medium" *ngIf="form.get('email')?.errors?.['required']
            && form.get('email')?.touched">
                  *Campo obrigatório
                </div>
              </div>
            </div>
            <div class="row d-flex justify-content-center pb-2">
              <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12">
                <label class="form-label ff-gotham-light">Produtor Supervisor</label>
                <br>
                <input-autocomplete
                  #autocomplete
                  classes="form-control input-principal ff-gotham-light"
                  formControlName="produtorPaiId"
                  [list]="listOperadores"
                  label="apelido"
                  key="id"
                >
                  <ng-template let-produtor #row_option>
                    {{produtor.row?.apelido || produtor.row?.nome || 'Sem Vínculo'}}
                  </ng-template>
                  <ng-template #button_end>
                    <mat-icon (click)="autocomplete.clear()">close</mat-icon>
                  </ng-template>
                </input-autocomplete>
                <div class="msg-error ff-gotham-medium" *ngIf="form.get('produtorPaiId')?.errors?.['required']
          && form.get('produtorPaiId')?.touched">
                  *Campo obrigatório
                </div>
              </div>
            </div>
            <div class="row d-flex justify-content-center pb-2">
              <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12">
                <label class="form-label ff-gotham-light">Telefone<small style="color:red;">*</small></label>
                <input type="text" name="telefone" formControlName="telefone" class=" form-control input-principal ff-gotham-light" mask="(00) 0000-0000 ||(00) 00000-0000">
                <div class="msg-error ff-gotham-medium" *ngIf="form.get('telefone')?.errors?.['required']
            && form.get('telefone')?.touched">
                  *Campo obrigatório
                </div>
              </div>
              <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12">
                <label class="form-label ff-gotham-light">CPF/CNPJ<small style="color:red;">*</small></label>
                <input type="text" name="cpf" formControlName="documento" class=" form-control input-principal ff-gotham-light"  mask="000.000.000-00 || 00.000.000/0000-00">
                <div class="msg-error ff-gotham-medium" *ngIf="form.get('documento')?.errors?.['required']
            && form.get('documento')?.touched">
                  *Campo obrigatório
                </div>
              </div>
            </div>
          </form>
        </div>

        <div class="card card-banco pb-3" *ngIf="session.checkPerfil('ROLE_ADMINISTRADOR')">
          <div class="card-header banco-header">
            <p class="card-title title ff-gotham-bold">Dados Bancários</p>
          </div>
          <div class="card-body body-banco">
            <form [formGroup]="formBanco">
              <div class="row d-flex justify-content-center pb-2">
                <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                  <label for="banco" class="form-label ff-gotham-light">Banco</label>
                  <input type="text" id="banco" name="banco" formControlName="banco" class="form-control input-principal ff-gotham-light">
                </div>
                <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                  <label for="numOperacao" class="form-label ff-gotham-light">Número da Operação</label>
                  <br>
                  <input type="text" id="numOperacao" name="numOperacao" formControlName="numOperacao" class="form-control input-principal ff-gotham-light">
                </div>
                <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                  <label for="conta" class="form-label ff-gotham-light">Tipo de Conta</label>
                  <br>
                  <select id="conta" name="conta" formControlName="conta" class="form-select input-principal select-formulario ff-gotham-light">
                    <option value="selecione" selected>Selecione o tipo de conta...</option>
                    <option value="corrente">Conta Corrente</option>
                    <option value="poupanca">Conta Poupança</option>
                  </select>
                </div>
              </div>

              <div class="row d-flex justify-content-center pb-2">
                <div class="col-xl-2 col-lg-2 col-md-3 col-sm-12">
                  <label for="agencia" class="form-label ff-gotham-light">Agência</label>
                  <input type="text" name="agencia" formControlName="agencia" class="form-control input-principal ff-gotham-light">
                </div>
                <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                  <label for="numConta" class="form-label ff-gotham-light">Nº Conta</label>
                  <input type="text" id="numConta" name="numConta" formControlName="numConta" class="form-control input-principal ff-gotham-light">
                </div>
              </div>

              <div class="row d-flex justify-content-center pb-2">
                <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12">
                  <label for="pix" class="form-label ff-gotham-light">Chave Pix</label>
                  <input type="text" name="pix" formControlName="pix" id="pix" class="form-control input-principal">
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="card-footer footer-relatorio">
          <button (click)="voltar()" class="btn btn-secundario m-1" style="margin-left: 2rem;">Voltar</button>
          <button crud-salvar class="btn btn-principal m-1" style="margin-left: 2rem;">Salvar</button>
        </div>
      </div>
    </div>
  </div>
</div>
