import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ProdutorEntity } from '../entitys/produtor.entity';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CdkDynamicTable, CdkDynamicTableService, DgModalComponent } from '@datagrupo/dg-ng-util';
import { HttpClient, crudDispatchEvent } from '@datagrupo/dg-crud';
import { environment } from 'src/environments/environment';
import { SessionService } from 'src/app/core/service/session/session.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-produtor',
  templateUrl: './produtor.component.html',
  styleUrls: ['./produtor.component.scss']
})
export class ProdutorComponent implements OnInit, OnDestroy {

  @ViewChild('modal') modal!: DgModalComponent
  // @ViewChild('table')

  public table: CdkDynamicTable.tableClass
  public tableMap = new ProdutorEntity()

  listOperadores: ProdutorEntity[] = [];

  public formFilters = new FormGroup({
    produtorApelido: new FormControl(''),
    produtorSupervisorApelido: new FormControl('')
  })

  public form = new FormGroup({
    id: new FormControl(''),
    nome: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    telefone: new FormControl(''),
    documento: new FormControl('', [Validators.required]),
    apelido: new FormControl(''),
    produtor: new FormControl('')
  })


  constructor(
    private router: Router,
    private http: HttpClient,
    public session: SessionService,
    private createTable: CdkDynamicTableService,
  ) {
    this.table = createTable.createByEntity(new ProdutorEntity(), {
      pipePagination: (p:any) => {
        console.log('pipePagination', p.page)
        return {
          page: p?.page?.number || 0,
          totalPages: p?.page?.totalPages || 0,
          totalElements: p?.page?.totalElements || 0
        };
      },
      filters: {filters: {},
      group: "tableProdutor", reactive: false},
      actions: {
        add: {
          edit: {
            name: 'Editar',
            action: (row: ProdutorEntity) => {router.navigate(['user', 'produtor', row.id])},
            permission: !!session.checkPerfil('ROLE_ADMINISTRADOR') ? true : false,
          },
          remove: {
            name: 'Excluir',
            action: (row: ProdutorEntity) => this.delete(row),
            permission: !!session.checkPerfil('ROLE_ADMINISTRADOR')? true : false
          }
        }
      }})

    this.tableMap.addActions([{
      name: 'Editar',
      action: (row: ProdutorEntity) => {router.navigate(['user', 'produtor', row.id])},
      permission: !!session.checkPerfil('ROLE_ADMINISTRADOR') ? true : false,
    },
    {
      name: 'Excluir',
      action: (row: ProdutorEntity) => this.delete(row),
      permission: !!session.checkPerfil('ROLE_ADMINISTRADOR')? true : false
    }
    ])
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.table.destroy()
  }

  dictionary(data: any[]) {
    return data;
  }

  salvarProdutor(){
    if(this.form.invalid){
      this.form.markAllAsTouched()
      return
    }

    const form = this.form.value

    // @ts-ignore
    // form.produtor = { id: produtor.produtor }

    // const context = form?.id ? 'produtor/' + form.id : 'produtor'

    this.http[form?.id ? 'put' : 'post'](environment.apiUrl + "produtor", form).subscribe((r:any)=> {
      crudDispatchEvent('listaProdutores')
      this.modal.close()
    })

    return


  }

  afterClose(){
    this.form.reset()
  }

  modalOpen() {
    this.http.get(environment.apiUrl + 'produtor', { params: { unpaged: true } }).subscribe((resp: any) => {
      this.listOperadores = resp.data;
      this.modal.open()
    })
  }

  delete(row: ProdutorEntity){
    Swal.fire({
      icon: 'question',
      title: 'Excluir Produtor',
      text: 'Deseja excluir esse produtor? Essa ação será permanente',
      showCancelButton: true,
      cancelButtonColor: 'red',
      showConfirmButton: true,
      confirmButtonColor: 'blue'
    }).then((opt => {
      if(opt.isConfirmed){
        this.http.delete(environment.apiUrl + `produtor/${row.id}`).subscribe(
          (resp)=> {
            Swal.fire({
              icon: 'success',
              title: 'Produtor excluído com sucesso!'
            })
            this.table.controls.data.find()
        }, ()=> {
          Swal.fire({
            icon: 'error',
            title: 'Erro ao excluir produtor.'
          })
        })
      }
    }))
  }

}

