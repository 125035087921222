

export class CreditCardEntity {
  constructor(data: {
    installment?: number | null,
    name?: string | null,
    number?: string | null,
    identity?: string | null,
    expiration?: string | null,
    cvv?: string | null,
  }) {
    this.installment = data?.installment;
    this.name = data?.name;
    this.number = data?.number;
    this.identity = data?.identity;
    this.expiration = data?.expiration;
    this.cvv = data?.cvv;
  }

  installment: number | null | undefined;
  name: string | null | undefined;
  number: string | null | undefined;
  identity: string | null | undefined;
  expiration: string | null | undefined;
  cvv: string | null | undefined;
}
