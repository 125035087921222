export function isValidCPF(cpf: string, strict: boolean = false): boolean {
  cpf = cpf.replace(/[^0-9]/g, '');

  if (cpf.length!== 11) {
    return false;
  }

  if (strict &&!/^\d{11}$/.test(cpf)) {
    return false;
  }

  let sum = 0;
  let weight = 10;

  for (let n = 0; n < 9; n++) {
    sum = sum + parseInt(cpf.charAt(n)) * weight;
    weight = weight - 1;
  }

  let verifyingDigit = 11 - (sum % 11);
  if (verifyingDigit > 9) {
    verifyingDigit = 0;
  }

  if (cpf.charAt(9)!== verifyingDigit.toString()) {
    return false;
  }

  sum = 0;
  weight = 11;

  for (let n = 0; n < 10; n++) {
    sum = sum + parseInt(cpf.charAt(n)) * weight;
    weight = weight - 1;
  }

  verifyingDigit = 11 - (sum % 11);
  if (verifyingDigit > 9) {
    verifyingDigit = 0;
  }

  if (cpf.charAt(10)!== verifyingDigit.toString()) {
    return false;
  }

  return true;
}

export function isValidCNPJ(cnpj: string, strict: boolean = false): boolean {
  cnpj = cnpj.replace(/[^0-9]/g, '');

  if (cnpj.length!== 14) {
    return false;
  }

  if (strict &&!/^\d{14}$/.test(cnpj)) {
    return false;
  }

  let sum = 0;
  let weight = 5;

  for (let n = 0; n < 12; n++) {
    sum = sum + parseInt(cnpj.charAt(n)) * weight;
    weight = weight - 1;

    if (weight < 2) {
      weight = 9;
    }
  }

  let verifyingDigit = 11 - (sum % 11);
  if (verifyingDigit > 9) {
    verifyingDigit = 0;
  }

  if (cnpj.charAt(12)!== verifyingDigit.toString()) {
    return false;
  }

  sum = 0;
  weight = 6;

  for (let n = 0; n < 13; n++) {
    sum = sum + parseInt(cnpj.charAt(n)) * weight;
    weight = weight - 1;

    if (weight < 2) {
      weight = 9;
    }
  }

  verifyingDigit = 11 - (sum % 11);
  if (verifyingDigit > 9) {
    verifyingDigit = 0;
  }

  if (cnpj.charAt(13)!== verifyingDigit.toString()) {
    return false;
  }

  return true;
}
