import { Component, OnDestroy, OnInit } from '@angular/core';
import { SessionService } from 'src/app/core/service/session/session.service';
import { adminMenuList } from '../../../core/config/menu/admin.menulist';

@Component({
  selector: 'app-user-layout',
  templateUrl: './user-layout.component.html',
  styleUrls: ['./user-layout.component.scss']
})
export class UserLayoutComponent implements OnInit, OnDestroy {

  public menuList = adminMenuList
  navigateMenu: any;

  constructor(private session: SessionService) {
      if(this.session.checkPerfil('ROLE_ADMINISTRADOR')){
        this.menuList[0].menuList[1].subMenu?.push({icon: '', nome: 'Sol Agora', url: '/user/solAgora' })
      }
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}

}
