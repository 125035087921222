import {Injectable} from '@angular/core';
import {TokenService} from './../../service/token/token.service';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {AbstractAddToken} from '@datagrupo/dg-ng-util';
import {Observable} from 'rxjs';

@Injectable()
export class AddTokenInterceptor extends AbstractAddToken implements HttpInterceptor {

  constructor(
    private token: TokenService,
  ) {
    super(token)
  }


  pipeRequestRefreshToken(resp: any) {
    return resp.data.access_token
  }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (req.headers.get('noAddToken')) {
      return next.handle(req);
    }

    return this.rootIntercept(req, next)
  }
}

