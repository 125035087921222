import { Router } from '@angular/router';
import { SessionService } from './../../../../core/service/session/session.service';
import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AbstractInsertEdit, InsertEditConfig} from "@datagrupo/dg-crud";
import {UsuariosEntity} from "../usuarios.entity";
import {environment} from "../../../../../environments/environment";
import {ProdutorEntity} from "../../perfis/produtor/entitys/produtor.entity";
import {GenericHttpService} from "../../../../services/generic-http.service";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-usuario-insert-edit',
  templateUrl: './usuario-insert-edit.component.html',
  styleUrls: ['./usuario-insert-edit.component.scss']
})
export class UsuarioInsertEditComponent extends AbstractInsertEdit <UsuariosEntity> implements OnInit {

  public form = new FormGroup({
    id: new FormControl(''),
    username: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    telefone: new FormControl(''),
    cpf: new FormControl(''),
    perfil: new FormControl(''),
    password: new FormControl('', [Validators.required, Validators.minLength(6)]),
    confirmPassword: new FormControl('', [Validators.required, Validators.minLength(6)]),
    produtor: new FormControl(''),
  });

  public error: boolean = false
  public mostrarSenha = true
  public mostrarConfirmSenha = true
  listOperadores: ProdutorEntity[] = [];
  public showLoader = false;

  constructor(
    public config: InsertEditConfig,
    public service: GenericHttpService,
    public session: SessionService,
    private router: Router
  ) {
    super(config, { path: environment.apiUrl, context: 'users' })
    this.service.get('produtor', { params: { unpaged: true } }).subscribe((resp: any) => {
      this.listOperadores = resp.data;

    })
  }

  override afterSaveEntity(){
    this.showLoader = false;
  }

  override ngOnInit(): void {
    super.ngOnInit();
    //console.log(this.entity.perfil)
  }

  override afterFetchEntity() {
    console.log(this.entity)
    // @ts-ignore
    this.form.patchValue({
      ...this.entity,
      // @ts-ignore
      produtor: this.entity.produtor?.id || ''
    })

    if(this.entity.id){
      this.form.controls.password.disable();
      this.form.controls.confirmPassword.disable();
    }
  }

  initNewEntity(): void {
    this.entity = new UsuariosEntity()
  }

  override beforeSaveEntity(): boolean {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return false;
    }

    if(this.session.checkPerfil('ROLE_PRODUTOR')) this.form.patchValue({perfil: 'ROLE_PRODUTOR'});

    const form = <UsuariosEntity>this.form.value;

    this.entity = {
      ...this.entity,
      ...form
    }
    this.showLoader = true;

      return true
  }



  saveOrUpdate() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    if(this.session.checkPerfil('ROLE_PRODUTOR')) this.entity.perfil = 'ROLE_PRODUTOR';

    const form = this.form.value;

    // @ts-ignore
    // form.produtor = { id: form.produtor }

    //@ts-ignore
    this.entity = {
      ...this.entity,
      ...form
    }
    this.showLoader = true;

    this.service[this.entity.id ? 'put' :'post']('users', this.entity).subscribe(
      (r:any)=> {
        Swal.fire({
        icon: 'success',
        title: 'Usuario criado com sucesso!'
      })
      this.showLoader = false;
      this.voltar()
    },
    (e) => {
      console.log(e)
      Swal.fire({
        icon: 'error',
        title: 'Não foi possivel criar o usuario!'
      })
      this.showLoader = false;
    }
    )
  }

  passwordMatchValidator(event: any){
    const password = this.form.value.password;
    const confirmPassword = event.target.value;
    console.log(event.target.value)
    if(password != '' && confirmPassword != ''){
      if(password !== confirmPassword){
        this.error = true
      }else{
        this.error = false
      }
    };
  }

  mostrarPassword(){
    const input = document.querySelector('.password')
    if(input?.getAttribute('type')== 'password'){
      input.setAttribute('type', 'text')
      this.mostrarSenha = false
    }else{
      input?.setAttribute('type', 'password')
      this.mostrarSenha = true
    }
  }

  mostrarConfirmPassword() {
    const input = document.querySelector('.confirmPassword')
    if(input?.getAttribute('type')== 'password'){
      input.setAttribute('type', 'text')
      this.mostrarConfirmSenha = false
    }else{
      input?.setAttribute('type', 'password')
      this.mostrarConfirmSenha
    }
  }

  override voltar(){
    this.router.navigate(['user','usuarios']).then()
  }

  override afterErrorSave(){
    this.showLoader = false
  }

}
