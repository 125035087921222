<div class="container-fluid">
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 d-flex justify-content-end" *ngIf="session.checkPerfil('ROLE_ADMINISTRADOR')">
      <button routerLink="novo" class="btn btn-principal ff-gotham-bold d-inline-flex justify-content-center align-items-center">
        <mat-icon style="margin-right: 1rem;">person_add</mat-icon>
        Cadastrar Produtor
      </button>
    </div>
  </div>
  <div class="row pt-3">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
      <div class="card card-relatorio">
        <div class="card-header ff-gotham-bold header-relatorio">
          <h4 class="card-title">Registro de Produtores</h4>
        </div>
        <div class="card-header">
          <form [formGroup]="formFilters">
            <div class="row">
              <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                <label class="form-lable">Produtor</label>
                <input class="form-control" formControlName="produtorApelido" dg-dynamic-group="tableProdutor" paramName="produtorApelido">
              </div>
              <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                <label class="form-lable">Produtor Supervisor</label>
                <input class="form-control" formControlName="produtorSupervisorApelido" dg-dynamic-group="tableProdutor" paramName="produtorSupervisorApelido">
              </div>
            </div>
        </form>
        <div class="row mt-3">
          <div class="col-12 d-inline-flex justify-content-end">
            <button dynamic-group-control="tableProdutor" filter class="btn btn-principal ff-gotham-medium btn-sm m-1">Filtrar</button>
            <button dynamic-group-control="tableProdutor" clear class="btn btn-secondary btn-sm m-1">Limpar</button>
          </div>
        </div>
        </div>
        <div class="card-body tbl1">
            <dg-table  dg-dynamic-table [dynamicTable]="table" classes="crud-table tbl3" class="dg-table-stick tbl2">
            </dg-table>
        </div>
        <div class="card-footer justify-content-center d-inline-flex">
          <dg-paginator dg-dynamic-paginator [dynamicTable]="table"></dg-paginator>
      </div>
      </div>
    </div>
  </div>

</div>

  <dg-modal (afterClose)="afterClose()" size="md" #modal>
    <div dg-header>
      <h4 class="ff-gotham-medium">Dados do Produtor</h4>
    </div>
    <div modal-body>
      <form [formGroup]="form">
        <div class="row d-flex justify-content-center pb-2">
          <div class="col-xl-10 col-lg-10 col-md-12 col-sm-">
            <label for="nome" class="form-label ff-gotham-light">Nome / Razão Social<small style="color:red;">*</small></label>
            <input type="text" name="nome" formControlName="nome" class=" form-control input-principal ff-gotham-light">
            <div class="msg-error ff-gotham-medium" *ngIf="form.get('nome')?.errors?.['required']
            && form.get('nome')?.touched">
              *Campo obrigatório
            </div>
          </div>
        </div>
        <div class="row d-flex justify-content-center pb-2">
          <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12">
            <label for="apelido" class="form-label ff-gotham-light">Apelido / Nome Fantasia<small style="color:red;">*</small></label>
            <input type="text" name="apelido" formControlName="apelido" class=" form-control input-principal ff-gotham-light">
            <div class="msg-error ff-gotham-medium" *ngIf="form.get('apelido')?.errors?.['required']
            && form.get('apelido')?.touched">
              *Campo obrigatório
            </div>
          </div>
        </div>
        <div class="row d-flex justify-content-center pb-2">
          <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12">
            <label for="email" class="form-label ff-gotham-light">Email<small style="color:red;">*</small></label>
            <input type="text" name="email" formControlName="email" class=" form-control input-principal ff-gotham-light">
            <div class="msg-error ff-gotham-medium" *ngIf="form.get('email')?.errors?.['required']
            && form.get('email')?.touched">
              *Campo obrigatório
            </div>
          </div>
        </div>
        <div class="row d-flex justify-content-center pb-2">
          <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12">
            <label for="perfil" class="form-label ff-gotham-light">Produtor</label>
            <br>
            <input-autocomplete classes="form-control input-principal ff-gotham-light" formControlName="produtor" [list]="listOperadores" label="nome" key="id">
            </input-autocomplete>
            <div class="msg-error ff-gotham-medium" *ngIf="form.get('perfil')?.errors?.['required']
          && form.get('perfil')?.touched">
              *Campo obrigatório
            </div>
          </div>
        </div>
        <div class="row d-flex justify-content-center pb-2">
          <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12">
            <label class="form-label ff-gotham-light">Telefone<small style="color:red;">*</small></label>
            <input type="text" name="telefone" formControlName="telefone" class=" form-control input-principal ff-gotham-light" mask="(00) 0000-0000 ||(00) 00000-0000">
            <div class="msg-error ff-gotham-medium" *ngIf="form.get('telefone')?.errors?.['required']
            && form.get('telefone')?.touched">
              *Campo obrigatório
            </div>
          </div>
          <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12">
            <label class="form-label ff-gotham-light">CPF/CNPJ<small style="color:red;">*</small></label>
            <input type="text" name="cpf" formControlName="documento" class=" form-control input-principal ff-gotham-light"  mask="000.000.000-00 || 00.000.000/0000-00">
            <div class="msg-error ff-gotham-medium" *ngIf="form.get('documento')?.errors?.['required']
            && form.get('documento')?.touched">
              *Campo obrigatório
            </div>
          </div>
        </div>
      </form>
    </div>
    <div dg-footer>
      <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 d-flex flex-row justify-content-end">
          <button class="btn btn-secundario" style="margin-right: 2rem; width:50%">Cancelar</button>
          <button class="btn btn-principal" (click)="salvarProdutor()">Salvar</button>
        </div>
      </div>
    </div>
  </dg-modal>
