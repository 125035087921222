<div class="container-fluid pb-5">
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
      <div class="card card-meusSeguros">
        <div class="card-header ff-gotham-bold header-meusSeguros">
          <h4 class="card-title">Emissões Sol Agora</h4>
        </div>
        <div class="card-header">
          <form [formGroup]="formFilters">
            <div class="row">
              <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                <label class="form-lable">Cliente</label>
                <input class="form-control" formControlName="personName" dg-dynamic-group="tableCotacao" paramName="personName">
              </div>
              <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                <label class="form-lable">Produtor</label>
                <input class="form-control" formControlName="produtor" dg-dynamic-group="tableCotacao" paramName="produtor">
              </div>
              <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                <label class="form-lable">Nº Proposta</label>
                <input class="form-control" formControlName="proposalNo" dg-dynamic-group="tableCotacao" paramName="proposalNo">
              </div>
              <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                <label class="form-lable">Nº da Apólice</label>
                <input class="form-control" formControlName="certificate" dg-dynamic-group="tableCotacao" paramName="certificate">
              </div>
              <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                <label class="form-lable">Status</label>
                <select class="form-control" formControlName="status" dg-dynamic-group="tableCotacao" paramName="status">
                  <option value=""></option>
                  <option value="0">Em Cotação</option>
                  <option value="NotEffective">Não Efetivado</option>
                  <option value="Effective">Efetivado</option>

                </select>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                <label class="form-lable">Inicio de Vigência</label>
                <input class="form-control" type="date" formControlName="effectiveDate" dg-dynamic-group="tableCotacao" paramName="effectiveDate">
              </div>
              <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                <label class="form-lable">Fim de Vigência</label>
                <input class="form-control" type="date" formControlName="expiryDate" dg-dynamic-group="tableCotacao" paramName="expiryDate">
              </div>
              <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                <label class="form-lable">Data de criação</label>
                <div class="row">
                  <div class="col-6">
                    <input class="form-control" type="datetime-local" formControlName="issueDateStart" dg-dynamic-group="tableCotacao" paramName="issueDateStart">
                  </div>
                  <div class="col-6">
                    <input class="form-control" type="datetime-local" formControlName="issueDateEnd" dg-dynamic-group="tableCotacao" paramName="issueDateEnd">
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div class="row mt-3">
            <div class="col-12 d-inline-flex justify-content-end">
              <button dynamic-group-control="tableCotacao" filter class="btn btn-principal ff-gotham-medium btn-sm m-1">Filtrar</button>
              <button dynamic-group-control="tableCotacao" clear class="btn btn-secondary btn-sm m-1">Limpar</button>
            </div>
          </div>
        </div>
        <div class="card-body tbl1">
            <dg-table  dg-dynamic-table [dynamicTable]="table"
            class="tbl2"
            classes="tbl3 crud-table" >
            </dg-table>
          </div>
        <div class="card-footer justify-content-center d-inline-flex">
            <dg-paginator dg-dynamic-paginator [dynamicTable]="table"></dg-paginator>
        </div>
      </div>
    </div>
  </div>
</div>

<dg-modal size="lg" #modal>
  <div modal-header>
    <h4 class="ff-gotham-medium">Detalhes da emissão:</h4>
  </div>
  <div modal-body>
    <p class="ff-gotham-medium">Informações do seguro:</p>
    <div class="row">
      <div class="d-flex flex-row col-xl-6 col-lg-6 col-md-6 col-sm-12">
        <p class="ff-gotham-medium" style="padding-right: 0.4rem;">Valor em risco total:</p>
        <p class="ff-gotham-light">R$ {{ maskValores(entity.totalEquipmentAmount )  }} </p>
      </div>
      <div class="d-flex flex-row col-xl-6 col-lg-6 col-md-6 col-sm-6">
        <p class="ff-gotham-medium" style="padding-right: 0.4rem;">Vigência do seguro:</p>
        <p class="ff-gotham-light">{{ maskDate(entity.effectiveDate) }} - {{ maskDate(entity.expiryDate) }}</p>
      </div>
    </div>
    <div class="row">
      <div class="d-flex flex-row col-xl-6 col-lg-6 col-md-6 col-sm-12">
        <p class="ff-gotham-medium" style="padding-right: 0.4rem;">Prêmio líquido:</p>
        <p class="ff-gotham-light">R$ {{ maskValores(entity.netPremium) }} </p>
      </div>
      <div class="d-flex flex-row col-xl-6 col-lg-6 col-md-6 col-sm-6">
        <p class="ff-gotham-medium" style="padding-right: 0.4rem;">IOF:</p>
        <p class="ff-gotham-light">R$ {{ maskValores(entity.vat) }}</p>
      </div>
    </div>
    <div class="row">
      <div class="d-flex flex-row col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <p class="ff-gotham-medium" style="padding-right: 0.4rem;">Prêmio total:</p>
        <p class="ff-gotham-light">R$ {{ maskValores(entity.premium) }}</p>
      </div>

    </div>
    <br>
    <p class="ff-gotham-medium">Informações do segurado:</p>
    <div class="row">
      <div class="d-flex flex-row col-xl-6 col-lg-6 col-md-6 col-sm-12">
        <p class="ff-gotham-medium" style="padding-right: 0.4rem;">Nome:</p>
        <p class="ff-gotham-light">{{ entity.name || 'Não informado' }}</p>
      </div>
      <div class="d-flex flex-row col-xl-6 col-lg-6 col-md-6 col-sm-6">
        <p class="ff-gotham-medium" style="padding-right: 0.4rem;">Documento:</p>
        <p class="ff-gotham-light">{{ maskDocument(entity.document || 'Não informado') }}</p>
      </div>
    </div>
    <div class="row">
      <div class="d-flex flex-row col-xl-6 col-lg-6 col-md-6 col-sm-12">
        <p class="ff-gotham-medium" style="padding-right: 0.4rem;">Data de nascimento:</p>
        <p class="ff-gotham-light">{{ maskDate(entity.dateOfBirth || 'Não informado')  }}</p>
      </div>
      <div class="d-flex flex-row col-xl-6 col-lg-6 col-md-6 col-sm-6">
        <p class="ff-gotham-medium" style="padding-right: 0.4rem;">E-mail:</p>
        <p class="ff-gotham-light">{{ entity.email || 'Não informado' }}</p>
      </div>
    </div>
    <div class="row">
      <div class="d-flex flex-row col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <p class="ff-gotham-medium" style="padding-right: 0.4rem;">Telefone:</p>
        <p class="ff-gotham-light">{{ maskMobile(entity.mobile || 'Não informado') }}</p>
      </div>
    </div>
    <br>
    <p class="ff-gotham-medium">Endereço do segurado:</p>
    <div class="row">
      <div class="d-flex flex-row col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <p class="ff-gotham-medium" style="padding-right: 0.4rem;">CEP:</p>
        <p class="ff-gotham-light">{{ entity.cep || 'Não informado' }}</p>
      </div>
    </div>
    <div class="row">
      <div class="d-flex flex-row col-xl-6 col-lg-6 col-md-6 col-sm-12">
        <p class="ff-gotham-medium" style="padding-right: 0.4rem;">Rua:</p>
        <p class="ff-gotham-light">{{ entity.street || 'Não informado' }}</p>
      </div>
      <div class="d-flex flex-row col-xl-6 col-lg-6 col-md-6 col-sm-6">
        <p class="ff-gotham-medium" style="padding-right: 0.4rem;">Número:</p>
        <p class="ff-gotham-light">{{ entity.number || 'Não informado' }}</p>
      </div>
    </div>
    <div class="row">
      <div class="d-flex flex-row col-xl-6 col-lg-6 col-md-6 col-sm-12">
        <p class="ff-gotham-medium" style="padding-right: 0.4rem;">Complemento:</p>
        <p class="ff-gotham-light">{{ entity.complement || 'Não informado' }}</p>
      </div>
      <div class="d-flex flex-row col-xl-6 col-lg-6 col-md-6 col-sm-6">
        <p class="ff-gotham-medium" style="padding-right: 0.4rem;">UF:</p>
        <p class="ff-gotham-light">{{ entity.state || 'Não informado' }}</p>
      </div>
    </div>
    <div class="row">
      <div class="d-flex flex-row col-xl-6 col-lg-6 col-md-6 col-sm-12">
        <p class="ff-gotham-medium" style="padding-right: 0.4rem;">Cidade:</p>
        <p class="ff-gotham-light">{{ entity.city || 'Não informado' }}</p>
      </div>
      <div class="d-flex flex-row col-xl-6 col-lg-6 col-md-6 col-sm-6">
        <p class="ff-gotham-medium" style="padding-right: 0.4rem;">Bairro:</p>
        <p class="ff-gotham-light">{{ entity.suburb || 'Não informado' }}</p>
      </div>
    </div>
    <hr>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 d-flex flex-column align-items-start justify-content-center">
      <p class="ff-gotham-bold">Boletos:</p>
      <a class="ff-gotham-medium btn d-flex justify-content-center linkBoleto" style="color: #DEA32C;" target="_blank" [href]="installment.billetUrl" *ngFor="let installment of this.entity.payment?.installments; let i = index"><mat-icon>receipt_long</mat-icon> Baixar Boleto ({{i+ 1}}ª Parcela)</a>
    </div>
  </div>
  <div modal-footer></div>
</dg-modal>

<div class="loader" *ngIf="showLoader">
  <div class="content-loader">
    <mat-spinner></mat-spinner>
  </div>
</div>
