import { TokenService } from './../../service/token/token.service';
import { SessionService } from './../../service/session/session.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SessionGuard implements CanActivate {

  constructor(
    private session: SessionService,
    private token: TokenService,
    private router: Router
  ){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      if(!this.token.issetToken()){
        this.session.logout();
      }

      if(!this.token.checkExp()){
        this.session.logout();
      }

      if(!this.session.user?.name){
        this.session.setUser()
      }

    return true;
  }

}
