import {AddressEntity} from "./address.entity";


export class PersonEntity {
  constructor(data: {
    name?: string,
    document?: string,
    mobile?: string,
    email?: string,
    dateOfBirth?: string,
    address?: AddressEntity,
    billingAddress?: AddressEntity,
    isPep?: boolean,
    type?: 'PF' | 'PJ',
    documentType?: 'CPF' | 'CNPJ'
  }) {
    this.name = data.name;
    this.document = data.document;
    this.mobile = data.mobile;
    this.email = data.email;
    this.address = data?.address;
    this.billingAddress = data?.billingAddress;
    this.isPep = data?.isPep;
    this.type = data?.type ? data.type : 'PF'
    this.documentType = data?.documentType ? data.documentType : 'CPF'
    this.dateOfBirth = data?.dateOfBirth;
  }

  name: string | undefined
  document: string | undefined
  mobile: string | undefined
  email: string | undefined
  address: AddressEntity | undefined;
  billingAddress: AddressEntity | undefined;
  isPep: boolean | undefined;
  type: 'PF' | 'PJ';
  documentType: 'CPF' | 'CNPJ';
  dateOfBirth: string | undefined
}
