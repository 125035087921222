import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { DgCrudModule } from '@datagrupo/dg-crud';
import { DgModalModule, DgPaginatorModule } from '@datagrupo/dg-ng-util';
import { NgxMaskModule } from 'ngx-mask';
import { AppMaterialModule } from './../../shared/app-material/app-material.module';
import { ContactSuccessComponent } from './contact-user/contact-success/contact-success.component';
import { ContactUserComponent } from './contact-user/contact-user.component';
import { CotacaoReModule } from './cotacao-re/cotacao-re.module';
import { CotacaoModule } from "./cotacao/cotacao.module";
import { CotacoesGeradasModule } from './cotacoes-geradas/cotacoes-geradas.module';
import { HomeUserComponent } from './home-user/home-user.component';
import { ProdutorModule } from './perfis/produtor/produtor.module';
import { ProfileUserComponent } from './profile-user/profile-user.component';
import { SolAgoraModule } from './sol-agora/sol-agora.module';
import { UserRoutingModule } from './user-routing.module';
import { UsuariosModule } from './usuarios/usuarios.module';


@NgModule({
  declarations: [
    HomeUserComponent,
    ProfileUserComponent,
    ContactUserComponent,
    ContactSuccessComponent
  ],
  imports: [
    CommonModule,
    UserRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AppMaterialModule,
    NgxMaskModule.forChild(),
    CotacoesGeradasModule,
    ProdutorModule,
    MatProgressSpinnerModule,
    DgModalModule,
    DgCrudModule,
    DgPaginatorModule,
    CotacaoModule,
    UsuariosModule,
    CotacaoReModule,
    SolAgoraModule
  ]
})
export class UserModule {
}
