import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from "@angular/router";
import { HttpClient, crudDispatchEvent } from '@datagrupo/dg-crud';
import { CdkDynamicTable, CdkDynamicTableService, DgModalComponent } from '@datagrupo/dg-ng-util';
import { SessionService } from 'src/app/core/service/session/session.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { GenericHttpService } from "../../../../services/generic-http.service";
import { ProdutorEntity } from "../../perfis/produtor/entitys/produtor.entity";
import { UsuariosEntity } from './../usuarios.entity';

@Component({
  selector: 'app-usuarios-main',
  templateUrl: './usuarios-main.component.html',
  styleUrls: ['./usuarios-main.component.scss']
})
export class UsuariosMainComponent implements OnInit, OnDestroy {

  @ViewChild('modal') modal!: DgModalComponent

  public error: boolean = false
  public table: CdkDynamicTable.tableClass
  public tableMap = new UsuariosEntity()

  public mostrarSenha = true
  public mostrarConfirmSenha = true
  public formFilters = new FormGroup({
    username: new FormControl(''),
    produtorApelido: new FormControl('')
  })

  public form = new FormGroup({
    id: new FormControl(''),
    username: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    telefone: new FormControl(''),
    cpf: new FormControl(''),
    perfil: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required, Validators.minLength(6)]),
    confirmPassword: new FormControl('', [Validators.required, Validators.minLength(6)]),
    produtor: new FormControl(''),
  });

  listOperadores: ProdutorEntity[] = [];

  constructor(
    private http: HttpClient,
    private service: GenericHttpService,
    private router: Router,
    public session: SessionService,
    private createTable: CdkDynamicTableService,
  ) {

    this.table = createTable.createByEntity(new UsuariosEntity(), {
      pipePagination: (p: any) => {
        console.log('pipePagination', p.page)
        return {
          page: p?.page?.number || 0,
          totalPages: p?.page?.totalPages || 0,
          totalElements: p?.page?.totalElements || 0
        };
      },
      filters: {filters: {},
      group: "tableUsuarios", reactive: false},
      actions: {
        add: {
          edit: {
            name: 'Editar',
            action: (row: UsuariosEntity) => {this.router.navigate(['user','usuarios', row.id])},
            permission: !!session.checkPerfil('ROLE_ADMINISTRADOR') ? true : false,
          },
          remove: {
            name: 'Excluir',
            action: (row: UsuariosEntity) => this.delete(row),
            permission: !!session.checkPerfil('ROLE_ADMINISTRADOR') ? true : false,
          }
        }
      }
    })

  }

  ngOnInit(): void {
  }

  dictionary(data: any[]) {
    return data;
  }

  ngOnDestroy(): void {
    this.table.destroy()
  }

  delete(row: UsuariosEntity){
    Swal.fire({
      icon: 'question',
      title: 'Excluir Usuário',
      text: 'Deseja excluir esse usuário? Essa ação será permanente',
      showCancelButton: true,
      cancelButtonColor: 'red',
      showConfirmButton: true,
      confirmButtonColor: 'blue'
    }).then((opt => {
      if(opt.isConfirmed){
        this.http.delete(environment.apiUrl + `users/${row.id}`).subscribe(
          (resp)=> {
            Swal.fire({
              icon: 'success',
              title: 'Usuário excluído com sucesso!'
            })
            crudDispatchEvent('listaUsuarios')
        }, ()=> {
          Swal.fire({
            icon: 'error',
            title: 'Erro ao excluir usuário.'
          })
        })
      }
    }))


  }

  mostrarPassword(){
      const input = document.querySelector('.password')
      if(input?.getAttribute('type')== 'password'){
        input.setAttribute('type', 'text')
        this.mostrarSenha = false
      }else{
        input?.setAttribute('type', 'password')
        this.mostrarSenha = true
      }
  }

  mostrarConfirmPassword(){
    const input = document.querySelector('.confirmPassword')
    if(input?.getAttribute('type')== 'password'){
      input.setAttribute('type', 'text')
      this.mostrarConfirmSenha = false
    }else{
      input?.setAttribute('type', 'password')
      this.mostrarConfirmSenha
    }
  }

  passwordMatchValidator(event: any){
    const password = this.form.value.password;
    const confirmPassword = event.target.value;
    console.log(event.target.value)
    if(password != '' && confirmPassword != ''){
      if(password !== confirmPassword){
        this.error = true
      }else{
        this.error = false
      }
    };
  }

  criarUsuario(){
    if(this.form.invalid){
      this.form.markAllAsTouched()
      return
    }

    const {confirmPassword, ...form} = this.form.value
    const context = form?.id ? 'users/' + form.id : 'users'

    this.http[form?.id ? 'put' : 'post'](environment.apiUrl + context, form).subscribe((r:any)=> {
      crudDispatchEvent('listaUsuarios')
      this.modal.close()
    })

    return
  }

  afterClose(){
    this.form.reset()
  }

  modalOpen() {
    this.service.get('produtor', { params: { unpaged: true } }).subscribe((resp: any) => {
      this.listOperadores = resp.data;
      this.modal.open()
    })
  }
}
