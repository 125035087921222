import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PublicLayoutComponent} from "./public-layout/public-layout.component";
import {RouterModule} from "@angular/router";
import {HeaderComponent} from "./sub-components/header/header.component";
import {MatIconModule} from "@angular/material/icon";



@NgModule({
  declarations: [PublicLayoutComponent, HeaderComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule
  ],
  exports: [PublicLayoutComponent]
})
export class PublicLayoutModule { }
