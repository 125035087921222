import { perfil } from './../../../interfaces/perfil';
import { AbstractEntity, DataServer, DgTableColumn } from "@datagrupo/dg-crud";
import { environment } from "src/environments/environment";
import { ProdutorEntity } from '../perfis/produtor/entitys/produtor.entity';
import { DynamicColumn, DynamicTableEntity } from '@datagrupo/dg-ng-util';

@DataServer({
  path: environment.apiUrl,
  context: 'users'
})

@DynamicTableEntity({
  api: {
    path: environment.apiUrl,
    context: 'users'
  }
})

export class UsuariosEntity extends AbstractEntity{

  constructor(
    id?: number | string,
    username?: string | undefined,
    email?: string | undefined,
    cpf?: string | undefined,
    telefone?: string | undefined,
    produtorId?: number | string,
    perfil?: string | undefined
  ){
    super()
    this.username = username;
    this.email = email;
    this.cpf = cpf;
    this.telefone = telefone;
    this.produtorId = produtorId
    this.perfil = perfil
  }

  @DynamicColumn({headerName: 'Nome'})
  public username: string | undefined
  @DynamicColumn({headerName: 'Email'})
  public email: string | undefined
  @DynamicColumn({headerName: 'CPF', resource: (val:any, row: UsuariosEntity)=> {
    if(!val) return '--';

    val = String(val).replace(/\D/g, "")
    if(val.length == 11) return val.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/,"$1.$2.$3-$4")

  }})
  public cpf: string | undefined
  @DynamicColumn({headerName: 'Telefone', resource: (val: string, row: UsuariosEntity) => {
    if(!row?.telefone) return '--'

    val = String(row?.telefone).replace(/\D/g, "")
    if(val.length == 11) return val.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/,"($1) $2 $3-$4")

    return val.replace(/(\d{2})(\d{4})(\d{4})/,"($1) $2-$3")

  }})
  public telefone: string | undefined
  @DynamicColumn({headerName: 'Produtor', resource: (cel: string, row: any) => {
    return row.produtorApelido || row.produtorNome || 'sem vínculo'
  }})
  public produtorId: number | string | undefined

  public nome: string | undefined
  public apelido: string | undefined
  public perfil: string | undefined
}
