import { Component, OnInit } from '@angular/core';
import {AbstractInsertEdit, InsertEditConfig} from "@datagrupo/dg-crud";
import {ProdutorEntity} from "../entitys/produtor.entity";
import {environment} from "../../../../../../environments/environment";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {GenericHttpService} from "../../../../../services/generic-http.service";
import { Router } from '@angular/router';
import { DadosBancariosEntity } from '../entitys/dados-bancarios.entity';
import { SessionService } from 'src/app/core/service/session/session.service';

@Component({
  selector: 'app-produtor-insert-edit',
  templateUrl: './produtor-insert-edit.component.html',
  styleUrls: ['./produtor-insert-edit.component.scss']
})
export class ProdutorInsertEditComponent extends AbstractInsertEdit<ProdutorEntity> implements OnInit {

  public form = new FormGroup({
    id: new FormControl(''),
    nome: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    telefone: new FormControl(''),
    documento: new FormControl('', [Validators.required]),
    apelido: new FormControl(''),
    produtorPaiId: new FormControl('')
  })
  public formBanco = new FormGroup({
    banco: new FormControl(''),
    numOperacao: new FormControl(''),
    conta: new FormControl('selecione'),
    agencia: new FormControl(''),
    numConta: new FormControl(''),
    pix: new FormControl(''),
  })

  listOperadores: ProdutorEntity[] = [];

  constructor(
    public config: InsertEditConfig,
    public service: GenericHttpService,
    private router: Router,
    public session: SessionService
    ) {
    super(config, { path: environment.apiUrl, context: 'produtor' })
    this.service.get('produtor', { params: { unpaged: true } }).subscribe((resp: any) => {
      this.listOperadores = resp.data;
    })
  }

  override ngOnInit(): void {
    super.ngOnInit()
  }

  override afterFetchEntity() {
    //@ts-ignore
    this.form.patchValue({...this.entity})
    this.formBanco.patchValue({...this.entity?.bankInfo})
  }

  initNewEntity(): void {
    this.entity = new ProdutorEntity()
  }

  override beforeSaveEntity(): boolean {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return false
    }

    const form = this.form.value;
    const formBanco = this.formBanco.value

    //@ts-ignore
    this.entity = {
      ...this.entity,
      ...form,
      bankInfo: <DadosBancariosEntity> {
        ...(this.entity?.bankInfo || {}),
        ...formBanco
      }

    }

    return true;
  }

  override voltar(){
    this.router.navigate(['user','produtor']).then()
  }

}
