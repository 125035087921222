<div class="container-fluid payment">
<form [formGroup]="formStep3">
  <div class="row">
    <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12" *ngIf="entity?.status == 'NotEffective'">
      <div class="card cardCredito">
        <div class="card-header headerCredito">
          <p class="card-subtitle ff-gotham-medium">Pagamento por Cartão de Crédito</p>
        </div>
        <div class="card-body bodyCredito">
          <div class="row pb-2">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <label class="form-label ff-gotham-light">Titular do Cartão</label>
              <input name="titularCartao" formControlName="name" type="text" class="form-control input-principal ff-gotham-light">
              <div
                class="msg-error"
                *ngIf="formStep3.get('titularCartao')?.errors?.['required']&& formStep3.get('titularCartao')?.touched">
                Esse campo é obrigatório!
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <label for="cpfTitular" class="form-label ff-gotham-light">CPF do Titular</label>
              <input name="cpfTitular" formControlName="identity" id="cpfTitular" type="text" class="form-control input-principal ff-gotham-light" mask="000.000.000-00">
              <div
                class="msg-error"
                *ngIf="formStep3.get('cpfTitular')?.errors?.['required']
                                  && formStep3.get('cpfTitular')?.touched">
                Esse campo é obrigatório!
              </div>
            </div>
          </div>
          <div class="row pb-2">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <label for="numCartao" class="form-label ff-gotham-light">Número do Cartão</label>
              <input name="numCartao" formControlName="number" id="numCartao" type="text" class="form-control input-principal ff-gotham-light" mask="0000 0000 0000 0000">
              <div
                class="msg-error"
                *ngIf="formStep3.get('numCartao')?.errors?.['required']
                                  && formStep3.get('numCartao')?.touched">
                Esse campo é obrigatório!
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <label for="validadeCartao" class="form-label ff-gotham-light">Data de Validade do Cartão</label>
              <input name="validadeCartao" formControlName="expiration" id="validadeCartao" type="text" class="form-control input-principal ff-gotham-light" mask="00/00">
              <div
                class="msg-error"
                *ngIf="formStep3.get('validadeCartao')?.errors?.['required']
                                  && formStep3.get('validadeCartao')?.touched">
                Esse campo é obrigatório!
              </div>
            </div>
          </div>
          <div class="row pb-2">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <label for="cvv" class="form-label ff-gotham-light">Código de Segurança do Cartão (CVV)</label>
              <input name="cvv" formControlName="cvv" id="cvv" type="text" class="form-control input-principal ff-gotham-light" mask="000">
              <div
                class="msg-error"
                *ngIf="formStep3.get('cvv')?.errors?.['required']
                                  && formStep3.get('cvv')?.touched">
                Esse campo é obrigatório!
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex flex-column">
              <label for="numParcelasCartao" class="form-label ff-gotham-light">Número de parcelas</label>
              <select name="numParcelasCartao" formControlName="installment" id="numParcelasCartao" type="text" class="custom-select input-principal ff-gotham-light p-2 mb-2">
                <option [value]="i+1" *ngFor="let parcela of parcelas; let i = index">{{i + 1}}x de R${{maskValores(parcela)}} sem juros</option>
              </select>
              <div
                class="msg-error"
                *ngIf="formStep3.get('numParcelasCartao')?.errors?.['required']
                                  && formStep3.get('numParcelasCartao')?.touched">
                Esse campo é obrigatório!
              </div>
              <small class="ff-gotham-light">*Limite mínimo de R$110,00 por parcela</small>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 d-inline-flex justify-content-end">
          <button class="btn btn-principal ff-gotham-bold mt-3" (click)="save()">
            Realizar pagamento
          </button>
        </div>
      </div>
    </div>
    <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12" *ngIf="entity?.status == 'Effective'">
      <div class="card card-success mb-3">
          <p class="ff-gotham-bold" style="font-size:24px;">Sua contratação foi realizada com sucesso!</p>
          <p class="ff-gotham-light" style="font-size:18px;">Em breve o segurado receberá a sua apólice.</p>
              <div class="row">
                <div class="col-xl-1 col-lg-1 col-md-1 col-sm-3 d-inline-flex justify-content-center align-items-center">
                  <img src="../../../../../assets/images/apolice.png" style="width: 80%;">
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 d-flex flex-column justify-content-center pt-2">
                  <p class="ff-gotham-medium">Número da Apólice</p>
                  <p class="ff-gotham-light">{{entity?.certificate || '--'}}</p>
                </div>
              </div>
      </div>
    </div>

    <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
      <div class="card" style="border-left-color: #F5CF7A">
        <div class="card-body">
          <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
              <p class="ff-gotham-light">Nº da Cotação:</p>
            </div>
            <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12" style="text-align: end;">
              <p class="ff-gotham-medium"> {{ entity?.proposalNo }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col xl-5 col-lg-5 col-md-12 col-sm-12">
              <p class="ff-gotham-light">Vigência do Seguro:</p>
            </div>
            <div class="col xl-7 col-lg-7 col-md-12 col-sm-12" style="text-align: end;">
              <p class="ff-gotham-medium">
                {{ service.ajustDateView(entity?.effectiveDate) }} - {{ service.ajustDateView(entity?.expiryDate) }}
              </p>
            </div>
          </div>
          <div class="row" >
            <div class="col xl-6 col-lg-6 col-md-6 col-sm-12">
              <p class="ff-gotham-light">Valor em Risco:</p>
            </div>
            <div class="col xl-6 col-lg-6 col-md-6 col-sm-12" style="text-align: end;">
              <p class="ff-gotham-medium"> R$ {{ entity?.totalEquipmentAmount }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="card mt-4">
        <div class="card-body">
          <div class="row">
            <div class="col xl-6 col-lg-6 col-md-6 col-sm-12">
              <p class="ff-gotham-bold">Prêmio Líquido:</p>
            </div>
            <div class="col xl-6 col-lg-6 col-md-6 col-sm-12" style="text-align: end;">
              <p class="ff-gotham-medium"> R${{ entity?.netPremium }}</p>
            </div>
          </div>
          <hr style="border-style:dotted">
          <div class="row">
            <div class="col xl-10 col-lg-10 col-md-10 col-sm-12">
              <p class="ff-gotham-bold">Coberturas: </p>
              <div class="coberturas" *ngFor="let item of getCoverages()">
                <p class="ff-gotham-medium"> {{ item.coverageName }}</p>
              </div>
            </div>
            <!-- <div class="col xl-4 col-lg-4 col-md-4 col-sm-12 d-flex flex-column align-items-end">
              <p class="ff-gotham-bold">Valor(R$): </p>
              <div class="coberturas" *ngFor="let item of list">
                <p class="ff-gotham-medium pb-1"> {{item.duePremium}}</p>
                 <br>
              </div>
            </div>-->
          </div>
          <hr style="border-style:dotted">
          <div class="row">
            <div class="col xl-12 col-lg-12 col-md-12 col-sm-12">
              <p class="ff-gotham-bold">Taxas e Impostos:</p>
            </div>
            <div class="col xl-6 col-lg-6 col-md-6 col-sm-12">
              <p class="ff-gotham-light"> IOF</p>
            </div>
            <div class="col xl-6 col-lg-6 col-md-6 col-sm-12" style="text-align: end;">
              <p class="ff-gotham-medium"> R$ {{ entity?.vat }}</p>
            </div>
          </div>
          <hr>
          <div class="row">
            <div class="col xl-6 col-lg-6 col-md-6 col-sm-12">
              <p class="ff-gotham-bold">Prêmio Total:</p>
            </div>
            <div class="col xl-6 col-lg-6 col-md-6 col-sm-12" style="text-align: end;">
              <p class="ff-gotham-medium"> R${{ entity?.premium }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

</div>


<div class="loader" *ngIf="showLoader">
  <div class="content-loader">
    <mat-spinner></mat-spinner>
  </div>
</div>
