<div class="container-fluid login p-5">
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col sm-12 d-flex justify-content-center">
      <div class="card col-xl-5 col-lg-5 col-md-10 col-sm-12" style="box-shadow: 3px 3px 3px  3px #cdcdcd;">
        <div class="card-header header d-flex justify-content-center">
          <h4 class="ff-gotham-bold title">
            Olá! Seja Bem-Vindo(a)!
          </h4>
        </div>
        <div class="card-body body-formulario d-flex justify-content-center flex-column">
          <div class="row">
            <p class="card-text text-formulario ff-gotham-light">Faça o seu login:</p>
          </div>
          <br>
          <div class="row">
            <form [formGroup]="formLogin">
              <div class="row d-flex justify-content-center pb-2">
                <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12">
                  <label for="email" class="form-label ff-gotham-light">E-mail</label>
                  <input class="form-control input-principal " formControlName="email" type="email" id="email" name="email" style="width: 93%;">
                  <div class="msg-error"
                  *ngIf="formLogin.get('email')?.hasError('email')
                  && formLogin.get('email')?.touched" class="error-msg">
                    Por Favor informe um e-mail válido
                  </div>
                  <div class="msg-error"
                  *ngIf="formLogin.get('email')?.hasError('required')
                  && formLogin.get('email')?.touched" class="error-msg">
                    Email é obrigatório
                  </div>
                </div>
              </div>
              <div class="row d-flex justify-content-center pb-2">
                <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12">
                  <label for="password" class="form-label ff-gotham-light">Senha</label>
                  <div class="input-password">
                    <input class="form-control password input-principal" formControlName="password" type="password" id="password" name="password" style="width: 95%;">
                    <span class="password-eye" (click)="mostrarPassword()">
                      <mat-icon *ngIf="mostrarSenha == true">visibility</mat-icon>
                      <mat-icon *ngIf="mostrarSenha == false">visibility_off</mat-icon>
                    </span>
                  </div>
                  <div class="msg-error"
                  *ngIf="formLogin.get('password')?.hasError('required')
                  && formLogin.get('password')?.touched" class="error-msg">
                    Senha é obrigatória
                  </div>
                </div>
              </div>
              <div class="row d-flex justify-content-center">
                <div class="col-xl-10 col-lg-10 col-md-10 col-sm-12">
                  <a class="ff-gotham-bold" (click)="modal.open()">
                    Esqueceu sua senha?
                  </a>
                </div>
              </div>
              <div class="row d-flex justify-content-center pb-2 pt-5">
                <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12">
                      <button (click)="login()" class="btn btn-principal ff-gotham-bold" style="width:100%">
                        Acessar conta
                      </button>
                </div>
              </div>
              <div class="row d-flex justify-content-center pb-2">
                <p class="ff-gotham-light text-button" >
                  Ainda não possui uma conta?
                </p>
              </div>
              <div class="row d-flex justify-content-center pb-2">
                <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12">
                  <a href="https://ombrelloseguros.com.br/ombrello-solar/#integrador" target="_blank" class="btn btn-principal ff-gotham-bold link-cadastro">
                  Cadastre-se
                </a>
              </div>
            </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<dg-modal size="sm" #modal>
  <div modal-header>
    <h4 class="ff-gotham-medium">Esqueci minha Senha</h4>
  </div>
  <div modal-body>
    <div class="row d-flex justify-content-center pb-4">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <small class="ff-gotham-light">Informe o e-mail associado à sua conta.</small>
        <br>
        <small class="ff-gotham-light">Enviaremos um e-mail com instruções para redefinir sua senha.</small>
      </div>
    </div>
    <form [formGroup]="formForgotPass">
      <div class="row d-flex justify-content-center pb-2">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <label for="email" class="form-label ff-gotham-light">E-mail:</label>
          <input type="text" name="email" formControlName="email" class="form-control input-principal ff-gotham-light">
        </div>
      </div>
    </form>
  </div>
  <div modal-footer>
    <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 d-flex flex-row justify-content-end">
        <button class="btn btn-secundario" (click)="voltar()" style="margin-right: 2rem;">Cancelar</button>
        <button class="btn btn-principal" (click)="forgotpassword()">Enviar</button>
      </div>
    </div>
  </div>
</dg-modal>

<div class="loader" *ngIf="showLoader">
  <div class="content-loader">
    <mat-spinner></mat-spinner>
  </div>
</div>
