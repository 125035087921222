import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { TokenService } from '../../../core/service/token/token.service';
import { environment } from './../../../../environments/environment';
import { SessionService } from './../../../core/service/session/session.service';

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DgModalComponent } from '@datagrupo/dg-ng-util';
import Swal from 'sweetalert2';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  @ViewChild('modal') modal!: DgModalComponent

  public formLogin = new FormGroup ({
    email: new FormControl('', [
      Validators.required,
      Validators.email
    ]),
    password: new FormControl('', [ Validators.required])
  })

  public formForgotPass = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.email
    ])
  })

  public mostrarSenha = true
  public showLoader = false;

  constructor(
    private http: HttpClient,
    private router: Router,
    private token: TokenService,
    private session: SessionService
    ) {}

  ngOnInit(): void {
  }

  login() {

    if(this.formLogin.valid){
      const form = this.formLogin.value;

      this.showLoader = true;

      this.http.post<{data: {accessToken:string, refreshToken:string, temporaryLogin:boolean}}>(environment.apiUrl + 'auth/signin', form).subscribe(
        resp => {
          this.token.saveToken(resp.data.accessToken, resp.data.refreshToken)
          window.sessionStorage.setItem('temporaryLogin', resp.data?.temporaryLogin ? '1' : '0')
          window.location.href = 'auth/start-session'

        },
        () => {
          this.showLoader = false;
          Swal.fire({
            icon: 'error',
            title: 'Erro ao fazer login! Verifique se email e senha estão corretas.'
          })
        })
    }
      }

  forgotpassword(){
    const form = this.formForgotPass.value

    if(this.formForgotPass.invalid){
      this.formForgotPass.markAllAsTouched()
      return
    }

    this.showLoader = true;
    this.modal.close()

    this.http.post(environment.apiUrl + `auth/forgotpassword?email=${form.email}`, {}).subscribe(
      resp => {
        this.showLoader = false;
        Swal.fire({
          icon: 'success',
          title: 'Email enviado com sucesso!',
          text: 'Caso ainda não tenha recebido, verifique na caixa de span.'
        })
      },
      () => {
        this.showLoader = false;
        Swal.fire({
          icon: 'error',
          title: 'Erro ao enviar o e-mail.',
          text: ' Verifique se o e-mail informado é o mesmo cadastrado no sistema.'
        })
        this.modal.open();
      }
    )

  }

  mostrarPassword(){
    const input = document.querySelector('.password')
    if(input?.getAttribute('type')== 'password'){
      input.setAttribute('type', 'text')
      this.mostrarSenha = false
    }else{
      input?.setAttribute('type', 'password')
      this.mostrarSenha = true
    }
  }

  voltar(){
    this.modal.close()
  }

}
