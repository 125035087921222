<div class="container">
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 ">
      <div class="cardPrincipal">
        <div class="card cardRE col-11 m-3" style="border-radius: 15px;">
          <div class="row d-flex">
            <div class="col-2">
              <img src="../../../../../assets/images/logo.png" alt="Logo Ombrello Solar" style="width: 100%; padding-top: 1rem;">
            </div>
            <div class="col-8">
              <h4 class="card-header ff-gotham-bold headerRE">Proposta de Seguro - Seguro Risco Engenharia</h4>
              <h5 class="card-title ff-gotham-medium subtitleRE">Instalações Fotovoltaicas</h5>
              <p class="card-text ff-gotham-light titleRE">
                Processo SUSEP: 15414.9001782017-14, 15414.9013852013-62,  15414.000736/2012-35
              </p>
              <p class="card-text ff-gotham-light titleRE">
                AKAD SEGUROS S.A. - CNPJ 14.868.712/0001-31
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<hr>

<div class="container-fluid">
  <div class="row">
    <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12">
      <mat-stepper linear #stepper class="p-3 mb-3 rounded">
        <mat-step label="Informações do Seguro" [completed]="(formStep0.valid && formStep0.value.addInformation == true)">
          <div class="container">
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <form [formGroup]="formStep0">
                  <div class="card cardCotacao pt-3 pb-3  mb-5">
                    <div class="card-body bodyCotacao">
                      <div class="row d-flex flex-row pb-4">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                          <h6 class="title-items ff-gotham-bold">Informações do Seguro:</h6>
                        </div>
                      </div>
                      <div class="row pb-2">
                        <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                          <label class="form-label ff-gotham-light">Tipo do Seguro:</label>
                          <input type="text" name="productType" class="form-control ff-gotham-medium" value="Obra Nova" style="border: none; background: none;" disabled>
                        </div>
                        <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                          <label class="form-label ff-gotham-light">Ramo:</label>
                          <input type="text" name="productBranch" class="form-control ff-gotham-medium" value="67 - Riscos de Engenharia" style="border: none; background: none;" disabled>
                        </div>
                      </div>
                      <div class="row pb-2">
                        <div class="col-xl-10 col-lg-10 col-md-10 col-sm-12">
                          <label class="form-label ff-gotham-light">Tipo de Obra:</label>
                          <input type="text" name="typeWork" class="form-control ff-gotham-medium" value="Instalação de placas fotovoltaicas" style="border: none; background: none;" disabled>
                        </div>
                      </div>
                      <div class="row d-flex flex-row pb-3">
                        <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12">
                          <label class="form-label  ff-gotham-light">Início de Vigência</label>
                          <input type="date" [min]="todayDate" class="form-control input-principal ff-gotham-light" formControlName="effectiveDate" (click)="observablesFormChanges()" [(ngModel)]="entity.effectiveDate">
                          <div class="msg-error" *ngIf="formStep0.get('effectiveDate')?.errors?.['required'] && formStep0.get('effectiveDate')?.touched"> Esse campo é obrigatório!
                          </div>
                        </div>
                        <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12">
                          <label class="form-label  ff-gotham-light">Fim de Vigência</label>
                          <input type="date" class="form-control input-principal ff-gotham-light" name="expiryDate" formControlName="expiryDate" [(ngModel)]="entity.expiryDate">
                          <div class="msg-error" *ngIf="formStep0.get('expiryDate')?.errors?.['required'] && formStep0.get('expiryDate')?.touched"> Esse campo é obrigatório!
                          </div>
                        </div>
                      </div>
                      <div class="row d-flex flex-row pb-3">
                        <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12">
                          <label class="form-label ff-gotham-light">Valor Risco Total</label>
                          <input type="text" class="form-control input-principal ff-gotham-light" formControlName="equipmentAmount"  prefix="R$"  mask="0,00 || 00,00 || 000,00 || 0.000,00 || 00.000,00 || 000.000,00 || 0.000.000,00"  [(ngModel)]="entity.equipmentAmount">
                          <div class="msg-error" *ngIf="formStep0.get('equipmentAmount')?.errors?.['required'] && formStep0.get('equipmentAmount')?.touched"> Esse campo é obrigatório!
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer footerCotacao" *ngIf="!this.entity.id">
                      <div class="row" >
                        <div class="col-12 d-flex justify-content-end">
                          <button  class="btn btn-principal ff-gotham-bold mt-3" (click)="calculaPremios()">
                            <!-- (click)="verificaTermos()" -->
                            Calcular
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <form [formGroup]="formStep0">
                  <div class="card  mb-5 declaracaoCard">
                    <div class="card-header headerDeclaracao">
                      <h6 class="card-title ff-gotham-bold">
                        Informações Adicionais
                      </h6>
                    </div>
                    <div class="card-body bodyDeclaracao" style="overflow-y: auto; height: 25rem;">
                      <ul>
                        <li class="ff-gotham-light">
                          Condições do Seguro conforme processo SUSEP Nº: 15414.900178/2017-14 e 15414.901385/2013-62
                        </li>
                        <li class="ff-gotham-light">É obrigatória a contratação da cobertura Básica (Obras Civis em Costrução, e Instalação e Montagem) com pelo menos mais uma cobertura adicional. Caso esta seja Lucros Cessantes, será necessário incluir mais uma cobertura acessória.</li>
                        <li class="ff-gotham-light">A cobertura de "Responsabilidade Civil Geral e Cruzada - Danos Morais" não pode ser superior a cobertura de "RC Geral e Cruzada", bem como a cobertura de "Responsabilidade Civil Empregador - Danos Morais" não pode ser superior a "Responsabilidade Civil Empregador".</li>
                        <li class="ff-gotham-light">A seguradora possui o prazo de 15 dias para a aceitação do risco, após o recebimento dessa proposta de seguro, devidamente preenchida e assinada.</li>
                        <li class="ff-gotham-light">Quando do pedido de emissão, deverá ser enviada uma declaração de inexistência de outros seguros para o mesmo objeto desta cotação. Salientamos que a não manifestação do segurado/corretor quanto a esta informação, que é obrigatória conforme Circular SUSEP nº 327/2006, consideraremos que NÃO há outro seguro contratado pelo segurado para o mesmo objeto desta cotação.</li>
                        <li class="ff-gotham-light">
                          O segurado através desta proposta autoriza a emissão do seguro conforme dados constantes na cotação e se responsabiliza pelo pagamento do prêmio.
                        </li>
                      </ul>
                      <p class="card-text ff-gotham-light">O Segurado declara estar ciente que:</p>
                      <ol>
                        <li class="ff-gotham-light"> As informações foram prestadas com exatidão, boa fé e veracidade e assume integral responsabilidade, inclusive pelas não escritas de próprio punho e que tomou conhecimento prévio das Condições Gerais da Apólice;</li>
                        <li class="ff-gotham-light">
                          Autoriza expressamente a inclusão de todos os dados e informações relacionadas ao presente seguro, assim como de todos os eventuais sinistros e ocorrências referentes ao mesmo, em banco de dados, aos quais a seguradora poderá recorrer para análise de riscos e na liquidação de processos de sinistros. Autoriza também a consulta junto à banco de dados de instituições de perfil de crédito, bem como o registro da consulta em tais instituições;
                        </li>
                        <li class="ff-gotham-light">
                          A ARGO Seguros se reserva ao direito de recusar esta proposta até 15 dias após o recebimento da proposta, da documentação solicitada;
                        </li>
                        <li class="ff-gotham-light">A cobertura de seguro só se inicia no momento da entrega protocolada da proposta da ARGO Seguros.</li>
                      </ol>
                      <p class="card-text ff-gotham-light">Solicitamos a emissão deste documento conforme dados acima e/ou anexos. </p>
                    </div>
                    <div class="card-footer footerDeclaracao">
                      <input name="addInformation" formControlName="addInformation" formControlName="addInformation" type="checkbox" class="form-check-input" id="addInformation" >
                      <label for="addInformation" class="form-check-label ff-gotham-light" style="margin-left: .25rem">
                        Eu <strong>li e concordo</strong> com todas as informações acima.
                      </label>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row pb-5">
              <div class="col-12 d-flex justify-content-end">
                <button *ngIf="!this.entity.id" class="btn btn-principal ff-gotham-bold mt-3" style="margin-left: 1rem;" (click)="verificaValidacao()">
                  <!-- (click)="verificaTermos()" -->
                  Seguir
                </button>
                <button *ngIf="this.entity.id" class="btn btn-principal ff-gotham-bold mt-3" style="margin-left: 1rem;" (click)="verificaValidacao()">
                  <!-- (click)="verificaTermos()" -->
                  Seguir
                </button>
              </div>
            </div>
          </div>
        </mat-step>
        <mat-step label="Informações do Segurado" [completed]="formStep1.valid">
          <div class="container">
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <form [formGroup]="formStep1">
                  <div class="card cardSegurado mt-5 mb-5">
                    <div class="card-header headerSegurado">
                      <h4 class="card-title ff-gotham-bold">
                        Informações do Segurado
                      </h4>
                    </div>
                    <div class="card-body bodySegurado">

                      <div class="row d-flex flex-row pb-3">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                          <input value="PF" class="form-input" type="radio" id="pf" formControlName="type"   style="margin-right: .75rem;">
                          <label for="pf" class="form-label ff-gotham-medium" style="margin-right: 2rem;">Pessoa Física</label>
                          <input value="PJ" type="radio" id="pj" formControlName="type"  style="margin-right: .75rem;">
                          <label for="pj" class="form-label ff-gotham-medium" >Pessoa Juridica</label>
                        </div>
                      </div>
                      <div class="row d-flex flex-row pb-3">
                      <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12">
                        <label class="form-label ff-gotham-light">Segurado:</label>
                        <input type="text" formControlName="insured" class="form-control input-principal ff-gotham-medium" [(ngModel)]="entity.insured">
                        <div class="msg-error" *ngIf="formStep1.get('insured')?.errors?.['required'] && formStep1.get('insured')?.touched"> Esse campo é obrigatório!
                        </div>
                      </div>
                      </div>
                      <div class="row pb-2">
                        <div class="col-xl-5 col-lg-5 col-md-7 col-sm-7">
                          <label  class="form-label ff-gotham-light" *ngIf="formStep1.value.type == 'PF'">CPF</label>
                          <input formControlName="document"  type="text" class="form-control input-principal ff-gotham-light" mask="000.000.000-00" [(ngModel)]="entity.document" *ngIf="formStep1.value.type == 'PF'">
                          <label  class="form-label ff-gotham-light" *ngIf="formStep1.value.type == 'PJ'">CNPJ</label>
                          <input formControlName="document"  type="text" class="form-control input-principal ff-gotham-light" mask="00.000.000/0000-00" [(ngModel)]="entity.document" *ngIf="formStep1.value.type == 'PJ'">
                          <div class="msg-error" *ngIf="formStep1.get('document')?.errors?.['required'] && formStep1.get('document')?.touched"> Esse campo é obrigatório!
                          </div>
                        </div>
                        <div class="col-xl-5 col-lg-5 col-md-7 col-sm-7">
                          <label class="form-label ff-gotham-light">Telefone</label>
                          <input formControlName="mobile" type="tel" class="form-control input-principal ff-gotham-light" mask="(00) 0000-0000 ||(00) 00000-0000" [(ngModel)]="entity.mobile">
                          <div class="msg-error" *ngIf="formStep1.get('mobile')?.errors?.['required'] && formStep1.get('mobile')?.touched"> Esse campo é obrigatório!
                          </div>
                        </div>
                      </div>
                      <div class="row pb-2">
                        <div class="col-xl-5 col-lg-5 col-md-7 col-sm-7" *ngIf="formStep1.value.type == 'PF'">
                          <label for="dateOfBirth" class="form-label ff-gotham-light">Data de Nascimento</label>
                          <input formControlName="dateOfBirth" id="dateOfBirth" type="date" class="form-control input-principal ff-gotham-light" [(ngModel)]="entity.dateOfBirth">
                          <div class="msg-error" *ngIf="formStep1.get('dateOfBirth')?.errors?.['required'] && formStep1.get('dateOfBirth')?.touched"> Esse campo é obrigatório!
                          </div>
                        </div>
                        <div class="col-xl-5 col-lg-5 col-md-7 col-sm-7">
                          <label for="email" class="form-label ff-gotham-light">E-mail</label>
                          <input formControlName="email" id="email" type="text" class="form-control input-principal ff-gotham-light" [(ngModel)]="entity.email">
                          <div class="msg-error" *ngIf="formStep1.get('email')?.errors?.['required'] && formStep1.get('email')?.touched"> Esse campo é obrigatório!
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <form [formGroup]="formStep1">
                  <div class="card cardEndereco mb-5">
                    <div class=" card-header headerEndereco">
                      <h4 class="card-title ff-gotham-bold">Informações de Endereço</h4>
                    </div>
                    <div class="card-body bodyEndereco">
                      <div class="row pb-2">
                        <div class="col-xl-5 col-lg-5 col-md-7 col-sm-7">
                          <label class="form-label ff-gotham-light" for="postCode">1 - CEP Endereço do local da obra</label>
                          <input formControlName="postCode" id="postCode" class="form-control input-principal ff-gotham-light" mask="00000-000" [(ngModel)]="entity.postCode">
                          <div class="msg-error" *ngIf="formStep1.get('postCode')?.errors?.['required'] && formStep1.get('postCode')?.touched"> Esse campo é obrigatório!
                          </div>
                        </div>
                      </div>
                      <div class="row pb-2">
                        <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10">
                          <label for="street" class="form-label ff-gotham-light">Endereço do local da obra</label>
                          <input formControlName="street"  type="text" class="form-control ff-gotham-light input-principal" [(ngModel)]="entity.street">
                          <div class="msg-error" *ngIf="formStep1.get('street')?.errors?.['required'] && formStep1.get('street')?.touched"> Esse campo é obrigatório!
                          </div>
                        </div>
                      </div>
                      <div class="row pb-2">
                        <div class="col-xl-4 col-lg-4 col-md-10 col-sm-10">
                          <label for="number" class="form-label ff-gotham-light">Número</label>
                          <input formControlName="number" id="number" type="text" class="form-control input-principal ff-gotham-light" mask="0000000000" [(ngModel)]="entity.number">
                          <div class="msg-error" *ngIf="formStep1.get('number')?.errors?.['required'] && formStep1.get('number')?.touched"> Esse campo é obrigatório!
                          </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-10 col-sm-10">
                          <label for="complement" class="form-label ff-gotham-light">Complemento</label>
                          <input formControlName="complement" id="complement" type="text" class="form-control input-principal ff-gotham-light" [(ngModel)]="entity.complement">

                        </div>
                      </div>
                      <div class="row pb-2">
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 d-flex flex-column">
                          <label class="form-label ff-gotham-light" for="state">UF</label>
                          <select id="state" formControlName="state" class="custom-select input-principal ff-gotham-light p-2" [(ngModel)]="entity.state">
                            <option value="AC">Acre</option>
                            <option value="AL">Alagoas</option>
                            <option value="AP">Amapá</option>
                            <option value="AM">Amazonas</option>
                            <option value="BA">Bahia</option>
                            <option value="CE">Ceará</option>
                            <option value="DF">Distrito Federal</option>
                            <option value="ES">Espírito Santo</option>
                            <option value="GO">Goiás</option>
                            <option value="MA">Maranhão</option>
                            <option value="MT">Mato Grosso</option>
                            <option value="MS">Mato Grosso do Sul</option>
                            <option value="MG">Minas Gerais</option>
                            <option value="PA">Pará</option>
                            <option value="PB">Paraíba</option>
                            <option value="PR">Paraná</option>
                            <option value="PE">Pernambuco</option>
                            <option value="PI">Piauí</option>
                            <option value="RJ">Rio de Janeiro</option>
                            <option value="RN">Rio Grande do Norte</option>
                            <option value="RS">Rio Grande do Sul</option>
                            <option value="RO">Rondônia</option>
                            <option value="RR">Roraima</option>
                            <option value="SC">Santa Catarina</option>
                            <option value="SP">São Paulo</option>
                            <option value="SE">Sergipe</option>
                            <option value="TO">Tocantins</option>
                            <option value="EX">Estrangeiro</option>
                          </select>
                          <div class="msg-error" *ngIf="formStep1.get('state')?.errors?.['required'] && formStep1.get('state')?.touched"> Esse campo é obrigatório!
                          </div>

                        </div>
                        <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                          <label class="form-label ff-gotham-light" for="city">Cidade</label>
                          <input formControlName="city"  id="city" class="form-control input-principal ff-gotham-light" [(ngModel)]="entity.city">
                          <div class="msg-error" *ngIf="formStep1.get('city')?.errors?.['required'] && formStep1.get('city')?.touched"> Esse campo é obrigatório!
                          </div>
                        </div>
                      </div>
                      <div class="row pb-2">
                        <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10">
                          <label class="form-label ff-gotham-light" for="suburb">Bairro</label>
                          <input formControlName="suburb"  id="suburb" class="form-control input-principal input-suburb ff-gotham-light" [(ngModel)]="entity.suburb">
                          <div class="msg-error" *ngIf="formStep1.get('suburb')?.errors?.['required'] && formStep1.get('suburb')?.touched"> Esse campo é obrigatório!
                          </div>
                        </div>
                      </div>

                      <hr class="col-xl-12 col-lg-12 col-md-12 col-sm-12">

                      <div class="row pb-2 d-lex flex-row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                          <button
                            mat-raised-button
                            matTooltip="O endereço de cobrança deve ser o endereço do contratante do seguro. Geralmente o integrador."
                            matTooltipPosition="right"
                            style="background: none; border: none; width: auto; padding: 0; cursor:help;">
                              <mat-icon style="margin-right: 0.5rem; font-size: 1.02rem; width: 1.02rem; height: 1.02rem; color: #a7a7a7; cursor:help">help</mat-icon>
                          </button>
                          <input name="checkEndereco" id="checkEndereco" type="checkbox" class="form-check-l"  [formControl]="mesmoEndereco">
                          <label for="checkEndereco" class="ff-gotham-medium pb-4" style="margin-left: .75rem; padding-bottom: .25rem;">O endereço de cobrança é o mesmo endereço do local da obra?</label>

                        </div>
                      </div>
                      <div class="row pb-2">
                        <div class="col-xl-5 col-lg-5 col-md-7 col-sm-7">
                          <label class="form-label ff-gotham-light" for="billingPostCode">2- CEP Endereço Cobrança</label>
                          <input formControlName="billingPostCode" id="billingPostCode" class="form-control input-principal ff-gotham-light" mask="00000-000" [(ngModel)]="entity.postCodeBilling">

                        </div>
                      </div>
                      <div class="row pb-2">
                        <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10">
                          <label for="billingStreet" class="form-label ff-gotham-light">Endereço de Cobrança</label>
                          <input formControlName="billingStreet"  id="billingStreet" type="text" class="form-control ff-gotham-light input-principal" [(ngModel)]="entity.streetBilling">
                        </div>
                      </div>
                      <div class="row pb-2">
                        <div class="col-xl-4 col-lg-4 col-md-10 col-sm-10">
                          <label for="billingNumber" class="form-label ff-gotham-light">Número</label>
                          <input formControlName="billingNumber" id="billingNumber" type="text" class="form-control input-principal ff-gotham-light" mask="0000000000" [(ngModel)]="entity.numberBilling">

                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-10 col-sm-10">
                          <label for="billingComplement" class="form-label ff-gotham-light">Complemento</label>
                          <input formControlName="billingComplement" id="billingComplement" type="text" class="form-control input-principal ff-gotham-light" [(ngModel)]="entity.complementBilling">

                        </div>
                      </div>
                      <div class="row pb-2">
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 d-flex flex-column">
                          <label class="form-label ff-gotham-light" for="billingState">UF</label>
                          <select id="billingState"  formControlName="billingState" class="custom-select input-principal ff-gotham-light p-2" [(ngModel)]="entity.stateBilling">
                            <option value="AC">Acre</option>
                            <option value="AL">Alagoas</option>
                            <option value="AP">Amapá</option>
                            <option value="AM">Amazonas</option>
                            <option value="BA">Bahia</option>
                            <option value="CE">Ceará</option>
                            <option value="CE">Ceará</option>
                            <option value="DF">Distrito Federal</option>
                            <option value="ES">Espírito Santo</option>
                            <option value="GO">Goiás</option>
                            <option value="MA">Maranhão</option>
                            <option value="MT">Mato Grosso</option>
                            <option value="MS">Mato Grosso do Sul</option>
                            <option value="MG">Minas Gerais</option>
                            <option value="PA">Pará</option>
                            <option value="PB">Paraíba</option>
                            <option value="PR">Paraná</option>
                            <option value="PE">Pernambuco</option>
                            <option value="PI">Piauí</option>
                            <option value="RJ">Rio de Janeiro</option>
                            <option value="RN">Rio Grande do Norte</option>
                            <option value="RS">Rio Grande do Sul</option>
                            <option value="RO">Rondônia</option>
                            <option value="RR">Roraima</option>
                            <option value="SC">Santa Catarina</option>
                            <option value="SP">São Paulo</option>
                            <option value="SE">Sergipe</option>
                            <option value="TO">Tocantins</option>
                            <option value="EX">Estrangeiro</option>
                          </select>

                        </div>
                        <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                          <label class="form-label ff-gotham-light" for="billingCity">Cidade</label>
                          <input formControlName="billingCity"  id="billingCity" class="form-control input-principal ff-gotham-light" [(ngModel)]="entity.cityBilling">

                        </div>
                      </div>
                      <div class="row pb-2">
                        <div class="col-10">
                          <label class="form-label ff-gotham-light" for="billingSuburb">Bairro</label>
                          <input formControlName="billingSuburb"  id="billingSuburb" class="form-control input-principal input-billingSuburb ff-gotham-light" [(ngModel)]="entity.suburBilling">

                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row pb-5">
              <div class="col-12 d-flex justify-content-end">
                <button class="btn btn-principal ff-gotham-bold mt-3" (click)="checkData()" *ngIf="!entity.id">
                  Seguir
                </button>
                <button class="btn btn-principal ff-gotham-bold mt-3" *ngIf="entity.id" matStepperNext>
                  Seguir
                </button>
              </div>
            </div>
          </div>
        </mat-step>

        <mat-step label="Pedido Finalizado!" [completed]="finalizado == true">
          <div class="container mt-5 mb-5">
            <div class="row mt-5">
              <p class="ff-gotham-bold mt-5" style="font-size:24px;">
                <mat-icon class="text-align-center" style="color: green; font-size: 22px; font-weight: 800;">task_alt</mat-icon>
                Sua solicitação de emissão foi gerada com sucesso! </p>
              <br>
              <p class="ff-gotham-light" style="font-size: 18px;"> <strong>Próximo passo:</strong> em até 72h úteis nossa equipe enviará sua apólice e boleto por e-mail.</p>
              <p class="ff-gotham-medium" style="color: #DEA32C">Importante: a cobertura securitária só estará garantida após a quitação do boleto</p>
            </div>
            <hr>
            <div class="col-12 d-flex justify-content-end">
                <button [routerLink]="['/user', 'cotacaoRE']" class="btn btn-principal m-1">Visualizar Pedidos</button>
                <button (click)="novopedido()" class="btn btn-principal m-1">Novo Pedido</button>
            </div>
          </div>
        </mat-step>
      </mat-stepper>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-8 col-sm-12">
      <div class="card" style="border-left-color: #F5CF7A">
        <div class="card-body">
          <div class="row">
            <div class="col xl-5 col-lg-5 col-md-12 col-sm-12">
              <p class="ff-gotham-light">Vigência do Seguro:</p>
            </div>
            <div class="col xl-7 col-lg-7 col-md-12 col-sm-12" style="text-align: end;">
              <p class="ff-gotham-medium">
                {{ service.ajustDateView(formStep0.value?.effectiveDate ?? '') }} - {{service.ajustDateView(formStep0.value?.expiryDate ?? '')}}
              </p>
            </div>
          </div>
          <div class="row" >
            <div class="col xl-6 col-lg-6 col-md-6 col-sm-12">
              <p class="ff-gotham-light">Valor em Risco:</p>
            </div>
            <div class="col xl-6 col-lg-6 col-md-6 col-sm-12" style="text-align: end;">
              <p class="ff-gotham-medium"> R$ {{ formStep0.value.equipmentAmount }} </p>
            </div>
          </div>
        </div>
      </div>

      <div class="card mt-4" *ngIf="calcular == true">
        <div class="card-body">
          <div class="row">
            <div class="col xl-6 col-lg-6 col-md-6 col-sm-12">
              <p class="ff-gotham-bold">Prêmio Líquido:</p>
            </div>
            <div class="col xl-6 col-lg-6 col-md-6 col-sm-12" style="text-align: end;">
              <p class="ff-gotham-medium"> R$ {{ formStep0.value?.netPremium }}</p>
            </div>
          </div>
          <hr style="border-style:dotted">
          <div class="row">
            <div class="col xl-12 col-lg-12 col-md-12 col-sm-12">
              <p class="ff-gotham-bold">Coberturas: </p>
              <div class="coberturas" >
                <div class="row d-flex flex-row">
                  <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1">
                    <button
                    mat-raised-button
                    matTooltip="Obras civis em construção, instalação e montagem; Franquias: 10% dos prejuízos indenizáveis, com mínimo de R$ 5.000 por evento;"
                    matTooltipPosition="right"
                    style="background: none; border: none; width: auto; padding: 0; cursor:help;">
                      <mat-icon style="margin-right: 0.5rem; font-size: 1.02rem; width: 1.02rem; height: 1.02rem; color: #a7a7a7; cursor:help">help</mat-icon>
                    </button>
                  </div>
                  <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                    <p class="ff-gotham-medium" style="font-size: 14px;"> Cobertura Básica</p>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12" style="text-align: end;">
                    <p class="ff-gotham-bold" style="font-size: 12px;">R$ {{formStep0.value?.cb }}</p>
                  </div>
                </div>
                <div class="row d-flex flex-row">
                  <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1">
                    <button mat-raised-button
                    matTooltip="Danos em consequência de erro de projeto e riscos do fabricante; Franquias: 10% dos prejuízos indenizáveis, com mínimo de R$ 10.000 por evento"
                    matTooltipPosition="right"
                    style="background:none; border: none; width: auto; padding: 0; cursor:help;">
                      <mat-icon style="margin-right: 0.5rem; font-size: 1.02rem; width: 1.02rem; height: 1.02rem; color: #a7a7a7">help</mat-icon>
                    </button>
                  </div>
                  <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                    <p class="ff-gotham-medium" style="font-size: 14px;"> Cobertura de Danos</p>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12" style="text-align: end;">
                    <p class="ff-gotham-bold" style="font-size: 12px;">R$ {{formStep0.value?.cd }}</p>
                  </div>
                </div>
                <div class="row d-flex flex-row">
                  <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1">
                    <button
                    mat-raised-button
                    matTooltip="Franquias: Será somado à franquia da cobertura básica."
                    matTooltipPosition="right"
                    style="background:none; border: none; width: auto; padding: 0; cursor:help;">
                      <mat-icon style="margin-right: 0.5rem; font-size: 1.02rem; width: 1.02rem; height: 1.02rem; color: #a7a7a7">help</mat-icon>
                    </button>
                  </div>
                  <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                    <p class="ff-gotham-medium" style="font-size: 14px;"> Despesas com Desentulho do Local</p>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12" style="text-align: end;">
                    <p class="ff-gotham-bold" style="font-size: 12px;">R$ {{formStep0.value?.ddl }}</p>
                  </div>
                </div>
                <div class="row d-flex flex-row">
                  <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1">
                    <button
                    mat-raised-button
                    matTooltip="Franquias: 20% de todas as despesas, em cada situação de ocorrência e relativa exclusivamente às coberturas de contenção de sinistros."
                    matTooltipPosition="right"
                    style="background:none; border: none; width: auto; padding: 0; cursor:help;">
                      <mat-icon style="margin-right: 0.5rem; font-size: 1.02rem; width: 1.02rem; height: 1.02rem; color: #a7a7a7">help</mat-icon>
                    </button>
                  </div>
                  <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                    <p class="ff-gotham-medium" style="font-size: 14px;"> Despesas de Contenção e Salvamento de Sinistros</p>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12" style="text-align: end;">
                    <p class="ff-gotham-bold" style="font-size: 12px;">R$ {{formStep0.value?.dcss }}</p>
                  </div>
                </div>
                <div class="row d-flex flex-row">
                  <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1">
                    <button
                    mat-raised-button
                    matTooltip="Franquias: Será somado à franquia da cobertura básica."
                    matTooltipPosition="right"
                    style="background:none; border: none; width: auto; padding: 0; cursor:help;">
                      <mat-icon style="margin-right: 0.5rem; font-size: 1.02rem; width: 1.02rem; height: 1.02rem; color: #a7a7a7">help</mat-icon>
                    </button>
                  </div>
                  <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                    <p class="ff-gotham-medium" style="font-size: 14px;"> Honorários de Peritos</p>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12" style="text-align: end;">
                    <p class="ff-gotham-bold" style="font-size: 12px;">R$ {{formStep0.value?.hp }}</p>
                  </div>
                </div>
                <div class="row d-flex flex-row">
                  <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1">
                    <button
                    mat-raised-button
                    matTooltip="Franquias: POS de 15% dos prejuízos indenizáveis, com mínimo de R$ 2.000 por evento e por reclamante."
                    matTooltipPosition="right"
                    style="background:none; border: none; width: auto; padding: 0; cursor:help;">
                      <mat-icon style="margin-right: 0.5rem; font-size: 1.02rem; width: 1.02rem; height: 1.02rem; color: #a7a7a7">help</mat-icon>
                    </button>
                  </div>
                  <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                    <p class="ff-gotham-medium" style="font-size: 14px;"> Responsabilidade Civil Geral e Cruzada</p>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12" style="text-align: end;">
                    <p class="ff-gotham-bold" style="font-size: 12px;">R$ {{formStep0.value?.rcgc }}</p>
                  </div>
                </div>
                <div class="row d-flex flex-row">
                  <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1">
                    <button
                    mat-raised-button
                    matTooltip="Franquias: POS de 15% dos prejuízos indenizáveis, com mínimo de R$ 2.000 por evento e por reclamante."
                    matTooltipPosition="right"
                    style="background:none; border: none; width: auto; padding: 0; cursor:help;">
                      <mat-icon style="margin-right: 0.5rem; font-size: 1.02rem; width: 1.02rem; height: 1.02rem; color: #a7a7a7">help</mat-icon>
                    </button>
                  </div>
                  <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                    <p class="ff-gotham-medium" style="font-size: 14px;">Responsabilidade Civil – Fundações</p>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12" style="text-align: end;">
                    <p class="ff-gotham-bold" style="font-size: 12px;">R$ {{formStep0.value?.rcf }}</p>
                  </div>
                </div>
                <div class="row d-flex flex-row">
                  <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1">
                    <button
                    mat-raised-button
                    matTooltip="Franquias: POS de 15% dos prejuízos indenizáveis, com mínimo de R$ 2.000 por evento e por reclamante."
                    matTooltipPosition="right"
                    style="background:none; border: none; width: auto; padding: 0; cursor:help;">
                      <mat-icon style="margin-right: 0.5rem; font-size: 1.02rem; width: 1.02rem; height: 1.02rem; color: #a7a7a7">help</mat-icon>
                    </button>
                  </div>
                  <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                    <p class="ff-gotham-medium" style="font-size: 14px;"> Responsabilidade Civil – Erro de Projeto</p>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12" style="text-align: end;">
                    <p class="ff-gotham-bold" style="font-size: 12px;">R$ {{formStep0.value?.rcep }}</p>
                  </div>
                </div>
                <div class="row d-flex flex-row">
                  <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1">
                    <button
                    mat-raised-button
                    matTooltip="Franquias: POS de 15% dos prejuízos indenizáveis, com mínimo de R$ 2.000 por evento e por reclamante."
                    matTooltipPosition="right"
                    style="background:none; border: none; width: auto; padding: 0; cursor:help;">
                      <mat-icon style="margin-right: 0.5rem; font-size: 1.02rem; width: 1.02rem; height: 1.02rem; color: #a7a7a7">help</mat-icon>
                    </button>
                  </div>
                  <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                    <p class="ff-gotham-medium" style="font-size: 14px;"> Responsabilidade Civil - Empregador</p>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12" style="text-align: end;">
                    <p class="ff-gotham-bold" style="font-size: 12px;">R$ {{formStep0.value?.rce }}</p>
                  </div>
                </div>
                <div class="row d-flex flex-row">
                  <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1">
                    <button
                    mat-raised-button
                    matTooltip="Franquias: Não há."
                    matTooltipPosition="right"
                    style="background:none; border: none; width: auto; padding: 0; cursor:help;">
                      <mat-icon style="margin-right: 0.5rem; font-size: 1.02rem; width: 1.02rem; height: 1.02rem; color: #a7a7a7">help</mat-icon>
                    </button>
                  </div>
                  <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                    <p class="ff-gotham-medium" style="font-size: 14px;"> Danos Morais</p>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12" style="text-align: end;">
                    <p class="ff-gotham-bold" style="font-size: 12px;">R$ {{formStep0.value?.dm }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr style="border-style:dotted">
          <div class="row">
            <div class="col xl-12 col-lg-12 col-md-12 col-sm-12">
              <p class="ff-gotham-bold">Taxas e Impostos:</p>
            </div>
            <div class="col xl-6 col-lg-6 col-md-6 col-sm-12">
              <p class="ff-gotham-light"> IOF</p>
            </div>
            <div class="col xl-6 col-lg-6 col-md-6 col-sm-12" style="text-align: end;">
              <p class="ff-gotham-medium"> R$ {{formStep0.value?.iof }}</p>
            </div>
          </div>
          <hr>
          <div class="row">
            <div class="col xl-6 col-lg-6 col-md-6 col-sm-12">
              <p class="ff-gotham-bold">Prêmio Total:</p>
            </div>
            <div class="col xl-6 col-lg-6 col-md-6 col-sm-12" style="text-align: end;">
              <p class="ff-gotham-medium"> R$ {{ formStep0.value?.totalPremium }}</p>
            </div>
          </div>
          <hr>
          <div class="row">
            <div class="col xl-6 col-lg-6 col-md-6 col-sm-12">
              <p class="ff-gotham-bold">Nº de Parcelas:</p>
            </div>
            <div class="col xl-6 col-lg-6 col-md-6 col-sm-12" style="text-align: end;">
              <select name="numParcelasCartao" formControlName="installment" id="numParcelasCartao" type="text" class="custom-select input-principal ff-gotham-light p-2 mb-2" style="width: 100%;" (change)="onParcelChange($event)">
                <option [value]="i + 1" *ngFor="let parcela of parcelas; let i = index">{{i + 1}}x de R${{parcela}} sem juros</option>
              </select>
              <div class="msg-error" *ngIf="formStep1.get('installment')?.errors?.['required'] && formStep0.get('installment')?.touched"> Esse campo é obrigatório!
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<dg-modal size="lg" #modal>
  <div modal-header>
    <h4 class="ff-gotham-medium">Resumo</h4>
  </div>
  <div modal-body>
    <p class="subtitle ff-gotham-medium"> Por favor, verifique se os dados preenchidos anteriormente estão corretos:</p>
    <hr>
    <p class="ff-gotham-medium">Informações do seguro:</p>
    <div class="row">
      <div class="d-flex flex-row col-xl-6 col-lg-6 col-md-6 col-sm-12">
        <p class="ff-gotham-medium" style="padding-right: 0.4rem;">Valor em risco total:</p>
        <p class="ff-gotham-light">R$ {{ formStep0.value.equipmentAmount ||'' }}</p>
      </div>
      <div class="d-flex flex-row col-xl-6 col-lg-6 col-md-6 col-sm-6">
        <p class="ff-gotham-medium" style="padding-right: 0.4rem;">Vigência do seguro:</p>
        <p class="ff-gotham-light">{{ service.ajustDateView(formStep0.value.effectiveDate || '') }} - {{ service.ajustDateView(formStep0.value.expiryDate || '') }}</p>
      </div>
    </div>
    <div class="row">
      <div class="d-flex flex-row col-xl-6 col-lg-6 col-md-6 col-sm-12">
        <p class="ff-gotham-medium" style="padding-right: 0.4rem;">Prêmio líquido:</p>
        <p class="ff-gotham-light">R$ {{ maskValores(formStep0.value.netPremium || '')}}</p>
      </div>
      <div class="d-flex flex-row col-xl-6 col-lg-6 col-md-6 col-sm-6">
        <p class="ff-gotham-medium" style="padding-right: 0.4rem;">IOF:</p>
        <p class="ff-gotham-light">R$ {{ maskValores(formStep0.value.iof || '')}}</p>
      </div>
    </div>
    <div class="row">
      <div class="d-flex flex-row col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <p class="ff-gotham-medium" style="padding-right: 0.4rem;">Prêmio total:</p>
        <p class="ff-gotham-light">R$ {{ maskValores(formStep0.value.totalPremium || '') }}</p>
      </div>

    </div>
    <br>
    <p class="ff-gotham-medium">Informações do segurado:</p>
    <div class="row">
      <div class="d-flex flex-row col-xl-6 col-lg-6 col-md-6 col-sm-12">
        <p class="ff-gotham-medium" style="padding-right: 0.4rem;">Nome:</p>
        <p class="ff-gotham-light">{{ formStep1.value.insured || ''}}</p>
      </div>
      <div class="d-flex flex-row col-xl-6 col-lg-6 col-md-6 col-sm-6">
        <p class="ff-gotham-medium" style="padding-right: 0.4rem;">Documento:</p>
        <p class="ff-gotham-light">{{ maskDocument(formStep1.value.document || '') }}</p>
      </div>
    </div>
    <div class="row">
      <div class="d-flex flex-row col-xl-6 col-lg-6 col-md-6 col-sm-12" *ngIf="formStep1.value.type == 'PF'">
        <p class="ff-gotham-medium" style="padding-right: 0.4rem;">Data de nascimento:</p>
        <p class="ff-gotham-light">{{ maskDate(formStep1.value.dateOfBirth || '') }}</p>
      </div>
      <div class="d-flex flex-row col-xl-6 col-lg-6 col-md-6 col-sm-6">
        <p class="ff-gotham-medium" style="padding-right: 0.4rem;">E-mail:</p>
        <p class="ff-gotham-light">{{ formStep1.value.email || '' }}</p>
      </div>
    </div>
    <div class="row">
      <div class="d-flex flex-row col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <p class="ff-gotham-medium" style="padding-right: 0.4rem;">Telefone:</p>
        <p class="ff-gotham-light">{{ maskMobile(formStep1.value.mobile || '') }}</p>
      </div>
    </div>
    <br>
    <p class="ff-gotham-medium">Endereço do local da obra:</p>
    <div class="row">
      <div class="d-flex flex-row col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <p class="ff-gotham-medium" style="padding-right: 0.4rem;">CEP:</p>
        <p class="ff-gotham-light">{{ formStep1.value.postCode || '' }}</p>
      </div>
    </div>
    <div class="row">
      <div class="d-flex flex-row col-xl-6 col-lg-6 col-md-6 col-sm-12">
        <p class="ff-gotham-medium" style="padding-right: 0.4rem;">Rua:</p>
        <p class="ff-gotham-light">{{ formStep1.value.street || '' }}</p>
      </div>
      <div class="d-flex flex-row col-xl-6 col-lg-6 col-md-6 col-sm-6">
        <p class="ff-gotham-medium" style="padding-right: 0.4rem;">Número:</p>
        <p class="ff-gotham-light">{{ formStep1.value.number || '' }}</p>
      </div>
    </div>
    <div class="row">
      <div class="d-flex flex-row col-xl-6 col-lg-6 col-md-6 col-sm-12">
        <p class="ff-gotham-medium" style="padding-right: 0.4rem;">Complemento:</p>
        <p class="ff-gotham-light">{{ formStep1.value.complement || 'Não informado'}}</p>
      </div>
      <div class="d-flex flex-row col-xl-6 col-lg-6 col-md-6 col-sm-6">
        <p class="ff-gotham-medium" style="padding-right: 0.4rem;">UF:</p>
        <p class="ff-gotham-light">{{ formStep1.value.state  || ''}}</p>
      </div>
    </div>
    <div class="row">
      <div class="d-flex flex-row col-xl-6 col-lg-6 col-md-6 col-sm-12">
        <p class="ff-gotham-medium" style="padding-right: 0.4rem;">Cidade:</p>
        <p class="ff-gotham-light">{{ formStep1.value.city || '' }}</p>
      </div>
      <div class="d-flex flex-row col-xl-6 col-lg-6 col-md-6 col-sm-6">
        <p class="ff-gotham-medium" style="padding-right: 0.4rem;">Bairro:</p>
        <p class="ff-gotham-light">{{ formStep1.value.suburb || '' }}</p>
      </div>
    </div>
    <br>
    <p class="ff-gotham-medium">Endereço de cobrança:</p>
    <div class="row">
      <div class="d-flex flex-row col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <p class="ff-gotham-medium" style="padding-right: 0.4rem;">CEP:</p>
        <p class="ff-gotham-light">{{ formStep1.value.billingPostCode || ''}}</p>
      </div>
    </div>
    <div class="row">
      <div class="d-flex flex-row col-xl-6 col-lg-6 col-md-6 col-sm-12">
        <p class="ff-gotham-medium" style="padding-right: 0.4rem;">Rua:</p>
        <p class="ff-gotham-light">{{ formStep1.value.billingStreet || ''}}</p>
      </div>
      <div class="d-flex flex-row col-xl-6 col-lg-6 col-md-6 col-sm-6">
        <p class="ff-gotham-medium" style="padding-right: 0.4rem;">Número:</p>
        <p class="ff-gotham-light">{{ formStep1.value.billingNumber || ''}}</p>
      </div>
    </div>
    <div class="row">
      <div class="d-flex flex-row col-xl-6 col-lg-6 col-md-6 col-sm-12">
        <p class="ff-gotham-medium" style="padding-right: 0.4rem;">Complemento:</p>
        <p class="ff-gotham-light">{{ formStep1.value.billingComplement || 'Não informado'}}</p>
      </div>
      <div class="d-flex flex-row col-xl-6 col-lg-6 col-md-6 col-sm-6">
        <p class="ff-gotham-medium" style="padding-right: 0.4rem;">UF:</p>
        <p class="ff-gotham-light">{{ formStep1.value.billingState || ''}}</p>
      </div>
    </div>
    <div class="row">
      <div class="d-flex flex-row col-xl-6 col-lg-6 col-md-6 col-sm-12">
        <p class="ff-gotham-medium" style="padding-right: 0.4rem;">Cidade:</p>
        <p class="ff-gotham-light">{{ formStep1.value.billingCity || ''}}</p>
      </div>
      <div class="d-flex flex-row col-xl-6 col-lg-6 col-md-6 col-sm-6">
        <p class="ff-gotham-medium" style="padding-right: 0.4rem;">Bairro:</p>
        <p class="ff-gotham-light">{{ formStep1.value.billingSuburb || ''}}</p>
      </div>
    </div>
  </div>
  <div modal-footer>
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 d-flex flex-row justify-content-end">
          <button class="btn btn-secundario" style="margin-right: 2rem; width:50%" (click)="cancelar()">Cancelar</button>
          <button class="btn btn-principal" (click)="requestRe()">Confirmar</button>
        </div>
      </div>
  </div>
</dg-modal>

<div class="loader" *ngIf="showLoader">
  <div class="content-loader">
    <mat-spinner></mat-spinner>
  </div>
</div>

