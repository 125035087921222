import {
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { perfil } from './../../../interfaces/perfil';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user/user.service';
import { SessionService } from 'src/app/core/service/session/session.service';
import { HttpClient } from '@datagrupo/dg-crud';
import { environment } from 'src/environments/environment';
import { UsuariosEntity } from '../usuarios/usuarios.entity';
import Swal from 'sweetalert2';
import { DgModalComponent } from '@datagrupo/dg-ng-util';

@Component({
  selector: 'app-profile-user',
  templateUrl: './profile-user.component.html',
  styleUrls: ['./profile-user.component.scss'],
})
export class ProfileUserComponent implements OnInit {

  @ViewChild('modal') modal!: DgModalComponent
  // user: any;
  editPersonEnabled: boolean = false;
  editBankEnabled: boolean = false;

  public mostrarCurrentPass = true;
  public mostrarNewPass = true;
  public mostrarConfirmPass = true;
  public error: boolean = false;



  public formPerfil = new FormGroup({
    username: new FormControl({ value:'', disabled: true }, [
      Validators.required,
      Validators.pattern(/(.|\s)*\S(.|\s)*/),
    ]),
    email: new FormControl({ value: '', disabled: true }, [
      Validators.required,
      Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/),
    ]),
    telefone: new FormControl({ value: '', disabled: true }, [
      Validators.required]),
    cpf: new FormControl({ value: '', disabled: true }, [
      Validators.required,
      Validators.pattern(/\d{3}\.?\d{3}\.?\d{3}-?\d{2}/),
    ]),
    produtor: new FormControl({ value: '', disabled: true }),
  });

  // public formBanco = new FormGroup({
  //   banco: new FormControl('selecione'),
  //   conta: new FormControl('selecione'),
  //   agencia: new FormControl(''),
  //   numConta: new FormControl(''),
  //   pix: new FormControl(''),
  // })

  public formSenha = new FormGroup({
    senhaAtual: new FormControl('', [
      Validators.required
    ]),
    senhaNova: new FormControl('', [
      Validators.required,
      Validators.minLength(6)
    ]),
    confirmarSenhaNova: new FormControl('', [
      Validators.required,
      Validators.minLength(6)
    ])
  })

  constructor(
    public service: UserService,
    private route: ActivatedRoute,
    public session: SessionService,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    if (!this.session.user.id) return;
    this.service.buscarUserId(this.session.user.id).subscribe((perfil: {data: perfil} ) => {
      this.formPerfil.patchValue({
        ...perfil.data
      });
      if(window.sessionStorage.getItem('temporaryLogin') == '1') {
      this.modal?.open()
      // this.formSenha.controls.senhaAtual.disable();
    }
    });

  }


  toggleEditPerson() {
    this.editPersonEnabled = !this.editPersonEnabled;
    if (this.editPersonEnabled == true) {
      this.formPerfil.get('username')?.enable();
      this.formPerfil.get('email')?.enable();
      this.formPerfil.get('telefone')?.enable();
    } else {
      this.formPerfil.get('username')?.disable();
      this.formPerfil.get('email')?.disable();
      this.formPerfil.get('telefone')?.disable();
    }
  }

  // toggleEditBank() {
  //   this.editBankEnabled = !this.editBankEnabled;
  // }

  updateUser(){

    const data = {
      id: this.session.user.id,
      ...this.formPerfil.getRawValue()
    }

    this.service.editUser(data).subscribe(resp => {
      alert('Usuário Editado')
    })
  }

  currentPassword(){
    const input = document.querySelector('.password')
      if(input?.getAttribute('type')== 'password'){
        input.setAttribute('type', 'text')
        this.mostrarCurrentPass = false
      }else{
        input?.setAttribute('type', 'password')
        this.mostrarCurrentPass = true
      }
  }

  newPassword(){
    const input = document.querySelector('.newPassword')
      if(input?.getAttribute('type')== 'password'){
        input.setAttribute('type', 'text')
        this.mostrarNewPass = false
      }else{
        input?.setAttribute('type', 'password')
        this.mostrarNewPass = true
      }
  }

  confirmPassword(){
    const input = document.querySelector('.confirmPassword')
      if(input?.getAttribute('type')== 'password'){
        input.setAttribute('type', 'text')
        this.mostrarConfirmPass = false
      }else{
        input?.setAttribute('type', 'password')
        this.mostrarConfirmPass = true
      }
  }

  passwordMatchValidator(event: any){
    const password = this.formSenha.value.senhaNova;
    const confirmPassword = event.target.value;
    console.log(event.target.value)
    if(password != '' && confirmPassword != ''){
      if(password !== confirmPassword){
        this.error = true
      }else{
        this.error = false
      }
    };
  }

  patchPassword(){
    if(this.formSenha.invalid){
      this.formSenha.markAllAsTouched()
      return
    }

    const password = this.formSenha.value;
    const context = 'users/password';

    this.http.patch(environment.apiUrl + context, { usuarioId: this.session.user.id, ...password }).subscribe((r:any) =>
      {Swal.fire({
      icon: 'success',
      title: 'Senha alterada com sucesso!'
      })
      this.modal.close()
    },
    (error) => {
      Swal.fire({
        // icon: 'error',
        // title: 'Erro ao alterar senha!'
        title: error.error.message,
        icon: 'error',
        timer: 10000
      })
      this.modal.close()
    }
    );
    return
  }

  cancelar(){
    this.modal.close();
  }

}
