import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { RouterModule } from '@angular/router';
import { DgCrudModule } from '@datagrupo/dg-crud';
import { CdkDynamicGroupModule, CdkDynamicTableModule, DgModalModule, DgPaginatorModule, DgTableModule } from '@datagrupo/dg-ng-util';
import { NgxMaskModule } from 'ngx-mask';
import { AppMaterialModule } from 'src/app/shared/app-material/app-material.module';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { CotacaoReInsertEditComponent } from './cotacao-re-insert-edit/cotacao-re-insert-edit.component';
import { CotacaoReMainComponent } from './cotacao-re-main/cotacao-re-main.component';



@NgModule({
  declarations: [
    CotacaoReInsertEditComponent,
    CotacaoReMainComponent
  ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    ReactiveFormsModule,
    MatIconModule,
    FormsModule,
    MatSnackBarModule,
    NgxMaskModule.forChild(),
    DgCrudModule,
    AppMaterialModule,
    UiModule,
    RouterModule,
    DgTableModule,
    CdkDynamicTableModule,
    CdkDynamicGroupModule,
    DgPaginatorModule,
    DgModalModule
  ]
})
export class CotacaoReModule { }
