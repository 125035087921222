<div class="container">
  <div class="row">
    <h3 class="text-align-center home-title ff-gotham-bold">Faça a cotação de um dos nossos seguros:</h3>
  </div>
  <div class="row  d-flex justify-content-center mt-5">
    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 mt-md-2">
      <div class="card mx-4 home-card card-shadow">
        <img class="card-img-top" src="../../../../assets/images/rd-seguros.jpg" alt="Imagem de capa do card">
        <div class="card-body">
          <h5 class="card-title ff-gotham-bold">Seguro RD Equipamentos</h5>
          <p class="card-text ff-gotham-light pb-2">Uma vez instalado o painel solar, este seguro cobre danos diversos ao Kit do cliente final ,como incêndio queda de raio, granizo, e outros eventos climáticos. Opcionalmente, é possível cobrir ainda danos elétricos e roubo/furto.</p>
        </div>
        <div class="card-footer btn-home pt-1 mt-2">
          <a href="#" class="btn btn-principal" style="margin-top: 0.7rem;" routerLink="/user/rd/novo">Fazer cotação</a>
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 mt-md-2">
      <div class="card mx-4 home-card card-shadow">
        <img class="card-img-top" src="../../../../assets/images/re-seguros.jpg" alt="Imagem de capa do card">
        <div class="card-body">
          <h5 class="card-title ff-gotham-bold">Seguro Risco Engenharia</h5>
          <p class="card-text ff-gotham-light">Este seguro irá cobrir danos ao kit fotovoltaico durante a sua instalação, como quedas, vendaval, granizo, incêndio, roubo, furto entre outros.
            <br>Pode ser contratado por instalação ou através de uma apólice anual que cubra todas as suas obras!</p>
        </div>
        <div class="card-footer btn-home">
          <button href="#" class="btn btn-principal" style="text-transform: uppercase;" routerLink="/user/re/novo">Fazer cotação</button>
        </div>
      </div>
    </div>
  </div>
</div>

