import { MenuListSidebarMenu } from "@datagrupo/dg-ng-util";

export interface InterfMenuList {
  hrStart?: boolean,
  hrEnd?: boolean,
  menuList: MenuListSidebarMenu[]
}

export const adminMenuList: InterfMenuList[] = [
  {
    hrStart: true,
    hrEnd:true,
    menuList: [
    { icon: 'account_circle', url: '/user/profile' , nome: 'Minha Conta' },

    { icon: 'grading', nome: 'Cotações', subMenu:[
      {icon: '', nome: 'Riscos Diversos', url: '/user/cotacoes'},
      {icon: '', nome: 'Risco Engenharia', url: '/user/cotacaoRE'},
    ]},
    { icon: 'note_add', url: '/user/home', nome: 'Nova Cotação' }
  ]
},
{
  menuList: [
    { icon: 'groups', url: '/user/usuarios' , nome: 'Usuários' },
    { icon: 'co_present', url: '/user/produtor' , nome: 'Produtor' }
  ]
}
]
