import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContactSuccessComponent } from './contact-user/contact-success/contact-success.component';
import { ContactUserComponent } from './contact-user/contact-user.component';
import { CotacaoReInsertEditComponent } from './cotacao-re/cotacao-re-insert-edit/cotacao-re-insert-edit.component';
import { CotacaoReMainComponent } from './cotacao-re/cotacao-re-main/cotacao-re-main.component';
import { CotacaoInsertEditComponent } from "./cotacao/cotacao-insert-edit/cotacao-insert-edit.component";
import { CotacaoMainComponent } from './cotacao/cotacao-main/cotacao-main.component';
import { HomeUserComponent } from './home-user/home-user.component';
import { ProdutorInsertEditComponent } from "./perfis/produtor/produtor-insert-edit/produtor-insert-edit.component";
import { ProdutorComponent } from './perfis/produtor/produtor-main/produtor.component';
import { ProfileUserComponent } from './profile-user/profile-user.component';
import { SolAgoraMainComponent } from './sol-agora/sol-agora-main/sol-agora-main.component';
import { UsuarioInsertEditComponent } from "./usuarios/usuario-insert-edit/usuario-insert-edit.component";
import { UsuariosMainComponent } from './usuarios/usuarios-main/usuarios-main.component';


const routes: Routes = [
  {
    path: 'home',
    component: HomeUserComponent
  },
  {
    path: 'rd/:id',
    component: CotacaoInsertEditComponent
  },
  {
    path: 're/:id',
    component:CotacaoReInsertEditComponent,

  },
  {
    path: 'profile',
    component: ProfileUserComponent,
  },
  {
    path: 'cotacoes',
    component: CotacaoMainComponent,
  },
  {
    path: 'cotacaoRE',
    component: CotacaoReMainComponent,
  },
  {
    path: 'solAgora',
    component: SolAgoraMainComponent,
  },

  {
    path: 'usuarios',
    component: UsuariosMainComponent,
  },
  {
    path: 'usuarios',
    component: UsuarioInsertEditComponent,
    children: [
      { path: ':id', component: UsuarioInsertEditComponent, }
    ]
  },
  {
    path: 'produtor',
    component: ProdutorComponent,
  },
  {
    path: 'produtor',
    component: ProdutorInsertEditComponent,
    children: [
      { path: ':id', component: ProdutorInsertEditComponent }
    ]
  },
  {
    path: 'contact',
    component: ContactUserComponent
  },
  {
    path: 'success',
    component: ContactSuccessComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule { }
