

export class CoveragesEntity {

  constructor(data: {
    id?: number | string,
    additional?: any[],
    required?: {code: string}[],
  }) {
    this.id = data?.id;
    this.additional = Array.isArray(data?.additional) ? data.additional : [];
    this.required = Array.isArray(data?.required) ? data.required : [{code: "CP0014"}];
  }

  id: number | string | undefined;
  additional:any [] = [{code: "CP0028"}, {code: "CP0008"}]
  required: {code: string}[] = [{code: "CP0014"}];
}
