<div class="container-fluid">
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 d-flex justify-content-end ">
      <button routerLink="/user/re/novo" class="btn btn-principal ff-gotham-bold button-NovoPedido">
        <mat-icon class="pt-1">add_circle_outline</mat-icon>
        Novo Pedido de Cotação
      </button>
    </div>
  </div>
</div>
<br>
<div class="container-fluid pb-5">
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
      <div class="card card-meusSeguros">
        <div class="card-header ff-gotham-bold header-meusSeguros">
          <h4 class="card-title">Pedidos de emissão do Seguro Risco Engenharia</h4>
        </div>

        <div class="card-body tbl1">
            <dg-table  dg-dynamic-table [dynamicTable]="table"
            class="tbl2"
            classes="tbl3 crud-table" >
            </dg-table>
          </div>
        <div class="card-footer justify-content-center d-inline-flex">
            <dg-paginator dg-dynamic-paginator [dynamicTable]="table"></dg-paginator>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="loader" *ngIf="showLoader">
  <div class="content-loader">
    <mat-spinner></mat-spinner>
  </div>
</div>
