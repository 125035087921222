import { HttpResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient } from '@datagrupo/dg-crud';
import { CdkDynamicTable, CdkDynamicTableService } from '@datagrupo/dg-ng-util';
import { SessionService } from 'src/app/core/service/session/session.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { CotacaoEntity } from '../entitys/cotacao.entity';


@Component({
  selector: 'app-cotacoes-geradas-main',
  templateUrl: './cotacao-main.component.html',
  styleUrls: ['./cotacao-main.component.scss']
})
export class CotacaoMainComponent implements OnInit, OnDestroy {

  public table: CdkDynamicTable.tableClass;
  public showLoader = false;
  public entity = new CotacaoEntity()
  public formFilters = new FormGroup({
    proposalNo: new FormControl(''),
    status: new FormControl(''),
    effectiveDate: new FormControl(''),
    expiryDate: new FormControl(''),
    personName: new FormControl(''),
    username: new FormControl(''),
    issueDateStart: new FormControl(''),
    issueDateEnd: new FormControl(''),
    produtor: new FormControl(''),
    certificate: new FormControl('')
  })

  constructor(
    private createTable: CdkDynamicTableService,
    private http: HttpClient,
    private router: Router,
    private session: SessionService
  ) {
    this.table = createTable.createByEntity(new CotacaoEntity(), {
      pipePagination: (p:any) => {
        console.log('pipePagination', p.page)
        return {
          page: p?.page?.number || 0,
          totalPages: p?.page?.totalPages || 0,
          totalElements: p?.page?.totalElements || 0
        };
      },
      filters: {filters: {}, group: "tableCotacao", reactive: false},
      actions: {
        add: {
          edit: {
            name: 'Editar',
            dbClick: true,
            action: (val: CotacaoEntity) => {
              router.navigate(['user', 'rd', val.id]).then()
            }
          },
          remove: {
            name: 'Excluir',
            permission: !!session.checkPerfil('ROLE_ADMINISTRADOR') ? true : false,
            action: (val: CotacaoEntity) => {
              Swal.fire({
                icon: 'question',
                title: 'Excluir Cotação',
                text: 'Deseja excluir essa cotação? Essa ação será permanente',
                showCancelButton: true,
                cancelButtonColor: 'red',
                showConfirmButton: true,
                confirmButtonColor: 'blue'
              }).then((opt=> {
                if(opt.isConfirmed){
                  this.http.delete(environment.apiUrl + 'akad/proposal/' + val.id).subscribe(
                    (resp) => {
                    Swal.fire({
                      icon: 'success',
                      title: 'Cotação excluída com sucesso!'
                    })
                this.table.controls.data.find()
              },
              () => {
                Swal.fire({
                  icon: 'error',
                  title: 'Erro ao excluir cotação.'
                })
              })
            }
          }))
        }
          }
        }
      }
    })
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.table.destroy()
  }

  dictionary(data: any[]) {
    return data;
  }

  baixarRelatorio() {
    const filterParams: { [key: string]: string } = {}
    const effectiveDate = this.formFilters.get('effectiveDate')?.value;
    if (effectiveDate !== null && effectiveDate !== undefined) {
      filterParams['effectiveDate'] = effectiveDate;
    }
    const expiryDate = this.formFilters.get('expiryDate')?.value;
    if (expiryDate !== null && expiryDate !== undefined) {
      filterParams['expiryDate'] = expiryDate;
    }
    const issueDateStart = this.formFilters.get('issueDateStart')?.value;
    if (issueDateStart !== null && issueDateStart !== undefined) {
      filterParams['issueDateStart'] = issueDateStart;
    }
    const issueDateEnd = this.formFilters.get('issueDateEnd')?.value;
    if (issueDateEnd !== null && issueDateEnd !== undefined) {
      filterParams['issueDateEnd'] = issueDateEnd;
    }
    const proposalNo = this.formFilters.get('proposalNo')?.value;
    if (proposalNo !== null && proposalNo !== undefined) {
      filterParams['proposalNo'] = proposalNo;
    }
    const status = this.formFilters.get('status')?.value;
    if (status !== null && status !== undefined) {
      filterParams['status'] = status;
    }
    const username = this.formFilters.get('username')?.value;
    if (username !== null && username !== undefined) {
      filterParams['username'] = username;
    }
    const personName = this.formFilters.get('personName')?.value;
    if (personName !== null && personName !== undefined) {
      filterParams['personName'] = personName;
    }
    const produtor = this.formFilters.get('produtor')?.value;
    if (produtor !== null && produtor !== undefined) {
      filterParams['produtor'] = produtor;
    }
    const certificate = this.formFilters.get('certificate')?.value;
    if (certificate !== null && certificate !== undefined) {
      filterParams['certificate'] = certificate;
    }

    const params = new URLSearchParams(filterParams);

    this.showLoader= true;

    this.http.get(environment.apiUrl + 'akad/proposal/generatebasicreport' + '?' + params.toString(), {responseType: 'arraybuffer', observe: 'response'}).subscribe((response: HttpResponse<ArrayBuffer>)=> {
      const arrayBuffer = response.body;
      if(arrayBuffer){
        const blob = new Blob([arrayBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a');
          a.href = url;
          a.download = 'Relatorio.xlsx';
          a.target = '_blank';
          a.click();
      }
      this.showLoader= false;
    },
    (e)=> {
      this.showLoader= false;
      Swal.fire({
        icon: 'error',
        title:'Erro ao baixar relatório.',
        text: 'Ocorreu um erro inesperado ao baixar o relatório. Tente novamente mais tarde!'
      })
    })
  }
}
