<div class="container-fluid cadastro p-5">
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col sm-12 d-flex justify-content-center">
      <div class="card col-xl-6 col-lg-6 col-md-10 col-sm-12" style="box-shadow: 3px 3px 3px  3px #cdcdcd;">
        <div class="card-header header d-flex justify-content-center">
          <h4 class="ff-gotham-bold title">
            Olá! Seja Bem-Vindo(a)!
          </h4>
        </div>
        <div class="card-body body-formulario d-flex justify-content-center flex-column">
          <div class="row">
            <p class="card-text text-formulario ff-gotham-light">Faça o seu cadastro:</p>
          </div>
          <br>
          <div class="row">
            <form [formGroup]="form">
              <div class="row d-flex justify-content-center pb-2">
                <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12">
                  <label for="username" class="form-label ff-gotham-light">
                    Nome Completo<small style="color:red;">*</small>
                  </label>
                  <input class="form-control input-principal " formControlName="username" type="text" id="username" name="username">
                  <div class="msg-error ff-gotham-medium"
                  *ngIf="form.get('username')?.errors
                  && form.get('username')?.touched">
                    Esse campo é obrigatório!!
                  </div>
                </div>
              </div>
              <div class="row d-flex justify-content-center pb-2">
                <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12">
                  <label for="cpf" class="form-label ff-gotham-light">CPF</label>
                  <input class="form-control input-principal" formControlName="cpf"
                  name="cpf" type="string" id="cpf" mask="000.000.000-00">
                  <div
                  class="msg-error ff-gotham-medium"
                  *ngIf="form.get('cpf')?.errors?.['required']
                  && form.get('cpf')?.touched">
                    Esse campo é obrigatório!!
                  </div>
                  <div
                  class="msg-error ff-gotham-medium"
                  *ngIf="form.get('cpf')?.errors?.['pattern']">
                    CPF não é válido!
                  </div>
                </div>
                <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12">
                  <label class="form-label ff-gotham-light" for="telefone">Telefone</label>
                  <input name="telefone" formControlName="telefone" id="telefone" type="tel" placeholder="(XX) XXXXX-XXXX" class="form-control input-principal" mask="(00) 0000-0000 ||(00) 00000-0000">
                  <div class="msg-error ff-gotham-medium"
                  *ngIf="form.get('telefone')?.errors?.['required']
                  && form.get('telefone')?.touched">
                    Esse campo é obrigatório!!
                  </div>
                  <div class="msg-error ff-gotham-medium"
                  *ngIf="form.get('telefone')?.errors?.['pattern']">
                    Telefone não é válido.
                  </div>
                </div>
              </div>
              <div class="row d-flex justify-content-center pb-2">
                <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12">
                  <label for="email" class="form-label ff-gotham-light">E-mail<small style="color:red;">*</small></label>
                  <input class="form-control input-principal " formControlName="email" type="email" id="email">
                  <div class="msg-error ff-gotham-medium"
                  *ngIf="form.get('email')?.errors?.['required']
                  && form.get('email')?.touched">
                    Esse campo é obrigatório!!
                  </div>
                  <div class="msg-error ff-gotham-medium"
                  *ngIf="form.get('email')?.errors?.['pattern']">
                    Esse e-mail não é válido!
                  </div>
                </div>
              </div>

              <div class="row d-flex justify-content-center pb-2">
                <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12">
                  <label for="password" class="form-label ff-gotham-light">Senha<small style="color:red;">*</small></label>
                  <div class="input-password">
                    <input class="form-control password input-principal" name="password" formControlName="password" type="password" id="password">
                    <span class="password-eye" (click)="mostrarPassword()">
                      <mat-icon *ngIf="mostrarSenha == true">visibility</mat-icon>
                      <mat-icon *ngIf="mostrarSenha == false">visibility_off</mat-icon>
                    </span>
                  </div>

                  <div class="msg-error ff-gotham-medium"
                  *ngIf="form.get('password')?.errors?.['required']
                  && form.get('password')?.touched">
                    Esse campo é obrigatório!!
                  </div>
                  <div class="msg-error ff-gotham-medium"
                  *ngIf="form.get('password')?.errors?.['minLength']">
                    A senha deve conter no mínimo 6 caracteres
                  </div>
                </div>
                <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12">
                  <label for="confirmPassword" class="form-label ff-gotham-light">Confirmar Senha<small style="color:red;">*</small></label>
                  <div class="input-password">
                    <input class="form-control confirmPassword input-principal" name="confirmPassword" formControlName="confirmPassword" type="password" id="confirmPassword" (change)="passwordMatchValidator($event)">
                    <span class="password-eye" (click)="mostrarConfirmPassword()">
                      <mat-icon *ngIf="mostrarConfirmSenha == true">visibility</mat-icon>
                      <mat-icon *ngIf="mostrarConfirmSenha == false">visibility_off</mat-icon>
                    </span>
                  </div>

                  <div class="msg-error ff-gotham-medium"
                  *ngIf="form.get('confirmPassword')?.errors?.['required']
                  && form.get('confirmPassword')?.touched">
                    Esse campo é obrigatório!!
                  </div>
                  <div class="msg-error ff-gotham-medium " *ngIf="!!error">
                  As senhas não coincidem.
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="card-footer footer-formulario d-flex justify-content-center">
          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <button
            class="btn btn-principal ff-gotham-bold botao-formulario m-2"
            (click)="signup()">
              Criar Conta
          </button>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <button
            (click)="cancelar()"
            class="btn btn-cancelar ff-gotham-bold botao-formulario m-2">
              Cancelar
          </button>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
