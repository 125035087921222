import { DgPaginationComponent } from './dg-pagination/dg-pagination/dg-pagination.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    DgPaginationComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule
  ],
  exports: [
    DgPaginationComponent
  ]
})
export class UiModule { }
