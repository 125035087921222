import {Component, OnInit} from '@angular/core';
import {CotacoesRd} from '../../cotacoes-rd.entity';
import {HttpClient, crudDispatchEvent} from '@datagrupo/dg-crud';
import {environment} from 'src/environments/environment';
import {Router} from '@angular/router';

@Component({
  selector: 'app-cotacoes-geradas-main',
  templateUrl: './cotacoes-geradas-main.component.html',
  styleUrls: ['./cotacoes-geradas-main.component.scss']
})
export class CotacoesGeradasMainComponent implements OnInit {

  public entity = new CotacoesRd()

  constructor(
    private http: HttpClient,
    private router: Router
  ) {
    this.entity.addActions([
      {
        name: 'editar', action: (val: CotacoesRd) => {
          router.navigate(['user', 'rd', val.id]).then()
        }
      },
      {
        name: 'excluir', action: (val: CotacoesRd) => {
          // crudDispatchEvent('relatorioRd')
          this.http.delete(environment.apiUrl + 'akad/proposal/' + val.id).subscribe((r) => {

            console.log('resp', r)
            crudDispatchEvent('relatorioRd')
          })
        }, permission: true
      },
    ])
  }

  ngOnInit(): void {
  }

  dictionary(data: any[]) {
    return data;
  }
}
