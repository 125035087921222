import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from "@angular/material/stepper";
import { RouterModule } from '@angular/router';
import { DgCrudModule } from '@datagrupo/dg-crud';
import { CdkDynamicGroupModule, CdkDynamicTableModule, DgModalModule, DgPaginatorModule, DgTableModule } from '@datagrupo/dg-ng-util';
import { NgxMaskModule } from "ngx-mask";
import { AppMaterialModule } from 'src/app/shared/app-material/app-material.module';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { CotacaoInsertEditComponent } from './cotacao-insert-edit/cotacao-insert-edit.component';
import { CotacaoMainComponent } from './cotacao-main/cotacao-main.component';


@NgModule({
  declarations: [
    CotacaoInsertEditComponent,
    CotacaoMainComponent
  ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    ReactiveFormsModule,
    MatIconModule,
    FormsModule,
    MatSnackBarModule,
    NgxMaskModule.forChild(),
    DgCrudModule,
    AppMaterialModule,
    UiModule,
    RouterModule,
    DgTableModule,
    DgModalModule,
    CdkDynamicTableModule,
    CdkDynamicGroupModule,
    DgPaginatorModule
  ]
})
export class CotacaoModule {
}
