import { AbstractEntity, DataServer } from "@datagrupo/dg-crud";
import { DynamicColumn, DynamicTableEntity } from "@datagrupo/dg-ng-util";
import { environment } from "src/environments/environment";
import { InsuredEntity } from "./insured.entity";

@DataServer({
  path: environment.apiUrl,
  context: 'cotacaore'
})

@DynamicTableEntity({
  api:{
    path: environment.apiUrl,
    context: 'cotacaore'
  },
})

export class CotacaoReEntity extends AbstractEntity {
  constructor(
    id?: number | string,
    name?: string,
    document?: number | string,
    productType?: string | any ,
    productBranch?: string | any,
    typeWork?: string,
    effectiveDate?: string,
    expiryDate?: string,
    equipmentAmount?: string | number,
    addInformation?: boolean,
    client?: InsuredEntity,
    installment?: number | string,
    usuarioInclusao?: string,
    dataInclusao?: string,
    netPremium?: string,
    totalPremium?: string,
    iof?: string,
    cb?: string,  //cobertura básica
    cd?: string,  //cobertura de danos
    ddl?: string,  // despesas com desentulho do local
    dcss?: string, // despesas de contenção e salvamento de sinistros
    hp?: string, // honorários de peritos
    rcgc?: string,  // responsabilidade civil geral e cruzada
    rcf?: string,  // responsabilidade civil fundações
    rcep?: string, // responsabilidade civil erro de projeto
    rce?: string,  // responsabilidade civil empregador
    dm?: string // danos morais

  ){
    super()
    this.id = id,
    this.name = name;
    this.document = document;
    this.productType = productType,
    this.productBranch = productBranch,
    this.typeWork = typeWork,
    this.effectiveDate = effectiveDate,
    this.expiryDate = expiryDate,
    this.equipmentAmount = equipmentAmount,
    this.addInformation = addInformation,
    this.client = !!client ? client : new InsuredEntity({}),
    this.installment = installment
    this.usuarioInclusao = usuarioInclusao
    this.dataInclusao = dataInclusao;
    this.netPremium = netPremium;
    this.totalPremium = totalPremium;
    this.iof = iof;
    this.cb = cb;
    this.cd = cd;
    this.ddl = ddl;
    this.dcss = dcss;
    this.hp = hp;
    this.rcgc = rcgc;
    this.rcf = rcf;
    this.rcep = rcep;
    this.rce = rce;
    this.dm = dm;
  }

  @DynamicColumn({headerName: 'Cliente', resource: (cel: string, row: any) => {
    return row.insured || '--'
  }})
  public name: string | undefined
  @DynamicColumn({headerName: 'Documento', resource: (val: string, row: any) => {
    if(!row.document) return '--'
    val = String(row.document).replace(/\D/g, "")
    if(val.length == 11) return val.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/,"$1.$2.$3-$4")
      return val.replace(/(\d{2})(\d{3})(\d{3})(\d{3})(\d{2})/,"$1.$2.$3/$4-$5")
  }})
  public document: string | number | undefined
  @DynamicColumn({headerName:'Telefone', resource: (val: string, row: any) => {
    if(!row.mobile) return '--'
    val = String(row.mobile).replace(/\D/g, "")
    if(val.length == 11) return val.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/,"($1) $2 $3-$4")
    return val.replace(/(\d{2})(\d{4})(\d{4})/,"($1) $2-$3")
  }})
  public mobile: number | string | undefined;
@DynamicColumn({headerName:'E-mail', resource: (val:string)=> {
  return val || '--'
}})
public email: string | any
@DynamicColumn({headerName: 'Valor Total (R$)', resource: (val: string)=> {
  if(!val) return '--'
    const valor = parseFloat(val.replace(/[^\d.,]/g, '').replace('.', '').replace(',', '.'));
    return  new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL', minimumFractionDigits: 2}).format(valor).toString();
}})
public equipmentAmount: string | number | undefined
@DynamicColumn({headerName: 'Início de Vigência', resource: (val:any) => {
  if(!val) return '--'
  return  new Date(val).toLocaleString('pt-br', {timeZone: 'UTC'}).slice(0, 10);
}})
public effectiveDate: string | undefined
@DynamicColumn({headerName: 'Fim de Vigência', resource: (val:any) => {
  if(!val) return '--'
  return  new Date(val).toLocaleString('pt-br', {timeZone: 'UTC'}).slice(0, 10);
}})
public expiryDate: string | undefined
@DynamicColumn({headerName: 'Data da Criação', resource: (val:any)=> {
  if(!val) return '--'
  return  new Date(val).toLocaleString('pt-br', {timeZone: 'UTC'}).slice(0, 10);
}})
public dataInclusao: string | undefined
@DynamicColumn({headerName: 'Produtor'})
public produtor: string | undefined
@DynamicColumn({headerName: 'Usuário'})
public usuarioInclusao: string | undefined



    public client: InsuredEntity

    public productType: string | any
    public productBranch: string | any
    public typeWork: string | undefined
    public addInformation: boolean | undefined
    public installment: number | string | undefined
    public insured: string | undefined
    public dateOfBirth: string | any
    public postCode: string | any
    public street: string | any
    public number: string | any
    public complement: string | any
    public state: string | any
    public city: string | any
    public suburb: string | any
    public postCodeBilling: string | any
    public streetBilling: string | any
    public numberBilling: string | any
    public complementBilling: string | any
    public stateBilling: string | any
    public cityBilling: string | any
    public suburBilling: string | any
    public netPremium: string | any
    public totalPremium: string | any
    public iof: string | any
    public cb: string | any
    public cd: string | any
    public ddl: string | any
    public dcss: string | any
    public hp: string | any
    public rcgc: string | any
    public rcf: string | any
    public rcep: string | any
    public rce: string | any
    public dm: string | any

}
