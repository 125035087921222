import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CotacoesGeradasMainComponent } from './cotacoes-geradas-main/cotacoes-geradas-main/cotacoes-geradas-main.component';
import { DgCrudModule } from '@datagrupo/dg-crud';
import { AppMaterialModule } from 'src/app/shared/app-material/app-material.module';
import { UiModule } from 'src/app/shared/ui/ui.module';
import {RouterModule} from "@angular/router";



@NgModule({
  declarations: [
    CotacoesGeradasMainComponent
  ],
    imports: [
        CommonModule,
        DgCrudModule,
        AppMaterialModule,
        UiModule,
        RouterModule
    ]
})
export class CotacoesGeradasModule { }
