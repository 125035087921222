import { environment } from './../../environments/environment';
import { ConfigDgCrudService } from './../core/config/config-dg-crud/config-dg-crud.service';
import { Injectable } from '@angular/core';
import { AbstractHttpService } from '@datagrupo/dg-crud';

@Injectable({
  providedIn: 'root'
})
export class GenericHttpService extends AbstractHttpService<any> {

  constructor(
    public conf: ConfigDgCrudService
  ) {
    super(conf, environment.apiUrl, '')
  }
}
