import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DgSidebarMenuComponent } from "@datagrupo/dg-ng-util";
import { SessionService } from "../../../../core/service/session/session.service";

@Component({
  selector: 'private-header',
  templateUrl: './private-header.component.html',
  styleUrls: ['./private-header.component.scss']
})
export class PrivateHeaderComponent implements OnInit {

  @Output() changeSidebar = new EventEmitter<any>()

  constructor(
    public session: SessionService
  ) { }

  ngOnInit(): void {
  }

  sidebarChange() {
    this.changeSidebar.emit();
  }

  sidebaOpened() {
    return DgSidebarMenuComponent.open
  }

  // sair() {
  //   if (confirm('sair do sistema?')) {
  //     this.session.logout()
  //   }
  // }

  openNewsletter() {
    window.dispatchEvent(new CustomEvent('event-open-newsletter'));
  }

  openLix() {
    window.dispatchEvent(new CustomEvent('event-open-lix'));
  }

  openModalSearch() {
    window.dispatchEvent(new CustomEvent('open-modal-search-sistem'))
  }
}
