<div class="row">
  <div class="container-fluid d-flex justify-content mb-5">
    <div class="col-12 d-flex justify-content-center">
      <div class="row">
        <div class="container d-flex justify-content-center mx-3">
          <div class="card col-12 contact pb-3 m-3">
            <div class="card-header contact-header ">
              <h3 class="card-title title ff-gotham-bold">Entre em contato com a gente!</h3>
              <p class="card-subtitle subtitle ff-gotham-light ">
                Conte-nos a sua demanda que nossos consultores entrarão em contato para te atender.
              </p>
            </div>
            <div class="card-body">
              <form [formGroup]="form">
                <div class="row item-form">
                  <div class="col-6">
                    <div class="col-12">
                      <label for="name" class="form-label ff-gotham-light">Nome Completo</label>
                      <input formControlName="name" type="name" class="form-control input-secundario ff-gotham-light" id="name">
                      <div class="col-12 error-msg"
                    *ngIf="form.get('name')?.errors
                    && form.get('name')?.touched">
                      Esse campo é obrigatório!!
                    </div>
                    </div>
                    <div class="col-12">
                      <label for="email" class="form-label ff-gotham-light">Email</label>
                      <input formControlName="email" type="email" class="form-control input-secundario ff-gotham-light" id="email">
                      <div class="col-12 error-msg"
                    *ngIf="form.get('email')?.errors?.['required']
                    && form.get('email')?.touched">
                      Esse campo é obrigatório!!
                    </div>
                    <div class="col-12 error-msg"
                    *ngIf="form.get('email')?.errors?.['pattern']">
                      Esse e-mail não é válido!
                    </div>
                    </div>
                    <div class="col-8">
                      <label for="telefone" class="form-label ff-gotham-light">Telefone</label>
                      <input formControlName="telefone" type="tel" class="form-control input-secundario ff-gotham-light" id="telefone" placeholder="(XX) XXXXX-XXXX" mask="(00) 0000-0000 ||(00) 00000-0000">
                      <div class="col-12 error-msg"
                    *ngIf="form.get('telefone')?.errors?.['required']
                    && form.get('telefone')?.touched">
                      Esse campo é obrigatório!!
                    </div>
                    <div class="col-12 error-msg"
                    *ngIf="form.get('telefone')?.errors?.['pattern']">
                      Telefone não é válido.
                    </div>
                    </div>
                  </div>
                  <div class="col-6 ">
                    <label for="mensagem" class="form-label ff-gotham-light">Mensagem</label>
                    <textarea formControlName="mensagem" id="mensagem" cols="30" rows="8" class="form-control ff-gotham-light input-secundario" placeholder="Escreva sua mensagem aqui...">Escreva sua mensagem aqui...</textarea>
                    <div class="col-12 error-msg"
                    *ngIf="form.get('mensagem')?.errors
                    && form.get('mensagem')?.touched">
                      Esse campo não pode ser vazio!
                    </div>
                  </div>
                </div>
                <div class="card-footer contact-footer">
                  <button class="btn btn-principal ff-gotham-bold btn-form m-2" (click)="enviar()">Enviar</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
