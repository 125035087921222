import { AbstractEntity, DataServer } from "@datagrupo/dg-crud";
import { DynamicColumn, DynamicTableEntity } from "@datagrupo/dg-ng-util";
import { environment } from "src/environments/environment";
import { PaymentEntity } from '../../cotacao/entitys/payment.entity';
import { PaymentSAEntity } from "./payment-solAgora.entity";
import { PersonSAEntity } from "./person-solAgora.entity";

@DataServer({
  path: environment.apiUrl,
  context: 'akad/solagora/proposal'
})
@DynamicTableEntity({
  api:{
    path: environment.apiUrl,
    context: 'akad/solagora/proposal'
  }
})
export class SolAgoraEntity extends AbstractEntity{
  constructor(
    id?: number | string,
    totalEquipmentAmount?: any,
    status?: string,
    proposalNo?: string,
    effectiveDate?: string,
    expiryDate?: string,
    name?: string,
    document?: number | string,
    mobile?: number | string,
    email?: string,
    certificate?: string,
    netPremium?: any,
    vat?: any,
    premium?: any,
    policyId?: string,
    dataInclusao?: string,
    produtor?: string,
    person?: PersonSAEntity,
    payment?: PaymentEntity,
    paymentCode?: string
  ){
    super();
    this.id = id;
    this.totalEquipmentAmount = totalEquipmentAmount;
    this.status = status;
    this.proposalNo = proposalNo;
    this.effectiveDate = effectiveDate;
    this.expiryDate = expiryDate;
    this.name = name;
    this.document = document;
    this.mobile = mobile;
    this.email = email;
    this.certificate = certificate;
    this.dataInclusao = dataInclusao;
    this.produtor = produtor;
    this.netPremium = netPremium;
    this.vat = vat;
    this.premium = premium;
    this.policyId = policyId;
    this.person = !!person ? person : new PersonSAEntity({});
    this.paymentCode = paymentCode;
    this.payment = payment;
  }

  @DynamicColumn({headerName: 'Cliente', resource: (cel: string, row: any) => {
    return row.personName || '--';
    }
  })
  public name: string|undefined;
  @DynamicColumn({headerName:'Documento', resource: (val: string, row: any) => {
    if(!row.personDocument) return '--'
    val = String(row.personDocument).replace(/\D/g, "")
    if(val.length == 11) return val.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/,"$1.$2.$3-$4")

    return val.replace(/(\d{2})(\d{3})(\d{3})(\d{3})(\d{2})/,"$1.$2.$3/$4-$5")
  }})
  public document: number | string | undefined;

  @DynamicColumn({headerName:'Telefone', resource: (val: string, row: any) => {
    if(!row.personMobile) return '--'
    val = String(row.personMobile).replace(/\D/g, "")
    if(val.length == 11) return val.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/,"($1) $2 $3-$4")

    return val.replace(/(\d{2})(\d{4})(\d{4})/,"($1) $2-$3")

  }})

  @DynamicColumn({headerName:'Email', resource: (cel: string, row: any) => {
    return row.personEmail || '--'
  }})
  public email:  string | undefined;
  @DynamicColumn({headerName:'Valor Total (R$)', resource: (val: string) => {
    if(!val) return '--'
    return  Number(val).toLocaleString("BRL", {maximumFractionDigits: 2, minimumFractionDigits: 2});
  }})
  public totalEquipmentAmount: number | string | undefined;
  @DynamicColumn({headerName: 'Nº Proposta'})
  public proposalNo: string | undefined;
  @DynamicColumn({headerName: 'Nº Apólice', resource: (val: string) => {
    if(!val) return '--'
    return val
  }})
  public certificate: string | undefined;
  @DynamicColumn({headerName: 'Inicio de Vigência', resource: (val: any) => {
    if(!val) return '--'
    return  new Date(val).toLocaleString('pt-br', {timeZone: 'UTC'}).slice(0, 10);
  }})
  public effectiveDate: string | undefined;
  @DynamicColumn({headerName: 'Fim de Vigência', resource: (val: any) => {
    if(!val) return '--'
    return  new Date(val).toLocaleString('pt-br', {timeZone: 'UTC'}).slice(0, 10);
  }})
  public expiryDate: string | undefined;
  @DynamicColumn({headerName: 'Status', resource: (val: any) => {
    if(val == '0') return 'Em Cotação'
    if(val == 'NotEffective') return 'Não Efetivado'
    if(val == 'Effective') return 'Efetivado'
    return '--'
  }})
  public status: string | undefined;
  @DynamicColumn({headerName: 'Data da Criação', resource: (val: any) => {
    if(!val) return '--'
    return  new Date(val).toLocaleString('pt-br').slice(0, 10);
  }})
  public dataInclusao: string | undefined
  @DynamicColumn({headerName: 'Produtor'})
  public produtor: string | undefined


  public netPremium: any | undefined;
  public vat: any | undefined;
  public premium: any | undefined;
  public termos: boolean | undefined
  public policyId: string | undefined;
  public paymentCode: string | undefined;

  public payment: PaymentSAEntity | undefined;
  public person: PersonSAEntity;
  public billetUrl: string | undefined;
  public personName: string | undefined;
  public personEmail: string | undefined;
  public totalPremium: string | undefined;
  public personDocument: string | undefined;
  public personMobile: string | undefined;
  public cep: string | undefined;
  public billingCep: string | undefined;
  public city: string | undefined;
  public billingCity: string | undefined;
  public street: string | undefined;
  public billingStreet: string | undefined;
  public state: string | undefined;
  public billingState: string | undefined;
  public complement: string | undefined;
  public billingComplement: string | undefined;
  public number: string | undefined;
  public billingNumber: string | undefined;
  public suburb: string | undefined;
  public billingSuburb: string | undefined;
  public payer: string | undefined;
  public payerDocument: string | undefined;
  public payerEmail: string | undefined;
  public dateOfBirth: string | undefined
  public mobile: number | string | undefined;
  public installment: string | undefined;
  public installmentDate: string | undefined;
  public installmentValue: string | undefined;
  public payModeCode: string | undefined;
  public personDocumentType: string | undefined;



}
