import { AppMaterialModule } from './../../../shared/app-material/app-material.module';
import { UiModule } from './../../../shared/ui/ui.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsuariosMainComponent } from './usuarios-main/usuarios-main.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DgCrudModule } from '@datagrupo/dg-crud';
import {CdkDynamicGroupModule, CdkDynamicTableModule, DgAutocompleteModule, DgModalModule, DgPaginatorModule, DgTableModule} from '@datagrupo/dg-ng-util';
import { UsuarioInsertEditComponent } from './usuario-insert-edit/usuario-insert-edit.component';
import {RouterModule} from "@angular/router";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';



@NgModule({
  declarations: [
    UsuariosMainComponent,
    UsuarioInsertEditComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DgCrudModule,
    UiModule,
    DgModalModule,
    AppMaterialModule,
    DgAutocompleteModule,
    RouterModule,
    MatProgressSpinnerModule,
    DgTableModule,
    CdkDynamicTableModule,
    CdkDynamicGroupModule,
    DgPaginatorModule
  ]
})
export class UsuariosModule { }
