import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { formata_data_utc_br } from 'src/app/helpers/helpers';
import { environment } from 'src/environments/environment';
import { ApiAkadService } from "../../../../services/api-akad/api-akad.service";
import { CotacaoReEntity } from '../../cotacao-re/entity/cotacaoRe.entity';

interface retornoViaCep {
  logradouro: string,
  bairro: string,
  cidade: string,
  uf: string,
  localidade: string,
  erro: boolean
}

@Injectable({
  providedIn: 'root'
})
export class CotacaoService {

  private constextsApi ={
    cotacaore: 'cotacaore'
  }

  constructor(
    public akadService: ApiAkadService,
    private http: HttpClient
  ) { }

  getCartaCotacao(proposalNo: string | undefined) {
    if (!proposalNo) return;
    this.akadService.generateDocument('quote', {proposalNo})
  }

  getCertificate(proposalNo: string | undefined, tohide: boolean, nomeArquivo: string){
    if(!proposalNo) return;
    this.akadService.generateDocument('certificate',{ proposalNo, tohide: tohide? 'paymentInfo' : '', nomeArquivo })
  }

  public ajustDateByInput(dataString: string) {
    var data = new Date(!!dataString ? dataString : '');

    var dia = data.getDate().toString(),
      diaF = (dia.length == 1) ? '0' + dia : dia,
      mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
      mesF = (mes.length == 1) ? '0' + mes : mes,
      anoF = data.getFullYear();
    return (String(anoF) == "1" ? "0001" : anoF) + "-" + mesF + "-" + diaF;
  }

  public ajustDateView(dataString?: string) {
    if(!dataString) return ''
    return formata_data_utc_br(dataString)
  }

  getCep(cep: string, callBackSuccess: (d: retornoViaCep) => void, callBackError?: Function) {
    if (cep.length < 8) return;

    return this.http.get<retornoViaCep>(`https://viacep.com.br/ws/${cep}/json/`).subscribe(
      resp => {
        if (resp.erro) {
          if (!!callBackError) {
            callBackError(true);
            return;
          }
        }

        callBackSuccess(resp)
      },
    )
  }

  cotacaoRe(entity: CotacaoReEntity){
    return this.http.post(environment.apiUrl + this.constextsApi.cotacaore, entity)
  }
}
