import { StartSessionComponent } from './pages/auth/start-session/start-session.component';
import { UserLayoutComponent } from './layouts/private-layout/user-layout/user-layout.component';
import { PublicLayoutComponent } from './layouts/public-layout/public-layout/public-layout.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SessionGuard } from './core/guards/session/session.guard';
import { StartSessionGuard } from './core/guards/start-session/start-session.guard';


const routes: Routes = [
  {
    path: 'user',
    component: UserLayoutComponent,
    loadChildren: () =>
      import('./pages/user/user.module').then((u) => u.UserModule),
    canActivate: [SessionGuard]
  },
  {
    path: 'auth/start-session',
    component: StartSessionComponent,
    canActivate: [StartSessionGuard]
  },
  {
    path: '',
    component: PublicLayoutComponent,
    loadChildren: () =>
      import('./pages/public/public.module').then((p) => p.PublicModule)
  },
  {
    path: '**',
    redirectTo: 'login',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
