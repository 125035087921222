import { environment } from './../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractTokenService } from '@datagrupo/dg-ng-util';

interface token{
  id?: number,
  idEmpresa?: number,
  Username?: string,
  email?: string,
  empresa?: string
}

@Injectable({
  providedIn: 'root'
})
export class TokenService extends AbstractTokenService<token> {

  path_refreshToken = environment.apiUrl + 'auth/refresh';

  constructor(
    private http: HttpClient
  ) {
    super(http);
  }
}
