import { AbstractEntity, DataServer } from "@datagrupo/dg-crud";
import { DynamicColumn, DynamicTableEntity } from "@datagrupo/dg-ng-util";
import { environment } from "src/environments/environment";
import { PaymentEntity } from "./payment.entity";
import { PersonEntity } from "./person.entity";
import { RiskAnalysisEntity } from "./risk-analysis.entity";

@DataServer({
  path: environment.apiUrl,
  context: 'akad/proposal'
})
@DynamicTableEntity({
  api:{
    path: environment.apiUrl,
    context: 'akad/proposal'
  },
})
export class CotacaoEntity extends AbstractEntity {

  constructor(
    id?: number | string,
    totalEquipmentAmount?: any,
    status?: string,
    proposalNo?: string,
    effectiveDate?: string,
    expiryDate?: string,
    name?: string,
    document?: number | string,
    mobile?: number | string,
    email?: string,
    certificate?: string,
    netPremium?: any,
    vat?: any,
    premium?: any,
    termos?: boolean,
    policyId?: string,
    paymentCode?: string,
    riskAnalysis?: RiskAnalysisEntity[],
    commissionRate?: number,
    person?: PersonEntity,
    payment?: PaymentEntity,
    dataInclusao?: string,
    produtor?: string,
    apelido?: string,
    usuarioInclusao?: string,
    lucrosCessantes?: boolean,
    alagamentos?: boolean
  ){
    super()
    this.id = id;
    this.totalEquipmentAmount = totalEquipmentAmount;
    this.status = status;
    this.proposalNo = proposalNo;
    this.effectiveDate = effectiveDate;
    this.expiryDate = expiryDate;
    this.name = name;
    this.document = document;
    this.mobile = mobile;
    this.email = email;
    this.certificate = certificate;
    this.dataInclusao = dataInclusao;
    this.usuarioInclusao = usuarioInclusao
    this.produtor = produtor;
    this.apelido = apelido;
    this.netPremium = netPremium;
    this.vat = vat;
    this.premium = premium;
    this.policyId = policyId;
    this.termos = !!termos;
    this.person = !!person ? person : new PersonEntity({})

    this.riskAnalysis = Array.isArray(riskAnalysis) ? riskAnalysis : [];

    // estaticos e exclusivos da akad, não sendo salvos no nosso servidor
    this.productVersion = "1.0"
    this.commissionRate = !!commissionRate ? commissionRate : 0.2;
    this.paymentCode = paymentCode
    this.payment = payment
    this.lucrosCessantes = lucrosCessantes
    this.alagamentos = alagamentos
  }

  @DynamicColumn({headerName: 'Cliente', resource: (cel: string, row: any) => {
    return row.personName || '--';
    }
  })
  public name: string|undefined;
  @DynamicColumn({headerName:'Documento', resource: (val: string, row: any) => {
    // return row.person?.document || '--';
    if(!row.personDocument) return '--'
    val = String(row.personDocument).replace(/\D/g, "")
    if(val.length == 11) return val.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/,"$1.$2.$3-$4")

    return val.replace(/(\d{2})(\d{3})(\d{3})(\d{3})(\d{2})/,"$1.$2.$3/$4-$5")
  }})
  public document: number | string | undefined;
  @DynamicColumn({headerName:'Telefone', resource: (val: string, row: any) => {
    if(!row.personMobile) return '--'
    val = String(row.personMobile).replace(/\D/g, "")
    if(val.length == 11) return val.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/,"($1) $2 $3-$4")

    return val.replace(/(\d{2})(\d{4})(\d{4})/,"($1) $2-$3")

  }})
  public mobile: number | string | undefined;
  @DynamicColumn({headerName:'Email', resource: (cel: string, row: any) => {
    return row.personEmail || '--'
  }})
  public email:  string | undefined;
  @DynamicColumn({headerName:'Valor Total (R$)', resource: (val: string) => {
    if(!val) return '--'
    return  Number(val).toLocaleString("BRL", {maximumFractionDigits: 2, minimumFractionDigits: 2});
  }})
  public totalEquipmentAmount: number | string | undefined;
  @DynamicColumn({headerName: 'Nº Proposta'})
  public proposalNo: string | undefined;
  @DynamicColumn({headerName: 'Nº Apólice', resource: (val: string) => {
    if(!val) return '--'
    return val
  }})
  public certificate: string | undefined;
  @DynamicColumn({headerName: 'Inicio de Vigência', resource: (val: any) => {
    if(!val) return '--'
    return  new Date(val).toLocaleString('pt-br', {timeZone: 'UTC'}).slice(0, 10);
  }})
  public effectiveDate: string | undefined;
  @DynamicColumn({headerName: 'Fim de Vigência', resource: (val: any) => {
    if(!val) return '--'
    return  new Date(val).toLocaleString('pt-br', {timeZone: 'UTC'}).slice(0, 10);
  }})
  public expiryDate: string | undefined;
  @DynamicColumn({headerName: 'Status', resource: (val: any) => {
    if(val == '0') return 'Em Cotação'
    if(val == 'NotEffective') return 'Não Efetivado'
    if(val == 'Effective') return 'Efetivado'
    return '--'
  }})
  public status: string | undefined;
  @DynamicColumn({headerName: 'Data da Criação', resource: (val: any) => {
    if(!val) return '--'
    return  new Date(val).toLocaleString('pt-br').slice(0, 10);
  }})
  public dataInclusao: string | undefined
  @DynamicColumn({headerName: 'Produtor'})
  public produtor: string | undefined
  @DynamicColumn({headerName: 'Usuário'})
  public usuarioInclusao: string | undefined

  public billetUrl: string | undefined;

  public netPremium: any | undefined;
  public vat: any | undefined;
  public premium: any | undefined;
  public termos: boolean | undefined
  public policyId: string | undefined;
  public paymentCode: string | undefined;

  public payment: PaymentEntity | undefined;
  public person: PersonEntity;
  public riskAnalysis: RiskAnalysisEntity[];

  // importando outras variaveis
  public commissionRate: number | undefined;
  public apelido: string | undefined;

  public usuarioInclusaoId: number | undefined;
  public lucrosCessantes: boolean | undefined
  public alagamentos: boolean | undefined

  // estaticos e exclusivos da akad, não sendo salvos no nosso servidor
  productVersion: string = "1.0"
}
