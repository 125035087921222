import { UserService } from './../../../services/user/user.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cadastro',
  templateUrl: './cadastro.component.html',
  styleUrls: ['./cadastro.component.scss']
})
export class CadastroComponent implements OnInit {

  error: boolean = false

  teste: boolean = true;

  public mostrarSenha = true
  public mostrarConfirmSenha = true

  public form = new FormGroup({
    username: new FormControl('', [
      Validators.required,
      Validators.pattern(/(.|\s)*\S(.|\s)*/)]),
    cpf: new FormControl(''),
    telefone: new FormControl(''),
    email: new FormControl('', [
      Validators.required
    ]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(6)
    ]),
    confirmPassword: new FormControl('', [
      Validators.required,
      Validators.minLength(6)
    ])
  })


  constructor(
    private router: Router,
    private userService: UserService
  ) { }

  ngOnInit(): void {
  }

  passwordMatchValidator(event: any){
    const password = this.form.value.password;
    const confirmPassword = event.target.value;
    console.log(event.target.value)
    if(password != '' && confirmPassword != ''){
      if(password !== confirmPassword){
        this.error = true
      }else{
        this.error = false
      }
    };
  }

  signup(){
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const user = this.form.value;
    this.userService.createUser(user).subscribe(
      () => {
        alert('Usuário criado com sucesso!')
        this.form.reset()
      },
      (error)=> {
        alert('Erro ao criar usuário: ' + error.message)
      }
    )
  }

  mostrarPassword(){
    const input = document.querySelector('.password')
    if(input?.getAttribute('type')== 'password'){
      input.setAttribute('type', 'text')
      this.mostrarSenha = false
    }else{
      input?.setAttribute('type', 'password')
      this.mostrarSenha = true
    }
}

mostrarConfirmPassword(){
  const input = document.querySelector('.confirmPassword')
  if(input?.getAttribute('type')== 'password'){
    input.setAttribute('type', 'text')
    this.mostrarConfirmSenha = false
  }else{
    input?.setAttribute('type', 'password')
    this.mostrarConfirmSenha
  }
}

  cancelar(){
    this.router.navigate(['/login']);
  }
}
