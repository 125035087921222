import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { DgCrudModule } from '@datagrupo/dg-crud';
import { CdkDynamicGroupModule, CdkDynamicTableModule, DgModalModule, DgPaginatorModule, DgTableModule } from '@datagrupo/dg-ng-util';
import { AppMaterialModule } from 'src/app/shared/app-material/app-material.module';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { SolAgoraMainComponent } from './sol-agora-main/sol-agora-main.component';



@NgModule({
  declarations: [
    SolAgoraMainComponent
  ],
  imports: [
    CommonModule,
    DgCrudModule,
    UiModule,
    RouterModule,
    DgTableModule,
    DgModalModule,
    CdkDynamicTableModule,
    CdkDynamicGroupModule,
    DgPaginatorModule,
    MatIconModule,
    AppMaterialModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    FormsModule,
  ]
})
export class SolAgoraModule { }
