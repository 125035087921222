import { ApiAkadService } from 'src/app/services/api-akad/api-akad.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from './../../../core/service/session/session.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-start-session',
  templateUrl: './start-session.component.html',
  styleUrls: ['./start-session.component.scss']
})
export class StartSessionComponent implements OnInit {

  constructor(
    private session: SessionService,
    private router: Router,
    private akadService: ApiAkadService,
    private route: ActivatedRoute
  ) {}


  ngOnInit(): void {
    this.akadService.authAkad().subscribe((resp) => {
      this.session.tokenAkad = resp.accessToken;
      this.route.queryParams.subscribe((param:any)=> {
        if(window.sessionStorage.getItem('temporaryLogin') == '1') {
          this.router.navigate(['user/profile']).then()
          return;
        }

        if('rota' in param){
          return this.router.navigate([param['rota'] || '']).then()
        }
        return this.router.navigate(['user/home']).then()
      })
    },
    () => {
      return this.router.navigate(['user/home']).then()
    })
  }

}
