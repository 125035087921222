import { environment } from './../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { SessionService } from './../../../core/service/session/session.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-user',
  templateUrl: './home-user.component.html',
  styleUrls: ['./home-user.component.scss']
})
export class HomeUserComponent implements OnInit {

  constructor(private session: HttpClient) {
    //session.get(environment.apiUrl + 'users').subscribe()
   }

  ngOnInit(): void {
  }

}
