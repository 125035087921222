import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { AbstractInsertEdit, HttpClient, InsertEditConfig } from '@datagrupo/dg-crud';
import { DgModalComponent } from '@datagrupo/dg-ng-util';
import { InputHelpers } from 'src/app/helpers/input.helpers';
import { isValidCNPJ, isValidCPF } from 'src/app/helpers/validateDocument';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { CotacaoService } from '../../cotacao/services/cotacao.service';
import { AddressReEntity } from '../entity/addressRe.entity';
import { BillingAddressReEntity } from '../entity/billingAddressRe.entity';
import { CotacaoReEntity } from '../entity/cotacaoRe.entity';

@Component({
  selector: 'app-cotacao-re-insert-edit',
  templateUrl: './cotacao-re-insert-edit.component.html',
  styleUrls: ['./cotacao-re-insert-edit.component.scss']
})
export class CotacaoReInsertEditComponent extends AbstractInsertEdit <CotacaoReEntity> implements OnInit {

  @ViewChild('stepper') step!: MatStepper;
  @ViewChild('modal') modal!: DgModalComponent;


  public todayDate = new Date().toISOString().slice(0, 10);
  public mesmoEndereco = new FormControl(false);
  public showLoader = false;
  public valorTotal: any;
  public parcelas: any = [];
  public calcular = false
  public finalizado = false;

  public formStep0 = new FormGroup({
    productType: new FormControl('Obra Nova'),
    productBranch: new FormControl('67 - Riscos de Engenharia'),
    typeWork: new FormControl('Instalação de placas fotovoltaicas'),
    effectiveDate: new FormControl('' ,[Validators.required]),
    expiryDate: new FormControl({ disabled: false, value: '' }, [Validators.required]),
    equipmentAmount: new FormControl('', [Validators.required]),
    addInformation: new FormControl(false, [Validators.required]),
    netPremium: new FormControl(''),
    totalPremium: new FormControl( ''),
    iof: new FormControl(''),
    //COBERTURAS:
    cb: new FormControl(''), //cobertura básica
    cd: new FormControl(''), //cobertura de danos
    ddl: new FormControl(''), // despesas com desentulho do local
    dcss: new FormControl(''), // despesas de contenção e salvamento de sinistros
    hp: new FormControl(''), // honorários de peritos
    rcgc: new FormControl(''), // responsabilidade civil geral e cruzada
    rcf: new FormControl(''), // responsabilidade civil fundações
    rcep: new FormControl(''), // responsabilidade civil erro de projeto
    rce: new FormControl(''), // responsabilidade civil empregador
    dm: new FormControl('') // danos morais
  })

  public formStep1 = new FormGroup({
    type: new FormControl('PJ'),
    insured: new FormControl('', [Validators.required]),
    document: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    mobile: new FormControl(''),
    dateOfBirth: new FormControl(''),
    postCode: new FormControl('', [Validators.required]),
    street: new FormControl(''),
    number: new FormControl(''),
    complement: new FormControl(''),
    state: new FormControl(''),
    city: new FormControl(''),
    suburb: new FormControl(''),
    billingPostCode: new FormControl(''),
    billingStreet: new FormControl(''),
    billingNumber: new FormControl(''),
    billingComplement: new FormControl(''),
    billingState: new FormControl(''),
    billingCity: new FormControl(''),
    billingSuburb: new FormControl(''),
//PARCELAS!!
    installment: new FormControl('', [Validators.required])
  })

  constructor(
    public config: InsertEditConfig,
    private changeDetector: ChangeDetectorRef,
    public _snackBar: MatSnackBar,
    public datePipe: DatePipe,
    private http: HttpClient,
    public service: CotacaoService,
    private router: Router,

  ) {
    super(config, { path: environment.apiUrl, context: 'cotacaore'})

    this.crudConfig.backAfterSave = false;
    this.formStep0.valueChanges.subscribe((v) =>
      InputHelpers.ajusteInputMoney(this.formStep0, v, 'equipmentAmount')
    );
}

  override afterSaveEntity(){
    if (!this.entityId) {
      this.router.navigate(['user', 're', this.entity.id]).then(() => {
        this.entityId = this.entity.id;
      });
    }
  }

  override afterFetchEntity(){
    this.formStep0.patchValue({
      effectiveDate: this.entity.effectiveDate,
      expiryDate: this.entity.expiryDate,
      equipmentAmount: String(this.entity.equipmentAmount) || '',
      productType: this.entity.productType || '',
      productBranch: this.entity.productBranch || '',
      typeWork: this.entity.typeWork || '',
      addInformation: this.entity.addInformation = true,
      netPremium: this.entity.netPremium || '',
      totalPremium: this.entity.totalPremium || '',
      iof: this.entity.iof || '',
      cb: String(this.entity.cb) || '',
      cd: this.entity.cd || '',
      ddl: this.entity.ddl || '',
      dcss: this.entity.dcss || '',
      hp: this.entity.hp || '',
      rcgc: this.entity.rcgc || '',
      rcf: this.entity.rcf || this.entity.rcgc || '',
      rcep: this.entity.rcep || '',
      rce: this.entity.rce || '',
      dm: this.entity.dm || '',
    })
    this.formStep1.patchValue({
      type: this.entity.client.type,
      insured: this.entity.insured || '',
      document: this.entity.document?.toString() || '',
      email: this.entity.email || '',
      mobile: String(this.entity.mobile),
      dateOfBirth: this.entity.client.dateOfBirth,
      postCode: this.entity.postCode || '',
      street: this.entity.street || '',
      number: this.entity.number?.toString() || '',
      complement: this.entity.complement || '',
      state: this.entity.state || '',
      city: this.entity.city || '',
      suburb: this.entity.suburb || '',
      billingPostCode: this.entity.postCodeBilling || '',
      billingStreet: this.entity.streetBilling || '',
      billingNumber: this.entity.numberBilling.toString() || '',
      billingComplement: this.entity.complementBilling || '',
      billingState: this.entity.stateBilling || '',
      billingCity: this.entity.cityBilling || '',
      billingSuburb: this.entity.suburBilling || '',
      installment: this.entity.installment?.toString() || ''
    })

    const address = this.entity.client.address;
    const billingAddress = this.entity.client.billingAddress;

      if (
        address?.postCode == billingAddress?.postCode &&
        address?.number == billingAddress?.number &&
        address?.street == billingAddress?.street &&
        address?.complement == billingAddress?.complement
      )
        this.mesmoEndereco.patchValue(true);

    this.entity = { ...this.entity, ...this.fetchEntity };
    this.observablesFormChanges();
  }

  override ngOnInit(): void {
    super.ngOnInit()
    this.entity = this.entity || new CotacaoReEntity();
    this.changeDetector.detectChanges();
  }

  initNewEntity(): void {
    this.entity = new CotacaoReEntity();
    this.observablesFormChanges()
  }

  observablesFormChanges(){
    //observação para setar a data expiryDate com base no effectiveDate
    this.formStep0.controls['effectiveDate'].valueChanges.subscribe((val) => {
      if (!val) return;

      const effectiveDate = new Date(val);
      const currentDate = effectiveDate.getDate();
      effectiveDate.setDate(currentDate + 180);

      this.formStep0.patchValue(
        {
          expiryDate: effectiveDate
            .toLocaleString('pt-br', { timeZone: 'UTC' })
            .split(', ')[0]
            .split('/')
            .reverse()
            .join('-'),
        },
        {
          emitEvent: false,
        }
      );
    });

    //observação para buscar informações de endereço na viaCep com base no CEP
    this.formStep1.controls['postCode'].valueChanges.subscribe((val) => {
      if (!val) return;
      this.service.getCep(val, (result) => {
        this.formStep1.patchValue({
          street: result.logradouro,
          state: result.uf,
          city: result.localidade,
          suburb: result.bairro,
        });
      });
    });
    //observação para buscar informações de endereço cobrança na viaCep com base no CEP
    this.formStep1.controls['billingPostCode'].valueChanges.subscribe((val) => {
      if (!val) return;
      this.service.getCep(val, (result) => {
        this.formStep1.patchValue({
          billingStreet: result.logradouro,
          billingState: result.uf,
          billingCity: result.localidade,
          billingSuburb: result.bairro,
        });
      });
    });
    //observação para setar tipo de pessoa com base no documento
    this.formStep1.controls.type.valueChanges.subscribe((value) => {
      this.formStep1.controls.dateOfBirth[
        value == 'PF' ? 'enable' : 'disable'
      ]();
    });

    this.mesmoEndereco.valueChanges.subscribe((val) => {
      if (!!val) {
        this.formStep1.patchValue({
          billingPostCode: this.formStep1.value.postCode,
          billingStreet: this.formStep1.value.street,
          billingNumber: this.formStep1.value.number,
          billingComplement: this.formStep1.value.complement,
          billingState: this.formStep1.value.state,
          billingCity: this.formStep1.value.city,
          billingSuburb: this.formStep1.value.suburb,
        });
      }
    });
  }

  verificaValidacao(){

    if(this.formStep0.invalid || this.formStep0.value.addInformation == false){
      this.formStep0.markAllAsTouched()

      this._snackBar.open(
        'É necessário preencher todos os campos obrigatórios para seguir',
        'Ok',
        {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
        }
      )
      return
    }
    this.calculaPremios();
    this.step.next();

  }

  dataEntity(){
    const step1 = this.formStep0.value
    const step2 = this.formStep1.value

    this.entity.productType = step1.productType ||'Obra Nova'
    this.entity.productBranch = step1.productBranch || '67 - Riscos de Engenharia'
    this.entity.typeWork = step1.typeWork || 'Instalação de placas fotovoltaicas'
    this.entity.effectiveDate = step1.effectiveDate || ''
    this.entity.expiryDate = step1.expiryDate || ''
    this.entity.equipmentAmount = step1.equipmentAmount || ''
    this.entity.addInformation = step1.addInformation || false
    this.entity.client.type = step2.type || ''
    this.entity.client.insured = step2.insured || ''
    this.entity.client.document = step2.document || ''
    this.entity.client.email = step2.email || ''
    this.entity.client.mobile = step2.mobile || ''
    this.entity.client.dateOfBirth = step2.dateOfBirth || ''
    this.entity.client.address = new AddressReEntity({...(<any>step2)})
    this.entity.client.billingAddress = new BillingAddressReEntity({
      postCode: step2.billingPostCode || '',
      street: step2.billingStreet || '',
      number: step2.billingNumber || '',
      complement: step2.billingComplement || '',
      city: step2.billingCity || '',
      state: step2.billingState || '',
      suburb: step2.billingSuburb || ''
    })
    this.entity.installment = step2.installment || ''
    //coberturas e premios
    this.entity.netPremium = step1.netPremium || ''
    this.entity.totalPremium = step1.totalPremium || ''
    this.entity.iof = step1.iof || ''
    this.entity.cb = step1.cb || ''
    this.entity.cd = step1.cd || ''
    this.entity.ddl = step1.ddl || ''
    this.entity.dcss = step1.dcss || ''
    this.entity.hp = step1.hp || ''
    this.entity.rcgc = step1.rcgc || ''
    this.entity.rcf = step1.rcf || ''
    this.entity.rcep = step1.rcep || ''
    this.entity.rce = step1.rce || ''
    this.entity.dm = step1.dm || ''
  }

  checkData(){
    if(this.formStep1.invalid){
      this.formStep1.markAllAsTouched()
      this._snackBar.open(
        'É necessário preencher todos os campos obrigatórios para seguir',
        'Ok',
        {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
        }
      )
      this.showLoader = false
      return
    }

    const step2 = this.formStep1.value;
    const document = String(step2.document)

    if(step2.type == 'PJ'){
      if(isValidCNPJ(document)){
        this.showLoader = true;
      this.dataEntity();
      this.entity = {
        ...new CotacaoReEntity(),
        ...this.entity,
        dataInclusao: this.entity.dataInclusao,
        installment: this.formStep1.value.installment?.toString()
        };
      this.modal.open()
      }else {
        this.formStep1.controls.document.setErrors({'invalidCNPJ': true});
        Swal.fire({
          title: 'CNPJ inválido',
          text: 'CNPJ inválido, por favor atualizar para um número de documento válido antes de prosseguir com a cotação. '
        })
        return
      }
    }

    if(step2.type == 'PF'){
      if(isValidCPF(document)){
        this.showLoader = true;
        this.dataEntity();
        this.entity = {
          ...new CotacaoReEntity(),
          ...this.entity,
          dataInclusao: this.entity.dataInclusao,
          installment: this.formStep1.value.installment?.toString() || '1'
          };
        this.modal.open()
    }else {
      this.formStep1.controls.document.setErrors({'invalidCPF': true});
      Swal.fire({
        title: 'CPF inválido',
        text: 'CPF inválido, por favor atualizar para um número de documento válido antes de prosseguir com a cotação. ',
      })
      return
      }
    }
    this.finalizado = false
  }

  requestRe(){
    this.modal.close()
    this.service.cotacaoRe(this.entity).subscribe((resp:any)=> {
      this.entity.id =  resp.data.id
      this.entity = {...resp.data}
      this.entity.equipmentAmount = resp.data.equipmentAmount
      this.entity.usuarioInclusao = resp.data.usuarioInclusao
      this.fillForm(resp.data)
      this.showLoader = false;
      this.finalizado = true;
      this.afterSaveEntity()
      this.afterFetchEntity();
      this.step.next();
    },
    (error)=> {
      Swal.fire({
        icon: 'warning',
        title: 'Ocorreu algum erro no envio do formulário'
      })
      this.showLoader = false;
      return
    });
  }

  calculaPremios(): void {
    const formData = this.formStep0.value;
    const equipmentAmount = formData.equipmentAmount;

    if (!equipmentAmount || equipmentAmount === '') {
      return;
    }

    let netPremium = (parseFloat(equipmentAmount) * 0.002899);
    if (netPremium < 200) {
      netPremium = 200;
    }
    let iof = (netPremium * 0.0738);
    let totalPremium = (netPremium + iof);
    //calcula coberturas:
    let cb = parseFloat(equipmentAmount);
    let cd = parseFloat(equipmentAmount);
    let ddl = (parseFloat(equipmentAmount) * 0.1);
    let dcss = (parseFloat(equipmentAmount) * 0.05);
    let hp = (parseFloat(equipmentAmount) * 0.1);
    let rcgc = (parseFloat(equipmentAmount) * 0.5);
    let rcf = (parseFloat(equipmentAmount) * 0.5);
    let rcep = (parseFloat(equipmentAmount) * 0.5);
    let rce = (parseFloat(equipmentAmount) * 0.5);
    let dm = (parseFloat(equipmentAmount) * 0.5);

    formData.netPremium = this.maskValores(String(netPremium));
    formData.iof = this.maskValores(String(iof));
    formData.totalPremium = this.maskValores(String(totalPremium));
    //COBERTURAS:
    formData.cb = this.maskValores(cb.toString());
    formData.cd = this.maskValores(cd.toString());
    formData.ddl = this.maskValores(ddl.toString());
    formData.dcss = this.maskValores(dcss.toString());
    formData.hp = this.maskValores(hp.toString());
    formData.rcgc = this.maskValores(rcgc.toString());
    formData.rcf = this.maskValores(rcf.toString());
    formData.rcep = this.maskValores(rcep.toString());
    formData.rce = this.maskValores(rce.toString());
    formData.dm = this.maskValores(dm.toString());

    this.formStep0.patchValue(formData);
    this.calcParcela();
    this.calcular = true;
  }

  maskValores(val: string) {
    if (!val) return '';
    const formattedValue = parseFloat(val).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
    return formattedValue.replace('R$', '');
  }

  maskDocument(val: string){
    if(val.length == 11) return val.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/,"$1.$2.$3-$4")
      return val.replace(/(\d{2})(\d{3})(\d{3})(\d{3})(\d{2})/,"$1.$2.$3/$4-$5")
  }

  maskMobile(val: String){
    if(val.length == 11) return val.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/,"($1) $2 $3-$4")
      return val.replace(/(\d{2})(\d{4})(\d{4})/,"($1) $2-$3")
  }

  maskDate(val: any){
    return  new Date(val).toLocaleString('pt-br', {timeZone: 'UTC'}).slice(0, 10);
  }

  cancelar() {
    this.showLoader = false
    this.modal.close()
    this.step.selectedIndex = 0
  }

  calcParcela() {
    const equipmentAmount = this.formStep0.value.equipmentAmount;
    if (!equipmentAmount || equipmentAmount === '') {
      return;
    }

    let netPremium = (parseFloat(equipmentAmount) * 0.002899);
    if (netPremium < 200) {
      netPremium = 200;
    }
    let iof = (netPremium * 0.0738);
    let totalPremium = (netPremium + iof);

    const valorTotal = totalPremium;
    this.parcelas = [];

    if (valorTotal <= 100) {
      this.parcelas.push(valorTotal.toLocaleString('pt-BR', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }));
    } else {

      const numParcelas = Math.min(Math.ceil(valorTotal), 4);

      for (let i = 1; i <= numParcelas; i++) {
        const parcelaValor = Math.max((valorTotal / i));
        if(parcelaValor >= 100){
          this.parcelas.push(parcelaValor.toLocaleString('pt-BR', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }));
        }
      }
    }

    console.log('parcela', this.parcelas)
  }

  onParcelChange(event: any) {
    const selectedParcelIndex = event.target.selectedIndex;
    this.formStep1.patchValue({
      installment: selectedParcelIndex + 1
    });
  }

  novopedido(){
    this.initNewEntity()
    this.router.navigate(['user', 're', 'novo'])
    this.entity = new CotacaoReEntity()
    this.entityId = null;
    this.step.selectedIndex = 0
    this.formStep0.get('equipmentAmount')?.setValue('0');
    this.formStep0.get('addInformation')?.setValue(false)
    this.calcular = false
  }

  fillForm(data:any){
    this.entity = { ...data}
    this.formStep1.patchValue({
      type: data.client.type,
      insured: data.client.insured || '',
      document: data.client.document?.toString() || '',
      email: data.client.email || '',
      mobile: data.client.mobile,
      dateOfBirth: data.client.dateOfBirth,
      postCode: data.client.address.postCode || '',
      street: data.client.address.street || '',
      number: data.client.address.number.toString() || '',
      complement: data.client.address.complement || '',
      state: data.client.address.state || '',
      city: data.client.address.city || '',
      suburb: data.client.address.suburb || '',
      billingPostCode: data.client.billingAddress.postCode || '',
      billingStreet: data.client.billingAddress.street || '',
      billingNumber: data.client.billingAddress.number.toString() || '',
      billingComplement: data.client.billingAddress.complement || '',
      billingState: data.client.billingAddress.state || '',
      billingCity: data.client.billingAddress.city || '',
      billingSuburb: data.client.billingAddress.suburb || '',
      installment: data.installment?.toString() || ''

      })
  }

}
