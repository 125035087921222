import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import Swal from 'sweetalert2';

import { DatePipe } from "@angular/common";
import { SessionService } from 'src/app/core/service/session/session.service';
import { BilletEntity } from 'src/app/pages/user/cotacao/entitys/billet.entity';
import { PayerEntity } from 'src/app/pages/user/cotacao/entitys/payer.entity';
import { environment } from "../../../environments/environment";
import { CotacaoEntity } from "../../pages/user/cotacao/entitys/cotacao.entity";
import { CreditCardEntity } from "../../pages/user/cotacao/entitys/credit-card.entity";

interface proposal {
  id?: any
}

@Injectable({
  providedIn: 'root'
})
export class ApiAkadService {
  private contextsAkad = {
    auth: '',
    quickQuote: 'quotation/QuickQuote',
    fullQuote: 'quotation/fullquote',
    bind: 'quotation/Bind',
    proposal: 'akad/proposal',
    novoPedido: '',
    generate: 'report/Generate',
    document: 'report/generate-stream',
    payment: 'payment'
  }

  constructor(
    private http: HttpClient,
    private session: SessionService,
    public datePipe: DatePipe
  ) {
  }

  authAkad(alternativaAuthentication: 'loginAkad' | 'loginSolAgora' = 'loginAkad'): Observable<{ accessToken: string }> {
    return this.http.post<{ accessToken: string }>(environment.apiAkad + 'auth/login', environment[alternativaAuthentication], {
    })
  }

  pedidoSendPayment(data: {}, callback: (d: any) => void, callbackError: () => void) {
    if (!this.session.tokenAkad) return;

    this.http.post(environment.apiAkad + this.contextsAkad.payment, data, {
      headers: {
        'noAddToken': 'true',
        Authorization: 'Bearer ' + this.session.tokenAkad
      }
    }).subscribe(
      (resp: any) => {
        callback(resp)
      },
      (e) => {
        callbackError()
        this.callbackErrorMessage(e)
      }
    )
  }

    generateDocument(
      documentType: 'quote' | 'certificate',
      config: {
        proposalNo: string,
        tohide?: string,
        nomeArquivo?: string
      }) {
    this.http.post(
      environment.apiAkad + this.contextsAkad.generate,
      {
        documentType, proposalNo: config.proposalNo
      },
      {
        headers: {
          'noAddToken': 'true',
          Authorization: 'Bearer ' + this.session.tokenAkad
        }
      }
    ).subscribe((resp: { documentUrl?: string }) => {
      if (!resp?.documentUrl) return;

      this.http.get(resp.documentUrl, {
        params: {
          ...(!!config.tohide ? { tohide: config.tohide } : {})
        },
        responseType: 'arraybuffer',
        observe: 'response',
        headers: {
          'ProductCode': 'RDFO',
          'noAddToken': 'true',
          Authorization: 'Bearer ' + this.session.tokenAkad
        }
      }).subscribe((response: HttpResponse<ArrayBuffer>) => {
        const arrayBuffer = response.body;
        if(arrayBuffer){
          const blob = new Blob([arrayBuffer], { type: 'carta/pdf' });
          const url = window.URL.createObjectURL(blob);

          // Abrir uma nova aba com o URL do download
          const a = document.createElement('a');
          a.href = url;
          a.download = config.nomeArquivo || 'carta.pdf'; // Especificar o nome do arquivo desejado
          a.target = '_blank';
          a.click();
        }
        },
        (e) => {
          this.callbackErrorMessage(e)
        })
    })
  }

  getDocument(proposalNo: string, documentType: 1 | 0 | '1' | '0', tohide: string) {
    this.http.get(environment.apiAkad +
      this.contextsAkad.document,
      {
        params: {documentType, proposalNo, tohide}
      }).subscribe(resp => {
      console.log('resp', resp)
    })
  }

  public callbackErrorMessage(e: any) {
    if (e?.error?.errors.length > 0) {
      Swal.fire({
        icon: 'warning',
        title: e?.error?.message || 'erro não identificado',
        text: e?.error?.errors[0].Message
      }).then()
      return;
    }

    Swal.fire({
      icon: 'warning',
      title: 'Erro interno não identificado'
    })
  }

  // midware para ajustar da akad para o que o nosso beck espera receber
  pipeDataOmbrello(resp: any, data: any, bind: boolean = false) {
    if (bind) {
      return {
        ...data,
        riskAnalysis: [{
          ...(data?.riskAnalysis || {}),
          ...(resp.riskAnalyses?.[0] || {}),
          id: undefined,
          policyRiskList: [{
            ...(data?.riskAnalyse?.policyRiskList?.[0] || {}),
            ...(data?.riskAnalyses?.policyRiskList?.[0] || {}),
            id: undefined,
            coverages: resp?.riskAnalyses?.[0].coverages
          }]
        }],
      }
    }

    return {
      ...resp,
      riskAnalysis: resp.riskAnalyses,
      person: {
        ...(resp?.person || {}),
        ...(data?.person || {}),
      },
      effectiveDate: data?.effectiveDate || '',
      expiryDate: data?.expiryDate || '',
      termos: true
    }
  }

  payment(entity: CotacaoEntity, result: CreditCardEntity | BilletEntity, payer: PayerEntity | '') {
    if (!this.session.tokenAkad) {
      throw 'Autenticação incompleta';
    };

    const dataRequest: any = {
      paymentCode: entity.paymentCode,
      paymentCombo: true
    }

    if(result instanceof CreditCardEntity){
      dataRequest['creditCard'] = result
    } else {
      dataRequest['billet'] = result
    }

    if(payer instanceof PayerEntity){
      dataRequest['payer'] = payer
    }
    return this.http.post(environment.apiAkad + this.contextsAkad.payment, dataRequest, {
      headers: {
        'noAddToken': 'true',
        Authorization: 'Bearer ' + this.session.tokenAkad
      }
    })
  }

  quote(type: 'quick' | 'full', entity: CotacaoEntity) {
    if (!this.session.tokenAkad) {
      throw 'Autenticação incompleta'
    }

    const dataRequest = {
      productVersion: entity.productVersion,
      commissionRate: entity.commissionRate,
      person: {name: entity.person.name},
      effectiveDate: entity.effectiveDate,
      expiryDate: entity.expiryDate,
      riskAnalyse: entity.riskAnalysis[0] || []
    }

    const context = type == 'quick' ? this.contextsAkad.quickQuote : this.contextsAkad.fullQuote

    return this.http.post(environment.apiAkad + context, dataRequest, {
      headers: {
        'noAddToken': 'true',
        Authorization: 'Bearer ' + this.session.tokenAkad
      }
    })
  }

  bind(entity: CotacaoEntity) {
    if (!this.session.tokenAkad) {
      throw ''
    }

    const dataRequest = {
      PolicyId: entity.proposalNo,
      riskAnalyse: entity.riskAnalysis[0],
      person: entity.person,
      termos: entity.termos,
      effectiveDate: entity.effectiveDate,
      expiryDate: entity.expiryDate,
    }

    return this.http.post(environment.apiAkad + this.contextsAkad.bind, dataRequest, {
      headers: {
        'noAddToken': 'true',
        Authorization: 'Bearer ' + this.session.tokenAkad
      }
    })
  }

}
