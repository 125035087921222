// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  apiMock: '',
  apiUrl: 'https://homolog.apiportalsolar.ombrelloseguros.com.br/',
  apiAkad: 'https://sandbox.akadseguros.com.br/ombrello/v1.0/',
  loginAkad: {
    "username": "57615228026",
    "password": "Engarde@24"
  },
  loginSolAgora: {
    "username": "50280853076",
    "password": "D.*2>4?yBS!2zj1eZKIFU-W7LbCEr}fX"
}
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
