import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { InputHelpers } from 'src/app/helpers/input.helpers';
import Swal from "sweetalert2";
import { SessionService } from "../../../core/service/session/session.service";
import { ApiAkadService } from "../../../services/api-akad/api-akad.service";
import { GenericHttpService } from "../../../services/generic-http.service";
import { CotacaoEntity } from "../../user/cotacao/entitys/cotacao.entity";
import { CoveragesEntity } from "../../user/cotacao/entitys/coverages.entity";
import { CreditCardEntity } from "../../user/cotacao/entitys/credit-card.entity";
import { CotacaoService } from "../../user/cotacao/services/cotacao.service";

@Component({
  selector: 'app-public-payment',
  templateUrl: './public-payment.component.html',
  styleUrls: ['./public-payment.component.scss']
})
export class PublicPaymentComponent implements OnInit {

  private entityId: string | undefined;
  public entity = new CotacaoEntity()

  public showLoader = false;

  public parcelas:any = [];
  public paymentCode = '';

  public formStep3 = new FormGroup({
    name: new FormControl('', [Validators.required]),
    identity: new FormControl('', [Validators.required]),
    number: new FormControl('', [Validators.required]),
    expiration: new FormControl('', [Validators.required]),
    cvv: new FormControl('', [Validators.required]),
    installment: new FormControl('', [Validators.required])
  })

  constructor(
    private route: ActivatedRoute,
    private akadService: ApiAkadService,
    private http: GenericHttpService,
    private session: SessionService,
    public service: CotacaoService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    if (this.route.snapshot.params['id']) {
      this.entityId = this.route.snapshot.params['id'];

      this.http.get('akad/proposal/' + this.entityId + '/payment').subscribe((resp: any) => {
        this.entity = resp.data;
        this.calcParcela();
      })
    }
  }

  save() {
    if (this.formStep3.invalid) {
      this.formStep3.markAllAsTouched();
      return;
    }

    this.showLoader = true;

    const formStep3 = this.formStep3.value

    const creditCard = new CreditCardEntity({
      installment: !!formStep3.installment ? Number(formStep3.installment) : 1,
      name: formStep3.name,
      number: formStep3.number,
      identity: formStep3.identity,
      expiration: formStep3.expiration?.slice(0, 2) + '/' + formStep3.expiration?.slice(2),
      cvv: formStep3.cvv
    });

    this.akadService.authAkad().subscribe(resp => {
      this.session.tokenAkad = resp.accessToken;

      this.akadService.payment(this.entity, creditCard, '').subscribe((resp: any) => {
          resp.riskAnalysis = resp.riskAnalyses
          delete resp.riskAnalyses

          this.entity = {
            ...this.entity,
            ...resp,
            person: {
              ...(this.entity?.person || {}),
              ...(resp?.person || {}),
              name: this.entity.person.name,
              address: this.entity.person.address,
              billingAddress: this.entity.person.billingAddress
            },
            effectiveDate: this.entity.effectiveDate,
            expiryDate: this.entity.expiryDate,
            termos: this.entity.termos,
            riskAnalysis: [{
              ...(this.entity?.riskAnalysis?.[0] || {}),
              ...(resp.riskAnalysis?.[0] || {}),
              policyRiskList: [{
                ...(this.entity.riskAnalysis[0].policyRiskList[0] || {}),
                coverages: (resp?.riskAnalysis?.[0].policyRiskList?.[0].coverages || []).map((cover: CoveragesEntity) => {

                  return cover;
                })
              }]
            }],
            paymentCode: this.entity.paymentCode || undefined
          }

          this.requestProposal();


        },
        error => this.akadService.callbackErrorMessage(error)
      )
    })

  }

  public getCoverages(): any[] {
    if (!Array.isArray(this.entity?.riskAnalysis)) return [];
    if (this.entity.riskAnalysis.length <= 0) return [];

    if (!Array.isArray(this.entity.riskAnalysis[0]?.policyRiskList)) return []
    if (this.entity.riskAnalysis[0].policyRiskList.length <= 0) return []

    return this.entity.riskAnalysis[0].policyRiskList[0].coverages

  }

  requestProposal() {
    this.http.put('akad/proposal', this.entity).subscribe((r: any) => {

      this.showLoader = false;

        Swal.fire({
          icon: 'success',
          title: 'Pagamento realizado com sucesso!'
        })
        if(this.entity.status == 'Effective'){
          this.enablePayment()
        }
      },
      () => {
        Swal.fire({
          icon: 'error',
          title: 'Erro ao realizar com sucesso!'
        })
      });
  }

  enablePayment(){
    this.formStep3.controls.name.disable()
    this.formStep3.controls.identity.disable()
    this.formStep3.controls.number.disable()
    this.formStep3.controls.expiration.disable()
    this.formStep3.controls.cvv.disable()
    this.formStep3.controls.installment.disable()
}

  maskValores(val:string){
    return InputHelpers._ajusteInputMoney(val)
  }

  calcParcela(){
    let valorTotal = []
    valorTotal.push(this.entity?.premium)

    if(valorTotal[0] <= 100 || (valorTotal[0] / 2) < 100){
      this.parcelas.push(valorTotal);
      return
    }else{
      for (let index = 1; index <= 4; index++) {
        if((valorTotal[0]/index)< 100) return
        this.parcelas.push(Number(valorTotal[0]/index).toLocaleString("BR", {maximumFractionDigits: 2, minimumFractionDigits: 2}))
      }
    }

  }
}
