export class AddressEntity {
  constructor(data: {
    number?: number,
    ibge?: number,
    state?: string,
    city?: string,
    street?: string,
    suburb?: string,
    complement?: string,
    postCode?: string,

  }) {
    this.number = data?.number;
    this.ibge = data?.ibge;
    this.state = data?.state || '';
    this.city = data?.city || '';
    this.street = data?.street || '';
    this.suburb = data?.suburb || '';
    this.complement = data?.complement || '';
    this.postCode = data?.postCode || '';

  }

  id: string | number | undefined
  number: number | undefined
  ibge: number | undefined
  state: string | undefined
  city: string | undefined
  street: string | undefined
  suburb: string | undefined
  complement: string | undefined
  postCode: string | undefined

}
