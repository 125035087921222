<nav class="dg-pagination">
  <ul class="pagination">
    <li
      class="page-item"
      (click)="disabled('menos', 2) ? null : changePagination(0)"
      [class.disabled]="disabled('menos', 2)"
    >
      <span class="page-link"> << </span>
    </li>
    <li
      class="page-item"
      (click)="disabled('menos') ? null : changePagination(data.page - 1)"
      [class.disabled]="disabled('menos')"
    >
      <span class="page-link"> < </span>
    </li>


    <li class="page-item" (click)="changePagination(data.page + - 2)" *ngIf="verificarMenores(2)">
      <span class="page-link" href="#">{{  data.page - 1 }}</span>
    </li>

    <li class="page-item" (click)="changePagination(data.page + - 1)" *ngIf="verificarMenores(1)">
      <span class="page-link" href="#">{{ data.page }}</span>
    </li>


    <li class="page-item active-pagination">
      <span class="page-link">{{ (data.page + 1) }}</span>
    </li>


    <li class="page-item" *ngIf="verificarMaiores(1)">
      <span class="page-link" (click)="changePagination(data.page + 1)">{{ (data.page + 2) }}</span>
    </li>
    <li class="page-item" *ngIf="verificarMaiores(2)">
      <span class="page-link" (click)="changePagination(data.page + 2)">{{ (data.page + 3) }}</span>
    </li>

    <!--    <li class="page-item"><a class="page-link" href="#">3</a></li>-->


    <li
      class="page-item"
      (click)="disabled('mais') ? null : changePagination(data.page + 1)"
      [class.disabled]="disabled('mais')"
    >
      <span class="page-link"> > </span>
    </li>
    <li
      class="page-item"
      (click)="disabled('mais', 2) ? null : changePagination(data.totalPages - 1)"
      [class.disabled]="disabled('mais', 2)"
    >
      <span class="page-link"> >> </span>
    </li>
  </ul>

  <ul *ngIf="showSizeSelect" class="pagination size" style="margin-left: .5rem">
    <li class="page-item">
      <select class="page-link h-100" [(ngModel)]="data.size" (ngModelChange)="alterSize($event)">
        <option *ngFor="let option of optionsSizesPagination" [value]="option">{{option}}</option>
      </select>
    </li>

    <li class="page-item">
      <span class="page-link">
        Página {{data.page + 1}} de {{data.totalPages}}
      </span>
    </li>
  </ul>
</nav>
