<div class="row">
  <div class="container-fluid d-flex justify-content mb-5">
    <div class="col-12 d-flex justify-content-center">
      <div class="row">
        <div class="container d-flex justify-content-center mx-3">
          <div class="card col-12 contact pb-3 m-3">
            <div class="card-body contact-body m-5">
              <img src="../../../../../assets/images/check-form.svg" class="img-success">
              <p class="card-text contact-text ff-gotham-bold">Mensagem enviada<br>
                com sucesso!</p>
            </div>
            <div class="card-footer contact-footer">
              <button class="btn btn-principal ff-gotham-bold btn-form m-2" (click)="voltar()">Voltar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
