<div class="container-fluid">
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 d-flex justify-content-end ">
      <button routerLink="/user/rd/novo" class="btn btn-principal ff-gotham-bold button-NovoPedido">
        <mat-icon class="pt-1">add_circle_outline</mat-icon>
        Novo Pedido de Cotação
      </button>
    </div>
  </div>
</div>
<br>
<div class="container-fluid pb-5">
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
      <div class="card card-meusSeguros">
        <div class="card-header ff-gotham-bold header-meusSeguros">
          <h4 class="card-title">Registro Cotações Riscos Diversos</h4>
        </div>
        <div class="card-header">
          <form [formGroup]="formFilters">
            <div class="row">
              <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                <label class="form-lable">Cliente</label>
                <input class="form-control" formControlName="personName" dg-dynamic-group="tableCotacao" paramName="personName">
              </div>
              <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                <label class="form-lable">Usuário</label>
                <input class="form-control" formControlName="username" dg-dynamic-group="tableCotacao" paramName="username">
              </div>
              <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                <label class="form-lable">Produtor</label>
                <input class="form-control" formControlName="produtor" dg-dynamic-group="tableCotacao" paramName="produtor">
              </div>
              <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                <label class="form-lable">Nº Proposta</label>
                <input class="form-control" formControlName="proposalNo" dg-dynamic-group="tableCotacao" paramName="proposalNo">
              </div>
              <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                <label class="form-lable">Nº da Apólice</label>
                <input class="form-control" formControlName="certificate" dg-dynamic-group="tableCotacao" paramName="certificate">
              </div>
              <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                <label class="form-lable">Status</label>
                <select class="form-control" formControlName="status" dg-dynamic-group="tableCotacao" paramName="status">
                  <option value=""></option>
                  <option value="0">Em Cotação</option>
                  <option value="NotEffective">Não Efetivado</option>
                  <option value="Effective">Efetivado</option>

                </select>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                <label class="form-lable">Inicio de Vigência</label>
                <input class="form-control" type="date" formControlName="effectiveDate" dg-dynamic-group="tableCotacao" paramName="effectiveDate">
              </div>
              <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                <label class="form-lable">Fim de Vigência</label>
                <input class="form-control" type="date" formControlName="expiryDate" dg-dynamic-group="tableCotacao" paramName="expiryDate">
              </div>
              <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                <label class="form-lable">Data de criação</label>
                <div class="row">
                  <div class="col-6">
                    <input class="form-control" type="datetime-local" formControlName="issueDateStart" dg-dynamic-group="tableCotacao" paramName="issueDateStart">
                  </div>
                  <div class="col-6">
                    <input class="form-control" type="datetime-local" formControlName="issueDateEnd" dg-dynamic-group="tableCotacao" paramName="issueDateEnd">
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div class="row mt-3">
            <div class="col-12 d-inline-flex justify-content-end">
              <button dynamic-group-control="tableCotacao" filter class="btn btn-principal ff-gotham-medium btn-sm m-1">Filtrar</button>
              <button dynamic-group-control="tableCotacao" (click)="baixarRelatorio()" class="btn btn-principal ff-gotham-medium btn-sm m-1">Baixar Relatório</button>
              <button dynamic-group-control="tableCotacao" clear class="btn btn-secondary btn-sm m-1">Limpar</button>
            </div>
          </div>
        </div>
        <div class="card-body tbl1">
            <dg-table  dg-dynamic-table [dynamicTable]="table"
            class="tbl2"
            classes="tbl3 crud-table" >
            </dg-table>
          </div>
        <div class="card-footer justify-content-center d-inline-flex">
            <dg-paginator dg-dynamic-paginator [dynamicTable]="table"></dg-paginator>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="loader" *ngIf="showLoader">
  <div class="content-loader">
    <mat-spinner></mat-spinner>
  </div>
</div>
