export class AddressReEntity {
  constructor(data: {
    postCode?: string,
    street?: string,
    number?: number | string,
    complement?: string,
    state?: string,
    city?: string,
    suburb?: string,
  }) {
    this.postCode = data?.postCode || '';
    this.street = data?.street || '';
    this.number = data?.number;
    this.complement = data?.complement || '';
    this.state = data?.state || '';
    this.city = data?.city || '';
    this.suburb = data?.suburb || '';
  }
  id: string | number | undefined
  postCode?: string | undefined
  street?: string | undefined
  number?: number | string | undefined
  complement?: string | undefined
  state?: string | undefined
  city?: string | undefined
  suburb?: string | undefined
}
