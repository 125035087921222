import { HttpResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@datagrupo/dg-crud';
import { CdkDynamicTable, CdkDynamicTableService } from '@datagrupo/dg-ng-util';
import { SessionService } from 'src/app/core/service/session/session.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { CotacaoReEntity } from '../entity/cotacaoRe.entity';


@Component({
  selector: 'app-cotacao-re-main',
  templateUrl: './cotacao-re-main.component.html',
  styleUrls: ['./cotacao-re-main.component.scss']
})
export class CotacaoReMainComponent implements OnInit, OnDestroy {

  public table: CdkDynamicTable.tableClass;
  public showLoader = false;
  public entity = new CotacaoReEntity()
  private http: HttpClient

  constructor(
    createTable: CdkDynamicTableService,
    router: Router,
    session: SessionService,
    http: HttpClient
  ) {
    this.http = http
    this.table = createTable.createByEntity(new CotacaoReEntity(), {
      pipePagination: (p: any) => {
        return {
          page: p?.page?.number || 0,
          totalPages: p?.page?.totalPages || 0,
          totalElements: p?.page?.totalElements || 0
        }
      },
      actions: {
        add: {
          view: {
            name: 'Visualizar',
            dbClick: true,
            action: (val: CotacaoReEntity) => {
              this.entity.id = val.id
              router.navigate(['user', 're', val.id]).then()
            }
          },
          download: {
            name: 'Baixar Pedido',
            permission: !!session.checkPerfil('ROLE_ADMINISTRADOR') ? true : false,
            action: (val: CotacaoReEntity) => {
              this.entity.id = val.id
              this.http.get(environment.apiUrl + 'cotacaore/relatorio/' + this.entity.id, { responseType: 'arraybuffer', observe: 'response'}).subscribe((response: HttpResponse<ArrayBuffer>) => {
                const arrayBuffer = response.body;
                if(arrayBuffer){
                  const blob = new Blob([arrayBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
                  const url = window.URL.createObjectURL(blob)
                  const a = document.createElement('a');
                    a.href = url;
                    a.download = 'PedidoEmissãoRe.xlsx';
                    a.target = '_blank';
                    a.click();
                }
                this.showLoader= false;
              }, (error)=> {
                Swal.fire({
                  icon:'error',
                  title: 'Erro ao baixar pedido de emissão do Seguro Risco Engenharia.',
                })
              }
              )
            }
          }
        }
      }
    })
  }

  ngOnInit(): void {
  }
  ngOnDestroy(): void {
    this.table.destroy()
  }
  dictionary(data: any[]){
    return data;
  }
}
