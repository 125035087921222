import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { AbstractInsertEdit, InsertEditConfig } from '@datagrupo/dg-crud';
import { DgModalComponent } from '@datagrupo/dg-ng-util';
import { Observable, finalize } from 'rxjs';
import { SessionService } from 'src/app/core/service/session/session.service';
import { isValidCNPJ, isValidCPF } from 'src/app/helpers/validateDocument';
import Swal from 'sweetalert2';
import { environment } from '../../../../../environments/environment';
import { InputHelpers } from '../../../../helpers/input.helpers';
import { ApiAkadService } from '../../../../services/api-akad/api-akad.service';
import { ConsultaCepService } from '../../../../services/consulta-cep/consulta-cep.service';
import { GenericHttpService } from '../../../../services/generic-http.service';
import { AddressEntity } from '../entitys/address.entity';
import { BilletEntity } from '../entitys/billet.entity';
import { CotacaoEntity } from '../entitys/cotacao.entity';
import { CoveragesEntity } from '../entitys/coverages.entity';
import { CreditCardEntity } from '../entitys/credit-card.entity';
import { PayerEntity } from '../entitys/payer.entity';
import { ProductsEntity } from '../entitys/products.entity';
import { RiskAnalysisEntity } from '../entitys/risk-analysis.entity';
import { CotacaoService } from '../services/cotacao.service';

@Component({
  selector: 'app-cotacao-insert-edit',
  templateUrl: './cotacao-insert-edit.component.html',
  styleUrls: ['./cotacao-insert-edit.component.scss'],
})
export class CotacaoInsertEditComponent
  extends AbstractInsertEdit<CotacaoEntity>
  implements OnInit
{
  @ViewChild('stepper') step!: MatStepper;
  @ViewChild('modal') modal!: DgModalComponent;

  public todayDate = new Date().toISOString().slice(0, 10);
  public cotacaoGerada = true;
  public mesmoEndereco = new FormControl(false);
  public cotacaoCalculada = true;
  public paymentStep = false;
  public dataAtualizada = false
  public valorTotal: any;
  public parcelas: any = [];
  public datapedido: any;
  public showLoader = false;
  public datef: any;
  public linkPagamento = '';
  public tohide = false;
  public recalcular: boolean = false;

  public formStep0 = new FormGroup({
    //DADOS BÁSICOS DO SEGURADO//
    name: new FormControl('', [Validators.required]),
    effectiveDate: new FormControl('', [Validators.required]),
    expiryDate: new FormControl({ disabled: true, value: '' }, [
      Validators.required,
    ]),
    manufactureYear: new FormControl('', [Validators.required]),
    power: new FormControl(''),
    equipmentAmount: new FormControl('0,00', [Validators.required]),
    //COBERTURAS CONTRATADAS//
    premioLiquido: new FormControl({ value: 'calc. API', disabled: true }, [
      Validators.required,
    ]),
    iof: new FormControl({ value: 'calc. API', disabled: true }, [
      Validators.required,
    ]),
    premioTotal: new FormControl({ value: 'calc. API', disabled: true }, [
      Validators.required,
    ]),
    //COBERTURAS ADICIONAIS: //
    lucrosCessantes: new FormControl(false),
    alagamentos: new FormControl(false)
  });
  public formStep1 = new FormGroup({
    termos: new FormControl(false, [Validators.required]),
    isPep: new FormControl('', [Validators.required]),
  });
  public formStep2 = new FormGroup({
    //INFORMAÇÕES DO SEGURADO:
    type: new FormControl('PF'),
    name: new FormControl('', [Validators.required]),
    document: new FormControl('', [Validators.required]),
    dateOfBirth: new FormControl('', [Validators.required]),
    cnpj: new FormControl({ disabled: true, value: '' }, [Validators.required]),
    empresa: new FormControl({ disabled: true, value: '' }, [
      Validators.required,
    ]),
    mobile: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    respPagamento: new FormControl(false),
    estipulante: new FormControl({ value: '', disabled: true }, [
      Validators.required,
    ]),
    typeEst: new FormControl('PJ'),
    cnpjEst: new FormControl({ value: '', disabled: true }, [
      Validators.required,
    ]),
    emailEst: new FormControl({ value: '', disabled: true }, [
      Validators.required,
    ]),
    //INFORMAÇÕES DE ENDEREÇO - endereço 1//
    postCode: new FormControl('', [Validators.required]),
    street: new FormControl('', [Validators.required]),
    number: new FormControl('', [Validators.required]),
    complement: new FormControl(''),
    state: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),
    suburb: new FormControl('', [Validators.required]),
    //INFORMAÇÕES DE ENDEREÇO - endereço 2//
    billingPostCode: new FormControl(''),
    billingStreet: new FormControl(''),
    billingNumber: new FormControl(''),
    billingComplement: new FormControl(''),
    billingState: new FormControl(''),
    billingCity: new FormControl(''),
    billingSuburb: new FormControl(''),
    //KIT FOTOVOLTAICO //
    origem: new FormControl(''),
    make: new FormControl(''),
    model: new FormControl(''),
  });
  public formStep3 = new FormGroup({
    //FORMA DE PAGAMENTO//
    tipoPagamento: new FormControl('', [Validators.required]),
    name: new FormControl({ value: '', disabled: false }, [
      Validators.required,
    ]),
    identity: new FormControl({ value: '', disabled: false }, [
      Validators.required,
    ]),
    number: new FormControl({ value: '', disabled: false }, [
      Validators.required,
    ]),
    expiration: new FormControl({ value: '', disabled: false }, [
      Validators.required,
    ]),
    cvv: new FormControl({ value: '', disabled: false }, [Validators.required]),
    installment: new FormControl('', [Validators.required]),
  });

  constructor(
    public config: InsertEditConfig,
    private consultaCepService: ConsultaCepService,
    public akadService: ApiAkadService,
    private changeDetector: ChangeDetectorRef,
    public datePipe: DatePipe,
    private router: Router,
    public service: CotacaoService,
    private http: GenericHttpService,
    public _snackBar: MatSnackBar,
    private session: SessionService
  ) {
    super(config, { path: environment.apiUrl, context: 'akad/proposal' });
    if (!this.session.tokenAkad) {
      this.akadService.authAkad('loginAkad').subscribe(
        (resp) => {
          this.session.tokenAkad = resp.accessToken;
        },
        () => {
          Swal.fire({
            icon: 'error',
            title: 'Falha na conexão com a AKAD',
            text: 'Por favor, tente novamente mais tarde.',
          });
          return this.router.navigate(['user/home']).then()
        }
      );
    }

    this.crudConfig.backAfterSave = false;
    this.formStep0.valueChanges.subscribe((v) =>
      InputHelpers.ajusteInputMoney(this.formStep0, v, 'equipmentAmount')
    );

  }

  //################ METODOS DO CICLO DE VIDA
  override afterSaveEntity() {
    if (!this.entityId) {
      this.router.navigate(['user', 'rd', this.entity.id]).then(() => {
        this.entityId = this.entity.id;
      });
    }
    }

  override afterFetchEntity() {
    this.formStep0.patchValue({
      effectiveDate: this.entity.effectiveDate,
      expiryDate: this.entity.expiryDate,
      manufactureYear:
        String(
          this?.entity.riskAnalysis?.[0]?.policyRiskList?.[0]?.manufactureYear
        ) || '',
      power: this?.entity.riskAnalysis?.[0]?.policyRiskList?.[0]?.power || '--',
      equipmentAmount: InputHelpers.initInputMoney(
        this?.entity.riskAnalysis?.[0]?.policyRiskList?.[0]?.equipmentAmount ||
          ''
      ),
      name: this.entity?.person?.name || '',
      lucrosCessantes: this.entity.lucrosCessantes,
      alagamentos: this.entity.alagamentos
    });
    if (!!this.entity?.paymentCode) {
      this.formStep1.patchValue({
        termos: this.entity.termos,
        isPep:
          typeof this.entity.person.isPep == 'boolean'
            ? String(!!this.entity.person.isPep)
            : '',
      });
      // @ts-ignore
      this.formStep2.patchValue({
        ...(this.entity?.person?.address || {}),
        ...(this.entity?.person || {}),
        ...(this.entity?.riskAnalysis?.[0].policyRiskList?.[0] || {}),
        name: this.entity.person.name || '',
        estipulante: this.entity.payment?.payer?.name,
        cnpjEst: this.entity.payment?.payer?.document,
        emailEst: this.entity.payment?.payer?.email,
        billingPostCode: this.entity.payment?.payer?.postalCode || this.entity.person.billingAddress?.postCode || '',
        billingNumber:
          this.entity.payment?.payer?.unitNumber?.toString() ||
          this.entity.person.billingAddress?.number?.toString() || '',
        billingState: this.entity.payment?.payer?.state || this.entity.person.billingAddress?.state || '',
        billingCity: this.entity.payment?.payer?.city || this.entity.person.billingAddress?.city || '',
        billingStreet: this.entity.payment?.payer?.streetName || this.entity.person.billingAddress?.street || '',
        billingSuburb: this.entity.person.billingAddress?.suburb || '',
        billingComplement: this.entity.payment?.payer?.complementary || this.entity.person.billingAddress?.complement || '',
      });
      this.linkPagamento =
        window.location.origin + '/payment/' + this.entity.id;
      setTimeout(() => (this.step.selectedIndex = 3), 1);

      const address = this.entity.person.address;
      const billingAddress = this.entity.person.billingAddress;

      if (
        address?.postCode == billingAddress?.postCode &&
        address?.number == billingAddress?.number &&
        address?.street == billingAddress?.street &&
        address?.complement == billingAddress?.complement
      )
        this.mesmoEndereco.patchValue(true);

      this.calcParcela();
    }

    if (this.entity?.status == 'Effective') {
      this.enablePayment();
      setTimeout(() => (this.step.selectedIndex = 4), 1);
    }

    this.obsrvablesFormChanges();
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.changeDetector.detectChanges();
    this.recalcular = false;
    this.obsrvablesFormChanges();
  }

  initNewEntity(): void {
    this.entity = new CotacaoEntity();
    this.propertiesRequiredAkad();
    this.obsrvablesFormChanges();
    this.recalcular = true;
  }

  /**
   * dados que são obrigatórios para a akad mas que não são salvos no nosso beck-end
   */
  propertiesRequiredAkad() {
    if (!this.entity.productVersion) this.entity.productVersion = '1.0';

    //***************** riskAnalisis
    const editRiskAnalysis = this.entity.riskAnalysis?.[0]
      ? this.entity.riskAnalysis[0]
      : new RiskAnalysisEntity({});
    if (!editRiskAnalysis?.scope) editRiskAnalysis.scope = 'RL';
    if (!editRiskAnalysis?.reportedClaim) editRiskAnalysis.reportedClaim = 0;
    if (!editRiskAnalysis?.installationZone)
      editRiskAnalysis.installationZone = 'Z1';
    if (!editRiskAnalysis?.localType) editRiskAnalysis.localType = 'L1';
    if (!editRiskAnalysis?.federativeUnits)
      editRiskAnalysis.federativeUnits = 'SE';
    if (!editRiskAnalysis?.riskType) editRiskAnalysis.riskType = 'RiscoARisco';
    if (!editRiskAnalysis.coverages)
      editRiskAnalysis.coverages = new CoveragesEntity({
        required: [{ code: 'CP0014' }],
      });

    this.entity.riskAnalysis = [editRiskAnalysis];

    //****************** produtos
    const editProducts = this.entity.riskAnalysis?.[0].products?.[0]
      ? this.entity.riskAnalysis[0].products[0]
      : new ProductsEntity({});

    if (!editProducts.localType) editProducts.localType = 'L1';
    if (!editProducts.installationZone) editProducts.installationZone = 'Z1';
    if (!editProducts.federativeUnits) editProducts.federativeUnits = 'SE';
    if (!editProducts.make) editProducts.make = '';
    if (!editProducts.model) editProducts.model = '';
    if (!editProducts.equipmentType) editProducts.equipmentType = '';
    if (!editProducts.equipmentQuantity) editProducts.equipmentQuantity = 1;
    if (!editProducts.coverageUsage) editProducts.coverageUsage = 'C2';
    if (typeof editProducts.invoice != 'boolean') editProducts.invoice = true;
    if (!editProducts.invoiceNo) editProducts.invoiceNo = '';
    if (!editProducts.productElementCode)
      editProducts.productElementCode = 'R10065';

    this.entity.riskAnalysis[0].products = [editProducts];
  }

  propertiesQuote(){
    const form = this.formStep0.getRawValue();

    if(this.dataAtualizada == false){
      this.entity.effectiveDate =
        this.datePipe.transform(form.effectiveDate, 'yyyy-MM-dd') || '';
      this.entity.expiryDate =
        this.datePipe.transform(form.expiryDate, 'yyyy-MM-dd') || '';
    }

    //****************** person
    this.entity.person.name = form.name || '';
    //****************** produtos
    this.entity.riskAnalysis[0].products[0].equipmentAmount = Number(
      InputHelpers.ajusteMoneyBrToEua(form.equipmentAmount || '')
    );
    this.entity.riskAnalysis[0].products[0].manufactureYear = Number(
      form.manufactureYear
    );
    this.entity.riskAnalysis[0].products[0].power = Number(form.power);

    const coverages: CoveragesEntity = new CoveragesEntity({});

    if (form.alagamentos == true && form.lucrosCessantes == false) {
      coverages.additional.push({ code: 'CP0008' });
    }

    if (form.lucrosCessantes == true && form.alagamentos == false) {
      coverages.additional.push({ code: 'CP0028' });
    }

    if (form.alagamentos == true && form.lucrosCessantes == true) {
      coverages.additional.push({ code: 'CP0008' });
      coverages.additional.push({ code: 'CP0028' });
    }
    this.entity.riskAnalysis[0].coverages = coverages;
  }

  propertiesBind(){
    const formStep1 = this.formStep1.value;
    const formStep2 = this.formStep2.value;

    this.entity.termos = !!formStep1?.termos;
    //******* riskAnalysis
    this.entity.riskAnalysis[0].policyRiskList[0].make = formStep2.make || '';
    this.entity.riskAnalysis[0].model = formStep2.model || 'teste';
    //******* products
    this.entity.riskAnalysis[0].products[0].model = formStep2.model || '';
    this.entity.riskAnalysis[0].products[0].policyElementId =
      this.entity?.riskAnalysis?.[0]?.policyElementId || '';
    //******* person
    this.entity.person.type = <'PF' | 'PJ'>formStep2.type;
    this.entity.person.documentType =
      this.entity.person.type == 'PF' ? 'CPF' : 'CNPJ';
    this.entity.person.email = formStep2.email || '';
    this.entity.person.address = new AddressEntity({
      ...(<any>formStep2),
      ibge: 4216602,
    });
    this.entity.person.billingAddress = { ...this.entity.person.address };
    this.entity.person.document = formStep2.document || '';
    this.entity.person.mobile = formStep2.mobile || '';
    this.entity.person.isPep = formStep1.isPep == 'true';
    this.entity.person.dateOfBirth = formStep2.dateOfBirth || '';
    this.entity.person.name = formStep2.name || '';

    if (this.entity.person.type == 'PJ') {
      delete this.entity.person.dateOfBirth;
    }
  }

  //################ METODOS DO CICLO DE REQUEST
  quote(type: 'quick' | 'full', callback?: Function) {
    if (this.formStep0.invalid) {
      this.formStep0.markAllAsTouched();
      return;
    }

    this.propertiesRequiredAkad();
    this.showLoader = true;
    this.recalcular = true;
    this.propertiesQuote();

    this.akadService.quote(type, this.entity).subscribe((resp: any) => {
      resp['riskAnalysis'] = resp.riskAnalyses;
      delete resp.riskAnalyses;
      this.entity = {
        ...this.entity,
        ...resp,
        person: {
          ...(this.entity?.person || {}),
          ...(resp?.person || {}),
          name: this.entity.person.name,
        },
        dataInclusao: this.entity.dataInclusao,
        effectiveDate: this.entity.effectiveDate,
        expiryDate: this.entity.expiryDate,
        termos: undefined,
        lucrosCessantes: this.formStep0.value.lucrosCessantes,
        alagamentos: this.formStep0.value.alagamentos
      };

      this.requestProposal();
      if(callback) callback();
    });
  }

  bind() {
    if (this.formStep1.invalid || this.formStep2.invalid) {
      this.formStep1.markAllAsTouched();
      this.formStep2.markAllAsTouched();
      return;
    }

    const formStep1 = this.formStep1.value;
    const formStep2 = this.formStep2.value;

    const document = String(this.formStep2.value.document)

    if(formStep2.type == 'PF'){
      if(isValidCPF(document)){
        this.propertiesRequiredAkad();
        this.showLoader = true;
        this.propertiesBind();
      }else{
        this.formStep2.controls.document.setErrors({ 'invalidCpf': true });
        Swal.fire({
          title: 'CPF inválido',
          text: 'CPF inválido, por favor atualizar para um número de documento válido antes de prosseguir com a cotação. ',
        })
        return
      }
    }

    if(formStep2.type == 'PJ'){
      if(isValidCNPJ(document)){
        this.propertiesRequiredAkad();
        this.showLoader = true;
        this.propertiesBind();
    }else {
      this.formStep2.controls.document.setErrors({ 'invalidCnpj': true });
        Swal.fire({
          title: 'CNPJ inválido',
          text: 'CNPJ inválido, por favor atualizar para um número de documento válido antes de prosseguir com a cotação. ',
        })
        return
    }}


      this.akadService.bind(this.entity).subscribe((resp: any) => {
        resp.riskAnalysis = resp.riskAnalyses;
        delete resp.riskAnalyses;

        this.entity = {
          ...this.entity,
          ...resp,
          person: {
            ...(resp?.person || {}),
            name: this.entity.person.name,
            address: this.entity.person.address,
            billingAddress: this.entity.person.billingAddress,
          },
          dataInclusao: this.entity.dataInclusao,
          effectiveDate: this.entity.effectiveDate,
          expiryDate: this.entity.expiryDate,
          termos: this.entity.termos,
          riskAnalysis: [
            {
              ...(resp.riskAnalysis?.[0] || {}),
              policyRiskList: [
                {
                  ...(this.entity.riskAnalysis[0].policyRiskList[0] || {}),
                  model: formStep2.model,
                  origem: formStep2.origem,
                  coverages: (
                    resp?.riskAnalysis?.[0].policyRiskList?.[0].coverages || []
                  ).map((cover: CoveragesEntity) => cover),
                },
              ],
            },
          ],
          payment: {
            payer: {
            name: this.formStep2.value.estipulante,
            document: this.formStep2.value.cnpjEst,
            email: this.formStep2.value.emailEst
          }
        }
        };
        this.modal.open()
        this.calcParcela();
        this.requestProposal();
        this.step.next();

        this.linkPagamento =
          window.location.origin + '/payment/' + this.entity.id;
      });
  }

  payment() {
    if (this.formStep3.value.tipoPagamento == 'boleto') {
      this.formStep3.controls['name'].disable();
      this.formStep3.controls['cvv'].disable();
      this.formStep3.controls['number'].disable();
      this.formStep3.controls['identity'].disable();
      this.formStep3.controls['expiration'].disable();
    }else{
      this.formStep3.controls['name'].enable();
      this.formStep3.controls['cvv'].enable();
      this.formStep3.controls['number'].enable();
      this.formStep3.controls['identity'].enable();
      this.formStep3.controls['expiration'].enable();
    }

    if (this.formStep3.invalid) {
      this.formStep3.markAllAsTouched();
      return;
    }

    // verificaVigencia
    const effectiveDateString = String(this.formStep0.value.effectiveDate);
    const effectiveDate = new Date(effectiveDateString + 'T00:00:00.000-03:00');
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    if(currentDate > effectiveDate) {
      this.atualizaVigencia().pipe(
        finalize(() => {
        setTimeout(() => {
          this.requestPayment();
        }, 4000); // Set the timeout here (1000ms = 1 second)
      })
      ).subscribe();
      } else {
      this.requestPayment();
  }


  }

  requestPayment() :void{
    this.showLoader = true;

    const formStep2 = this.formStep2.value
    const formStep3 = this.formStep3.getRawValue();
    let payer: any;
    let result: any;

    this.entity.payment?.payer?.name == formStep2.estipulante || ''
    this.entity.payment?.payer?.document == formStep2.cnpjEst || ''
    this.entity.payment?.payer?.email == formStep2.emailEst || ''


    if(formStep2.respPagamento == true){
      payer = new PayerEntity ({
        name: formStep2.estipulante,
        document: formStep2.cnpjEst,
        streetName: formStep2.billingStreet,
        unitNumber: formStep2.billingNumber,
        complementary: formStep2.billingComplement,
        city: formStep2.billingCity,
        state: formStep2.billingState,
        postalCode: formStep2.billingPostCode,
        email: formStep2.emailEst
      })
    }
    if (formStep3.tipoPagamento == 'credito') {
      result = new CreditCardEntity({
        installment: !!formStep3.installment? Number(formStep3.installment): 1,
        name: formStep3.name,
        number: formStep3.number,
        identity: formStep3.identity,
        expiration:formStep3.expiration?.slice(0, 2) + '/' + formStep3.expiration?.slice(2),
        cvv: formStep3.cvv,
      });
    } else {
      result = new BilletEntity({
        installment: !!formStep3.installment ? Number(formStep3.installment): 1,
      });
    }

    Swal.fire({
      icon: 'question',
      title: 'Atenção!',
      text: 'Ao finalizar essa etapa você está contratando uma apólice de seguro. Deseja prosseguir?',
      showCancelButton: true,
      cancelButtonColor: 'red',
      showConfirmButton:true,
      confirmButtonColor: 'green'
    }).then((opt => {
      if(opt.isConfirmed){
        this.showLoader = true;
        if(this.dataAtualizada == true){
          Swal.fire({
            title: "Ops! Data de início de vigência não permitida",
            text:"A data de início de vigência do seguro não pode ser anterior à data de hoje. Não se preocupe! Estamos ajustando automaticamente o início de vigência para a data de HOJE.",
            showClass: {
              popup: `
                animate__animated
                animate__fadeInUp
                animate__faster
              `
            }
          })
        }
        this.akadService.payment(this.entity, result, payer).subscribe(
          (resp: any) => {
            resp.riskAnalysis = resp.riskAnalyses;
            delete resp.riskAnalyses;

            this.entity = {
              ...this.entity,
              ...resp,
              person: {
                ...(this.entity?.person || {}),
                ...(resp?.person || {}),
                name: this.entity.person.name,
                address: this.entity.person.address,
                billingAddress: this.entity.person.billingAddress,
              },
              dataInclusao: this.entity.dataInclusao,
              effectiveDate: this.entity.effectiveDate,
              expiryDate: this.entity.expiryDate,
              termos: this.entity.termos,
              riskAnalysis: [
                {
                  ...(this.entity?.riskAnalysis?.[0] || {}),
                  ...(resp.riskAnalysis?.[0] || {}),

                  policyRiskList: [
                    {
                      ...(this.entity.riskAnalysis[0].policyRiskList[0] || {}),
                      coverages: (
                        resp?.riskAnalysis?.[0].policyRiskList?.[0].coverages || []
                      ).map((cover: CoveragesEntity) => {
                        return cover;
                      }),
                    },
                  ],
                },
              ],
              payment: {
                payer: (payer || {}),
                ...(resp?.payment || {})
              },
              paymentCode: this.entity.paymentCode || undefined,
              payModeCode: formStep3.tipoPagamento == 'boleto' ? '70' : '30'
            };

            this.requestProposal();
          },
          (error) => {
            this.akadService.callbackErrorMessage(error);
            this.showLoader = false;
          }
        );
      }else{
        this.showLoader = false;
      }
    }))

  }

  requestProposal() {
    this.http[this.entity.id ? 'put' : 'post'](
      'akad/proposal',
      this.entity
    ).subscribe(
      (r: any) => {
        this.entity.id = r.data.id;
        this.entity.usuarioInclusaoId = r.data.usuarioInclusaoId;
        this.showLoader = false;
        this.cotacaoGerada = true;
        this.cotacaoCalculada = true;
        this.recalcular = false;

        if (this.entity.status == 'Effective' && this.formStep3.value.tipoPagamento == 'credito') {
          Swal.fire({
            icon: 'success',
            title: 'Pagamento realizado com sucesso!',
          });
          this.enablePayment();
        }

        if(this.entity.status == 'Effective' && this.formStep3.value.tipoPagamento == 'boleto'){
          Swal.fire({
            icon: 'success',
            title: 'Boleto gerado com sucesso!',
          });
          this.enablePayment();
        }

        this.afterSaveEntity();
        this.step.next();
      },
      () => {
        this.showLoader = false;
      }
    );
  }

  //################ METODOS DOS FORMS
  obsrvablesFormChanges() {
    //observação para excluir os dados quando o form for alterado depois de gerar o ProposalNo
    this.formStep0.valueChanges.subscribe((val: any) => {
      if (!this.entity.proposalNo) return;
      this.entity = new CotacaoEntity();
      this.entityId = null;
      this.recalcular = false;
    });
    //observação para setar a data expiryDate com base no effectiveDate
    this.formStep0.controls['effectiveDate'].valueChanges.subscribe((val) => {
      if (!val) return;

      console.log('effectiveDate alterado:', val);
      const effectiveDate = new Date(val);
      effectiveDate.setFullYear(effectiveDate.getFullYear() + 1);

      this.formStep0.patchValue(
        {
          expiryDate: effectiveDate
            .toLocaleString('pt-br', { timeZone: 'UTC' })
            .split(', ')[0]
            .split('/')
            .reverse()
            .join('-'),
        },
        {
          emitEvent: false,
        }
      );
    });
    // new observer for lucrosCessantes and alagamentos
    this.formStep0.controls['lucrosCessantes'].valueChanges.subscribe((val) => {
      this.entity = new CotacaoEntity();
      this.entityId = null;
      this.recalcular = false;
    });
    this.formStep0.controls['alagamentos'].valueChanges.subscribe((val) => {
      this.entity = new CotacaoEntity();
      this.entityId = null;
      this.recalcular = false;
    });
    //observação para buscar informações de endereço na viaCep com base no CEP
    this.formStep2.controls['postCode'].valueChanges.subscribe((val) => {
      if (!val) return;
      this.service.getCep(val, (result) => {
        this.formStep2.patchValue({
          street: result.logradouro,
          state: result.uf,
          city: result.localidade,
          suburb: result.bairro,
        });
      });
    });
    //observação para buscar informações de endereço cobrança na viaCep com base no CEP
    this.formStep2.controls['billingPostCode'].valueChanges.subscribe((val) => {
      if (!val) return;
      this.service.getCep(val, (result) => {
        this.formStep2.patchValue({
          billingStreet: result.logradouro,
          billingState: result.uf,
          billingCity: result.localidade,
          billingSuburb: result.bairro,
        });
      });
    });
    //observação para setar tipo de pessoa com base no documento
    this.formStep2.controls.type.valueChanges.subscribe((value) => {
      this.formStep2.controls.dateOfBirth[
        value == 'PF' ? 'enable' : 'disable'
      ]();
    });
    //observação para habilita/desativar campos estipulante
    this.formStep2.controls.respPagamento.valueChanges.subscribe((val) => {
      this.formStep2.controls.estipulante[!!val ? 'enable' : 'disable']();
      this.formStep2.controls.cnpjEst[!!val ? 'enable' : 'disable']();
      // this.formStep2.controls.empresaEst[!!val ? 'enable' : 'disable']();
      this.formStep2.controls.emailEst[!!val ? 'enable' : 'disable']();
    });

    this.mesmoEndereco.valueChanges.subscribe((val) => {
      if (!!val) {
        this.formStep2.patchValue({
          billingPostCode: this.formStep2.value.postCode,
          billingStreet: this.formStep2.value.street,
          billingNumber: this.formStep2.value.number,
          billingComplement: this.formStep2.value.complement,
          billingState: this.formStep2.value.state,
          billingCity: this.formStep2.value.city,
          billingSuburb: this.formStep2.value.suburb,
        });
      }
    });
  }
  enablePayment() {
    this.formStep3.controls.name.disable();
    this.formStep3.controls.identity.disable();
    this.formStep3.controls.number.disable();
    this.formStep3.controls.expiration.disable();
    this.formStep3.controls.cvv.disable();
    this.formStep3.controls.installment.disable();
  }

  //################ METODOS DE MANUPULAÇÃO PARA O HTML
  public getCoverages(): any[] {
    if (!Array.isArray(this.entity?.riskAnalysis)) return [];
    if (this.entity.riskAnalysis.length <= 0) return [];

    if (!Array.isArray(this.entity.riskAnalysis[0]?.policyRiskList)) return [];
    if (this.entity.riskAnalysis[0].policyRiskList.length <= 0) return [];

    return this.entity.riskAnalysis[0].policyRiskList[0].coverages;
  }

  nextStep() {
    switch (this.step._getFocusIndex()) {
      case 0:
        if (!!this.entity.proposalNo) this.step.next();
        break;
      default:
        return;
    }
  }

  maskValores(val: string) {
    if (!val) return '';
    return Number(val).toLocaleString('BRL', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
  }

  calcParcela() {
    let valorTotal = [];
    valorTotal.push(this.entity?.premium);
    if (
      valorTotal[0] <= 100 ||
      (valorTotal[0] / 2 < 100 && this.parcelas.lenght <= 3)
    ) {
      this.parcelas.push(valorTotal);
      return;
    } else {
      for (let index = 1; index <= 4; index++) {
        if (valorTotal[0] / index < 100) {
          return;
        } else {
          const v = valorTotal[0] / index;
          const n = Number(v);
          const l = n.toLocaleString('BR', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          });
          this.parcelas.push(l);
        }
      }
    }
  }

  atualizaVigencia(): Observable<void>{
    // verificaVigencia
    const effectiveDateString = String(this.formStep0.value.effectiveDate);
    const effectiveDate = new Date(effectiveDateString + 'T00:00:00.000-03:00');
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    if(currentDate > effectiveDate) {
      return new Observable<void>(observer => {
        this.entity = new CotacaoEntity();
        this.entityId = null;
        this.entity.effectiveDate = '';
        this.entity.expiryDate = ''

        const currentDateString = new Date().toISOString().slice(0,10);
        const newExpiryDate = new Date(currentDate.setFullYear(currentDate.getFullYear() + 1)).toISOString().slice(0,10)
        this.formStep0.value.effectiveDate = currentDateString
        this.entity.effectiveDate = currentDateString
        this.entity.expiryDate =  newExpiryDate
        this.dataAtualizada = true
        this.quote('full', () => {
          this.bind();

          observer.next()
          observer.complete();
        });
      })

    } else {
      this.dataAtualizada = false;
      return new Observable<void>(observer => {
        observer.next();
        observer.complete();
      })
    }

  }

  verificaTermos() {
    const termos = this.formStep1.value.termos;
    if (termos == false) {
      this.formStep1.markAllAsTouched();
      this._snackBar.open(
        'Necessário aceitar "termos e condições" para seguir',
        'Ok',
        {
          duration: 5000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
        }
      );
    }

    this.verificaVigencia()
  }

  verificaVigencia():void{
    const effectiveDateString = String(this.formStep0.value.effectiveDate);
    const effectiveDate = new Date(effectiveDateString + 'T00:00:00.000-03:00');
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    if (effectiveDate < currentDate) {
      Swal.fire({
        icon: 'warning',
        title: 'Data de vigência retroativa!',
        text: 'Por favor alterar o início de vigência na etapa 1 antes de dar seguimento na emissão.'
      })
      this.step.previous()
    }
  }

  copyLink() {
    // Cria um elemento temporário (input) para copiar o texto
    const elementoInput = document.createElement('input');
    elementoInput.value = this.linkPagamento;
    // Adiciona o elemento temporário ao DOM (fora do campo de visão do usuário)
    document.body.appendChild(elementoInput);
    // Seleciona o texto do elemento temporário
    elementoInput.select();
    elementoInput.setSelectionRange(0, 99999); // Seleciona todo o texto em dispositivos móveis
    // Executa o comando de cópia
    document.execCommand('copy');
    // Remove o elemento temporário do DOM
    document.body.removeChild(elementoInput);

    this._snackBar.open('Copiado!', 'Ok', {
      duration: 1000,
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
    });
  }

  maskDocument(val: string){
    if(val.length == 11) return val.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/,"$1.$2.$3-$4")
      return val.replace(/(\d{2})(\d{3})(\d{3})(\d{3})(\d{2})/,"$1.$2.$3/$4-$5")
  }

  maskMobile(val: String){
    if(val.length == 11) return val.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/,"($1) $2 $3-$4")
      return val.replace(/(\d{2})(\d{4})(\d{4})/,"($1) $2-$3")
  }

  maskDate(val: any){
    return  new Date(val).toLocaleString('pt-br', {timeZone: 'UTC'}).slice(0, 10);
  }

  cancelar() {
    this.modal.close()
    this.step.selectedIndex = 0
  }
}
