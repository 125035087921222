<nav id="public-navbar" class="navbar navbar-expand-md">
  <div class="container-fluid">
    <!-- LOGO -->
    <img src="assets/images/logo.png" alt="Logo Ombrello Seguros" class="navbar-brand">
    <!-- BTN DROP -->
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <!-- NAVEGAÇÃO -->
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a href="https://ombrelloseguros.com.br/ombrello-solar/" class="btn btn-principal ff-gotham-bold d-inline-flex" aria-current="page">
            Voltar para o portal
            <mat-icon fontIcon="arrow_right_alt">arrow_right_alt</mat-icon>
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>
