

export class ProductsEntity {
  constructor(data: {
    localType?: string,
    installationZone?: string,
    federativeUnits?: string,
    manufactureYear?: number,
    equipmentAmount?: number | string,
    power?: number,
    IsPowerGeneration?: boolean,
    equipmentType?: string,
    make?: string,
    model?: string,
    equipmentQuantity?: number,
    coverageUsage?: string,
    invoice?: boolean,
    invoiceNo?: string,
    productElementCode?: string,
    policyElementId?: string,
  }) {
    this.localType = data?.localType ? data.localType : 'L1';
    this.installationZone = data?.installationZone ? data.installationZone : 'Z1';
    this.federativeUnits = 'SE';
    this.manufactureYear = data.manufactureYear;
    this.equipmentAmount = data.equipmentAmount;
    this.power = data.power;
    this.IsPowerGeneration = typeof data.IsPowerGeneration == 'boolean' ? data.IsPowerGeneration : true;
    this.equipmentType = data.equipmentType || '';
    this.make = data.make || '';
    this.model = data.model || '';
    this.equipmentQuantity = data?.equipmentQuantity ? data?.equipmentQuantity : 1;
    this.coverageUsage = data.coverageUsage ? data.coverageUsage : 'C2';
    this.invoice = typeof data.invoice == 'boolean' ? data.invoice : true;
    this.invoiceNo = data.invoiceNo || '';
    this.productElementCode = data?.productElementCode ? data.productElementCode : 'R10065';
    this.policyElementId = data?.policyElementId
  }

  localType: string = "L1"
  installationZone: string = "Z1"
  federativeUnits: string = "SE"
  manufactureYear: number | undefined// = 'Number(data.manufactureYear)'
  equipmentAmount: string | number | undefined// = 'data.equipmentAmount'
  power: number | undefined// = 'Number(data.power)'
  IsPowerGeneration: boolean = true
  equipmentType: string | undefined// = ""
  make: string | undefined;
  model: string | undefined;
  equipmentQuantity: number// = 1
  coverageUsage: string// = "C2"
  invoice: boolean
  invoiceNo: string | undefined// = ""
  productElementCode: string// = "R10065"
  policyElementId: string | undefined;
}
